<template>
  <slide-out :size="size" :visible.sync="panelOpen" @close="onClose()">
    <slot v-if="!noHeader" name="header">
      <base-header :title="title" :onclose="onClose" />
    </slot>
    <div class="slider-content">
      <div class="slider-main-content">
        <slot></slot>
      </div>
      <div
        :class="['validation-panel-in-slider', noVerificationCheck ? 'noVerificationCheck' : '']"
        v-if="!noVerificationCheck"
      >
        <img
          @click="openSideBar(sliderTypes.VERIFICATION, { onTop: true })"
          :src="`${$endPoint}/assets/icons/validationIcon.svg`"
          alt=""
          class="validation-check clickable"
        />
      </div>
    </div>
  </slide-out>
</template>

<script>
import SlideOut from '@hyjiacan/vue-slideout';
import { sliderTypes } from '../../utils/enums';
import { openSideBar } from '../../utils/eventBus';
import BaseHeader from './BaseHeader';

export default {
  name: 'BaseSlider',
  components: {
    BaseHeader,
    SlideOut,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Array,
      default: () => [400, '100%'],
    },
    noHeader: {
      default: false,
    },
    noVerificationCheck: {
      default: false,
    },
  },

  data() {
    return {
      panelOpen: this.$props.visible,
      sliderTypes,
    };
  },
  computed: {
    getTitle: () => {
      return !this.noHeader ? this.$props.title : null;
    },
  },
  watch: {
    visible: function (newValue) {
      this.panelOpen = newValue;
    },
  },
  methods: {
    onClose({ buttonClicked } = {}) {
      this.panelOpen = false;
      this.$emit('close', { buttonClicked });
    },
    openSideBar,
  },
};
</script>

<style scoped lang="scss">
.slider-content {
  display: flex;
  flex-direction: row;
  .slider-main-content {
    width: 100%;
  }
  .validation-panel-in-slider {
    margin-left: auto;
    width: 56px;
    .validation-check {
      align-self: center;
      margin-top: 8px;
    }
  }
}
</style>
