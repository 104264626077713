import { render, staticRenderFns } from "./HFIInventory.vue?vue&type=template&id=00d08bce&scoped=true&"
import script from "./HFIInventory.vue?vue&type=script&lang=js&"
export * from "./HFIInventory.vue?vue&type=script&lang=js&"
import style0 from "./HFIInventory.vue?vue&type=style&index=0&id=00d08bce&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d08bce",
  null
  
)

export default component.exports