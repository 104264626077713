<template>
  <div class="events-page-wrapper">
    <div class="main-white-box">
      <h1 class="page-headline">Events</h1>
      <div class="table-nav" id="event-tab-content">
        <div class="tab-list">
          <black-button @click="switchTab(eventPageTabs.MAP)" :text="openTab !== eventPageTabs.MAP">
            Map view
          </black-button>
          <black-button
            @click="switchTab(eventPageTabs.TABLE)"
            :text="openTab !== eventPageTabs.TABLE"
          >
            Table view
          </black-button>
        </div>
        <el-row :gutter="10">
          <el-col :xs="16" :sm="4" :md="3" :lg="3" :xl="2"> </el-col>
          <el-col :sm="16" :md="3" :lg="3" :xl="2"> </el-col>
        </el-row>
        <div>
          <el-tabs :value="openTab">
            <el-tab-pane name="map">
              <leaflet-site-map :markers="slots" :on-marker-click="onMapMarkerClicked" />
            </el-tab-pane>
            <el-tab-pane name="table">
              <events-list-table @sortChange="handleSortChange" :table-data="eventsData" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlackButton from '../components/BlackButton';
import LeafletSiteMap from '../components/LeafletSiteMap';
import eventBus, { openSideBar } from '../utils/eventBus';
import { eventPageTabs, sliderTypes } from '../utils/enums';
import EventsListTable from '../components/EventsListTable';
import { formatAmountNumber } from '../utils/numberFormatter';
export default {
  name: 'Events',
  components: { EventsListTable, LeafletSiteMap, BlackButton },
  data() {
    return {
      eventPageTabs,
      openTab: 'map',
      slots: [],
      eventsData: [],
      loading: null,
      sort: 'eventCount:descending',
    };
  },
  computed: {
    selectedFilters() {
      return this.$store.getters.getAllLocationFilters;
    },
  },
  watch: {
    selectedFilters: {
      deep: true,
      handler() {
        this.fetchEvents();
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.fetchEvents();
    });
  },
  beforeDestroy() {
    eventBus.$off('filtersChange');
  },
  methods: {
    switchTab(newTabName) {
      this.openTab = newTabName;
    },
    async fetchEvents() {
      const loading = this.$loading();
      try {
        const { data } = await this.$http.get('locations', {
          params: { ...this.selectedFilters, sortBy: this.sort },
        });
        this.slots = data.locations
          .filter((e) => e.coordinates.latitude && e.coordinates.longitude)
          .map((e) => ({
            latLng: [e.coordinates.latitude, e.coordinates.longitude],
            data: {
              location: e,
            },
          }));

        this.eventsData = data.locations.map((e) => {
          return {
            ...e,
            vials: [
              {
                value: Number(e.eventCount),
                label: formatAmountNumber(e.eventCount),
                color: 'dark-blue',
              },
            ],
          };
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          message: "Couldn't get data for events tab. Please try later.",
        });
      } finally {
        loading.close();
      }
    },
    handleSortChange(val) {
      this.sort = val;
      this.fetchEvents();
    },
    onMapMarkerClicked(markerData) {
      const isLocation = !markerData.data?.event;
      const locationData = this.eventsData.find((e) => e.locationId === markerData.data.locationId);
      if (isLocation) {
        openSideBar(sliderTypes.EVENT_LIST, markerData.data.location);
      } else {
        openSideBar(sliderTypes.EVENT_DETAILS, {
          ...markerData.data?.event,
          location: locationData,
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '../scss/variables';

.events-page-wrapper {
  .main-white-box {
    background-color: var(--white);
    padding: 24px;

    h1 {
      text-align: start;
      margin-bottom: 34px;
    }

    .el-col {
      text-align: start;
    }

    ::v-deep .table-nav {
      .el-tabs__nav-wrap {
        display: none;
        &::after {
          height: 0;
        }
      }
      .tab-list {
        display: flex;
        flex-direction: row;
        .black-button {
          &:not(:last-child) {
            margin-right: 0px;
          }
        }
      }
    }
    #event-tab-content {
      .el-col-lg-3 {
        width: 11.7%;
      }
      .el-col-xl-2 {
        @media only screen and (min-width: 1920px) {
          width: 8.7%;
          max-width: 100px;
        }
      }
    }
  }
}
</style>
