<template>
  <el-dialog
    id="destruction-images-slider-dialog"
    title=""
    class=""
    :append-to-body="true"
    :show-close="false"
    :visible.sync="visibleModal"
    width="500px"
    @close="onClose"
  >
    <div class="images-slider-wrapper">
      <div class="resolve-dialog-header">
        <img
          class="close-image clickable"
          @click="closeModal()"
          :src="`${$endPoint}/assets/icons/close.svg`"
          alt=""
        />
      </div>
      <div class="resolve-dialog-content" v-if="visibleModal">
        <div class="destruction-photos-list" v-if="images">
          <div class="selected-image-wrapper">
            <img v-if="selectedImage !== null" class="selected-image" :src="selectedImage" alt="" />
          </div>
          <div class="images">
            <div class="uploaded" id="uploaded">
              <template v-for="(image, i) in images">
                <div
                  :key="i"
                  class="square preview clickable"
                  :class="ifActive(i)"
                  :id="`id-${i}`"
                  @click="openImage(i)"
                >
                  <img :src="image.image" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ImagesSliderComponent',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: String,
      default: null,
    },
    images: {
      type: Array,
      default: null,
    },
    selectedImageIndex: {
      type: Number,
      default: 0,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      visibleModal: this.isVisible,
      selectedImage: null,
      selectedLocalImageIndex: this.selectedImageIndex,
    };
  },
  computed: {
    selectedIndex() {
      return this.selectedLocalImageIndex;
    },
  },
  watch: {
    isVisible: function (newValue) {
      this.visibleModal = newValue;
      if (this.visibleModal) {
        this.$nextTick(() => {
          this.selectedImage = null;
          this.selectedImage = this.images[this.selectedIndex].image;
          let elementId = `id-${this.selectedIndex}`;
          let mainElement = document.getElementById(elementId);
          let parentElement = document.getElementById('uploaded');
          if (!this.elementInViewport(mainElement, parentElement)) {
            parentElement.scrollTo({
              left: mainElement.offsetLeft,
              behavior: 'smooth',
            });
          }
        });
      }
    },
    selectedImageIndex(newValue) {
      this.selectedLocalImageIndex = newValue;
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    elementInViewport(el, parentElement) {
      let currentScrollPositionOfParent = parentElement.scrollLeft;
      let currentViewportWidth = parentElement.clientWidth;
      let viewPortEnd = currentScrollPositionOfParent + currentViewportWidth;
      return el.offsetLeft >= currentScrollPositionOfParent && el.offsetLeft <= viewPortEnd;
    },
    openImage(imageIndex) {
      this.selectedImage = this.images[imageIndex].image;
      this.selectedLocalImageIndex = imageIndex;
    },
    closeModal() {
      this.visibleModal = false;
      if (this.onClose) {
        this.onClose();
      }
    },
    ifActive(index) {
      return index === this.selectedLocalImageIndex ? 'active-border' : '';
    },
  },
};
</script>

<style scoped lang="scss">
.images-slider-wrapper {
  .destruction-photos-list {
    background-color: var(--white);
    margin-bottom: 12px;
    padding: 0 1em;

    .images {
      display: inline-flex;
      width: 100%;
      overflow-x: auto;

      .square {
        width: 72px;
        min-width: 72px;
        height: 72px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--grey-100);
        overflow: hidden;
        margin-bottom: 5px;
        margin-top: 5px;
        border-radius: 18.8679px;

        .icon {
          width: 32px;
          height: 32px;
        }
      }

      .preview {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .uploaded {
        position: relative;
        display: flex;
        overflow-x: auto;
      }
    }
  }
}

.selected-image-wrapper {
  width: 100%;
  height: 45vh;
  margin-bottom: 20px;
  background: #f6f6f6;
  padding: 14px;

  .selected-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.active-border {
  border: 4px solid #00a3ad;
}
</style>
