<template>
  <div class="error-box">
    {{ message }}
    <slot></slot>
    <a class="error-link" :href="link">{{ link }}</a>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessageBox',
  props: {
    message: {
      type: String,
      default: () => {
        return '';
      },
    },
    link: {
      type: String,
      default: () => {
        return '';
      },
    },
  },
};
</script>

<style scoped lang="scss">
.error-box {
  max-width: 650px;
  padding: 12px;
  background: #fff7f7;
  border: 2px solid #de3860;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--black-primary);
  text-align: left;
}
.error-link {
  color: #de3860;
}
</style>
