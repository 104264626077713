<template>
  <div>
    <div class="open-issues-list-table">
      <div class="open-issues-list-header">
        <h4 class="label-big">Open issues</h4>
      </div>
      <div class="open-issues-table">
        <el-table
          :data="localTableData"
          header-cell-class-name="label-medium"
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column prop="issueId" label="Issue ID">
            <template slot-scope="scope" slot="header">
              <header-with-sorting>
                {{ scope.column.label }}
              </header-with-sorting>
            </template>
          </el-table-column>
          <el-table-column
            prop="issueType"
            label="Issue type"
            :formatter="(ev) => resolveIssueType(ev.issueType)"
          >
            <template slot-scope="scope" slot="header">
              <header-with-sorting>
                {{ scope.column.label }}
              </header-with-sorting>
            </template>
          </el-table-column>
          <el-table-column prop="numberOfVials" label="# of vials impacted">
            <template slot-scope="scope" slot="header">
              <header-with-sorting>
                {{ scope.column.label }}
              </header-with-sorting>
            </template>
            <template slot-scope="scope">
              <div class="number-of-vials-column">
                <span class="vials">{{ scope.row.numberOfVials }}</span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Top Left prompts info"
                  placement="top-start"
                >
                  <div slot="content" class="wfh-tooltip">
                    <ul class="basic-list">
                      <li :key="product.productName" v-for="product of scope.row.products">
                        - {{ product.productName }}, {{ product.lot }} : {{ product.numberOfVials }}
                      </li>
                    </ul>
                  </div>
                  <img class="info-icon" :src="`${$endPoint}/assets/icons/Union.svg`" />
                </el-tooltip>
                <basic-button class="resolve-button" @click="onResolveClick(scope.row)">
                  Resolve
                </basic-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { resolveIssueType } from '../utils/nameResolver';
import BasicButton from './basic/BasicButton';

export default {
  name: 'OpenIssuesTable',
  components: { BasicButton },
  props: {
    tableData: {
      type: Array,
      default: null,
    },
    onResolveClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      resolveMessage: null,
      localTableData: this.$props.tableData,
    };
  },
  watch: {
    tableData: function (newValue) {
      this.localTableData = newValue;
    },
  },
  methods: {
    resolveIssueType,
  },
};
</script>

<style scoped lang="scss">
.topwrapper {
  position: relative;
}
.open-issues-list-table {
  .open-issues-list-header {
    text-align: start;
    align-self: start;
    padding: 1em 1em;
  }

  .open-issues-table {
    ::v-deep .label-medium {
      color: var(--black-disabled);
    }

    .number-of-vials-column {
      display: flex;
      flex-direction: row;

      * {
        margin: auto 1em;
      }

      .resolve-button {
        margin-left: 3em;
        text-transform: uppercase;
      }
    }
  }
}
</style>
