import Vue from 'vue';

const eventBus = new Vue();
let filters = {
  country_filter: null,
  location_type_filter: null,
  date_range_filter: null,
  manufacturer_filter: null,
  location_filter: null,
  no_event_location_filter: null,
};

const onFilterChanges = () => {
  Object.keys(filters).forEach((key) => {
    eventBus.$on(key, (value) => {
      if (value !== filters[key]) {
        filters[key] = value;
        eventBus.$emit('filtersChange', filters);
      }
    });
  });
};
onFilterChanges();
export default eventBus;
export const openSideBar = (key, data, extra) => {
  eventBus.$emit('openSidebar', { key, data, extraProps: extra });
};

export const emitEvent = (eventKey, data) => {
  eventBus.$emit(eventKey, data);
};
