<template>
  <div class="kpi-component-wrapper">
    <h3 :class="{ 'headline label-large': true, [color]: color }">{{ headline }}</h3>
    <h5 class="description label-small">{{ description }}</h5>
  </div>
</template>

<script>
export default {
  name: 'KPIComponent',
  props: {
    headline: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
.kpi-component-wrapper {
  background-color: var(--grey-100);
  width: 100%;
  min-height: 72px;
  padding: 12px 16px;
  text-align: start;
  border-radius: 4px;
  .headline {
    color: var(--primary-color);
    line-height: 32px;
    &.black {
      color: var(--black-primary);
    }
    &.grey {
      color: var(--border-black);
    }
    &.green {
      color: var(--teal);
    }
    &.blue {
      color: var(--blue-primary);
    }
    &.orange {
      color: var(--dark-orange);
    }
  }
}
</style>
