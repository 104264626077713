<template>
  <div class="basic-filter-wrapper">
    <h5 class="label-small">Treatment center</h5>
    <el-select
      v-model="value"
      placeholder="Select"
      v-bind="$props"
      v-on="$listeners"
      @change="setFilter"
      class="treatment-center-select"
    >
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'TreatmentCenterFilter',
  data() {
    return {
      value: null,
    };
  },
  computed: {
    options() {
      return this.$store.getters.getTreatmentCenters;
    },
  },
  created() {
    this.$store.dispatch('fetchAvailableLocations');
  },
  methods: {
    ...mapMutations(['activateFilter']),
    setFilter(value) {
      this.activateFilter({ filter: 'location', value });
    },
    resetLocalFilter() {
      this.value = null;
    },
  },
};
</script>

<style scoped lang="scss">
.label-small {
  margin-bottom: 8px;
  text-align: start;
}
.treatment-center-select {
  text-align: start;
  display: block;
}
</style>
