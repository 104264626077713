var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('popper',{staticClass:"wfh_tooltip",attrs:{"root-class":'wfh_tooltip',"trigger":"hover","disabled":_vm.disabled,"options":{
    placement: 'top-end',
    modifiers: {
      offset: { offset: null, fn: _vm.fn },
      keepTogether: {
        enabled: true,
        fn: _vm.keepTogetherFn,
      },
    },
    disabled: _vm.disabled,
  },"delay-on-mouse-over":_vm.openDelay,"append-to-body":true,"visible-arrow":true}},[_c('div',{staticClass:"popper wfh_tooltip"},[_vm._t("tooltip-content")],2),_c('div',{ref:"contentComponent",attrs:{"slot":"reference"},on:{"mousemove":_vm.mousemove},slot:"reference"},[_vm._t("content")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }