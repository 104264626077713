<template>
  <div class="basic-filter-wrapper">
    <el-checkbox
      class="no-event-location-filter"
      label="Show locations with no events"
      :value="value"
      @change="(v) => toggleCheckBox(v)"
    ></el-checkbox>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'NoEventLocationFilter',
  data() {
    return {
      value: true,
    };
  },
  methods: {
    ...mapMutations(['activateFilter']),
    toggleCheckBox(value) {
      this.value = value;
      this.activateFilter({ filter: 'includeEmptyLocations', value });
      this.activateFilter({ filter: 'location', value: null });
      this.$store.dispatch('fetchAvailableLocations');
    },
    resetLocalFilter() {
      this.value = true;
    },
  },
};
</script>

<style scoped lang="scss">
.label-small {
  margin-bottom: 8px;
  text-align: start;
}

.no-event-location-filter {
  text-align: start;
  display: block;
}
</style>
