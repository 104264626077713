<template>
  <div class="utilization-page-context-switcher-wrapper">
    <black-button :text="active !== 'general'" @click="active = 'general'"> General </black-button>
    <black-button :text="active !== 'expiry'" @click="active = 'expiry'">Vial expiry</black-button>
  </div>
</template>

<script>
import BlackButton from '../BlackButton';
import { mapMutations } from 'vuex';
export default {
  name: 'HFIUtilizationPageContextSwitcher',
  components: { BlackButton },
  data() {
    return {
      active: 'general',
    };
  },
  watch: {
    active() {
      this.setHFIUtilizationView(this.active);
    },
  },
  created() {
    this.setHFIUtilizationView(this.active);
  },
  methods: {
    ...mapMutations(['setHFIUtilizationView']),
  },
};
</script>

<style scoped lang="scss">
.utilization-page-context-switcher-wrapper {
}
</style>
