<template>
  <el-dialog
    title=""
    class=""
    :append-to-body="true"
    :show-close="false"
    :visible.sync="visibleModal"
    width="40%"
    @close="onClose"
  >
    <div class="resolve-dialog-wrapper">
      <div class="resolve-dialog-header">
        <img
          class="close-image clickable"
          @click="closeModal()"
          :src="`${$endPoint}/assets/icons/close.svg`"
          alt=""
        />
      </div>
      <div class="resolve-dialog-content" v-if="visibleModal">
        <h4 class="issue-title label-medium label-bold">Event ID: {{ eventId }}</h4>
        <div class="destroyed-products-vials-list" v-if="products">
          <el-table
            :data="products"
            header-row-class-name="label-medium"
            :default-sort="{ prop: 'date', order: 'descending' }"
            cell-class-name="overflow-visible"
          >
            <el-table-column prop="product" label="Product" :width="180" align="left">
              <template slot-scope="scope" slot="header">
                <header-with-sorting>
                  {{ scope.column.label }}
                </header-with-sorting>
              </template>
            </el-table-column>
            <el-table-column prop="lot" label="LOT #" :width="180" align="left">
              <template slot-scope="scope" slot="header">
                <header-with-sorting>
                  {{ scope.column.label }}
                </header-with-sorting>
              </template>
            </el-table-column>
            <el-table-column prop="value" label="# of vials" :width="180" align="left">
              <template slot-scope="scope" slot="header">
                <header-with-sorting>
                  {{ scope.column.label }}
                </header-with-sorting>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import HeaderWithSorting from '../basic/HeaderwithSorting';
export default {
  name: 'DestructionEventProductsModal',
  components: { HeaderWithSorting },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: String,
      default: null,
    },
    products: {
      type: Array,
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      visibleModal: this.isVisible,
    };
  },
  watch: {
    isVisible: function (newValue) {
      this.visibleModal = newValue;
    },
  },
  methods: {
    closeModal() {
      this.visibleModal = false;
      if (this.onClose) {
        this.onClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.resolve-dialog-wrapper {
  .destroyed-products-vials-list {
    ::v-deep .label-medium {
      color: var(--black-disabled);
    }
  }
}
</style>
