<template>
  <div class="vial-flow-table no-lines-table">
    <el-table
      :data="tableData"
      header-row-class-name="label-medium no-padding-left header-row"
      :default-sort="defaultSort"
      @sort-change="handleSortChange"
      :cell-class-name="getClassName"
      :row-class-name="
        (r, i) => (isLastRow(r.rowIndex) ? 'adjusted-table-height' : 'base-table-height')
      "
    >
      <el-table-column
        :prop="'sourceName'"
        :label="inbound ? 'Origin WFH DC' : 'Destination HTC'"
        :width="180"
        align="left"
        sortable="custom"
        header-align="left"
      >
        <template slot-scope="scope">
          <div class="column-word">
            {{
              `${scope.row.locationName ? scope.row.locationName : 'N/A'}${
                scope.row.city ? ', ' + scope.row.city : ''
              }`
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        :prop="inbound ? 'expectedInBound' : 'expectedOutBound'"
        label="# of vials"
        align="left"
        :width="600"
        header-align="center"
      >
        <template slot-scope="scope">
          <grid-axis
            :inverted="false"
            :max-value="getMaxValue(scope.row.vials)"
            :step="getStepSize(scope, 0.9)"
            :max-width="scope.column.realWidth"
            :min-value="getMinValue(scope.row.vials)"
            :width-reduction="0.9"
            :show-axis-numbers="isLastRow(scope.$index)"
            :formatter="formatAmountNumberWithoutDecimals"
          />
          <double-tacked-chart
            :bars="scope.row.vials"
            :inverted="false"
            :max-width="scope.column.realWidth"
            :step="getStepSize(scope, 0.9)"
            :bar-height="22"
          >
            <div slot="tooltip">
              <div slot="content">
                <ul class="basic-list">
                  <li>Location name: {{ getTooltipInfo(scope, inbound).locationName }}</li>
                  <li>
                    Expected (shipped by
                    {{ inbound ? 'WFH' : getTooltipInfo(scope, inbound).receivedName }}):
                    {{ getTooltipInfo(scope, inbound).expected }}
                  </li>
                  <li>
                    Actual (received by
                    {{ inbound ? getTooltipInfo(scope, inbound).receivedName : 'HTC' }}):
                    {{ getTooltipInfo(scope, inbound).received }}
                  </li>
                </ul>
              </div>
            </div>
          </double-tacked-chart>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import GridAxis from './basic/GridAxis';
import DoubleTackedChart from './charts/DoubleTackedChart';
import { productFlowPageDescription } from '../utils/enums';
import { formatAmountNumberWithoutDecimals, formatRegularAmounts } from '../utils/numberFormatter';
export default {
  name: 'VialFlowTable',
  components: { DoubleTackedChart, GridAxis },
  props: {
    tableData: {
      type: Array,
      default: null,
    },
    inbound: {
      type: Boolean,
    },
    currentLocationName: {
      type: String,
      default: null,
    },
    handleSortChange: {
      type: Function,
      default: () => {},
    },
    defaultSort: {
      type: Object,
      default: null,
    },
  },
  watch: {
    inbound(n, v) {
      console.log(n, v);
    },
  },
  methods: {
    formatAmountNumberWithoutDecimals,
    getStepSize(scope, reduction = 0.9) {
      return (
        (scope.column.realWidth * reduction) /
        Math.max(
          ...this.$props.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []),
        )
      );
    },

    getMaxValue() {
      return Math.max(
        ...this.$props.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []),
      );
    },
    getMinValue() {
      return Math.min(
        ...this.$props.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []),
      );
    },
    isLastRow(rowindex) {
      return rowindex === this.$props.tableData.length - 1;
    },
    getReductionBasedOnMaxValues(scope) {
      // can calculate the space needed to leave for out labels
      const maxLengthLabel = Math.max(
        ...this.$props.tableData.reduce(
          (p, c) => [...p, ...c.vials.map((t) => t.labelOut?.length ?? 0)],
          [],
        ),
      );
      return (scope.column.realWidth - maxLengthLabel * 8 * 1.2) / scope.column.realWidth;
    },
    getTooltipInfo(scope, inbound = false) {
      return {
        locationName: `${scope.row.locationName ?? 'N/A'}${
          scope.row.city ? ', ' + scope.row.city : ''
        }`,
        expected: formatRegularAmounts(
          scope.row.vials
            .find((e) => e.valueType === productFlowPageDescription.PRODUCT_AMOUNT_EXPECTED)
            ?.value?.toFixed(1),
        ),
        received: formatRegularAmounts(
          scope.row.vials
            .find((e) => e.valueType === productFlowPageDescription.PRODUCT_AMOUNT_RECEIVED)
            ?.value?.toFixed(1),
        ),
        receivedName: scope.row[inbound ? 'origin' : 'destination'],
      };
    },
    getClassName(cell) {
      let classNames = '';
      if (cell.columnIndex === 0) {
        classNames += ' column-word location-name-column';
      }

      return classNames;
    },
  },
};
</script>

<style scoped lang="scss">
.vial-flow-table {
  width: 100%;
  ::v-deep .label-medium {
    color: var(--black-disabled);
  }
  ::v-deep .no-padding-left {
    .cell {
      padding-left: 0;
    }
  }

  ::v-deep .adjusted-table-height {
    height: 96px;
    .el-table__cell {
      padding-top: 0;
    }

    td {
      padding-top: 5px;
      padding-bottom: 5px;
      .grid-axis-lines-wrapper {
        height: 50px;
      }
      .cell {
        margin-top: -40px;
      }
    }
  }

  ::v-deep .base-table-height {
    height: 22px;
    td {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  ::v-deep .header-row {
    th {
      font-size: 14px;
      color: var(--black-tertiary);
      font-weight: 400;
    }
  }

  ::v-deep .el-table {
    .cell {
      padding-left: 0;
      padding-right: 0;
    }

    .column-word {
      .cell {
        word-break: break-word;
        color: var(--black-primary);
      }
    }
  }

  ::v-deep .location-name-column {
    .cell {
      padding-right: 0.8em;
      color: var(--black-primary);
      text-align: left;
      height: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: flex;
      .column-word {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: auto;
      }
    }
  }
}
</style>
