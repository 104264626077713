<template>
  <div class="destroyed-products-page-wrapper">
    <div class="main-white-box">
      <h1 class="page-headline">Destroyed Products</h1>
      <div class="content-wrapper">
        <div class="kpi-wrapper-content">
          <k-p-i-component
            :key="kpi.description"
            v-for="kpi of Object.values(kpis)"
            :description="kpi.description"
            :headline="kpi.title ? kpi.title : formatAmountNumber(kpi.value)"
            :color="kpi.color"
          />
        </div>
        <div class="destroyed-products-table-list-wrapper no-lines-table">
          <el-table
            :data="tableData"
            header-row-class-name="label-medium header-row"
            :default-sort="{ prop: 'total', order: 'descending' }"
            :row-class-name="
              (r, i) => (isLastRow(r.rowIndex) ? 'adjusted-table-height' : 'base-table-height')
            "
            :cell-class-name="getClassName"
            @row-click="(r) => onRowClick(r)"
            @sort-change="handleSortChange"
          >
            <el-table-column
              prop="name"
              label="Location"
              :width="220"
              align="left"
              sortable="custom"
            >
              <template slot-scope="scope">
                <div class="column-name">
                  {{ `${scope.row.location}${scope.row.city ? ', ' + scope.row.city : ''}` }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              prop="total"
              label="# of vials destroyed"
              header-align="center"
              align="left"
              :width="520"
              :class-name="'column-with-bars clickable'"
            >
              <template slot-scope="scope">
                <grid-axis
                  :default="getMaxValue(scope.row.vials, true) === 0"
                  :max-value="getMaxValue(scope.row.vials, true)"
                  :step="getStepSize(scope, 0.9)"
                  :max-width="scope.column.realWidth"
                  :min-value="getMinValue(scope.row.vials, true)"
                  :width-reduction="0.9"
                  :show-axis-numbers="isLastRow(scope.$index)"
                  :formatter="formatAmountNumber"
                  :gridCount="2"
                />
                <double-tacked-chart
                  :bars="scope.row.vials"
                  :inverted="false"
                  :max-width="scope.column.realWidth"
                  :step="getStepSize(scope, 0.9)"
                >
                  <div slot="tooltip0">
                    <div slot="content">
                      <ul class="basic-list">
                        <li>Location name: {{ getStockTooltipInfo(scope).location }}</li>
                        <li>Damaged: {{ getStockTooltipInfo(scope).damaged }}</li>
                        <li>Expired: {{ getStockTooltipInfo(scope).expired }}</li>
                        <li>Recalled: {{ getStockTooltipInfo(scope).recalled }}</li>
                        <li>Total: {{ getStockTooltipInfo(scope).total }}</li>
                      </ul>
                    </div>
                  </div>
                </double-tacked-chart>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KPIComponent from '../components/basic/KPIComponent';
import {
  formatAmountNumber,
  formatAmountNumberWithoutDecimals,
  formatPercent,
  formatRegularAmounts,
} from '../utils/numberFormatter';
import { openSideBar } from '../utils/eventBus';
import { sliderTypes, vialDestructionReason } from '../utils/enums';
import GridAxis from '../components/basic/GridAxis';
import DoubleTackedChart from '../components/charts/DoubleTackedChart';
export default {
  name: 'DestroyedProducts',
  components: { DoubleTackedChart, GridAxis, KPIComponent },
  data() {
    return {
      kpis: {
        ['total']: {
          value: 0,
          description: 'Total destroyed',
          color: 'black',
        },
        [vialDestructionReason.DAMAGED]: {
          value: 0,
          description: 'Damaged',
          color: 'green',
        },
        [vialDestructionReason.EXPIRED]: {
          value: 0,
          description: 'Expired',
          color: 'blue',
        },
        [vialDestructionReason.RECALLED]: {
          value: 0,
          description: 'Recalled',
          color: 'orange',
        },
      },
      tableData: [],
      productDestructionLogs: {},
      sort: 'total:descending',
    };
  },
  computed: {
    selectedFilters() {
      return this.$store.getters.getAllLocationFilters;
    },
  },
  watch: {
    selectedFilters: {
      deep: true,
      handler() {
        this.fetchEvents();
      },
    },
  },
  created() {
    this.fetchEvents();
  },
  methods: {
    async fetchEvents() {
      const loading = this.$loading();
      try {
        const { data } = await this.$http.get('events/destroyedProducts', {
          params: { ...this.selectedFilters, sortBy: this.sort },
        });
        Object.keys(this.kpis).forEach((e) => {
          this.kpis[e].value = Number(data.kpis.find((ke) => ke.reason === e)?.content ?? 0);
        });
        this.kpis['total'].value = data.kpis.reduce((p, c) => p + Number(c.content), 0);
        this.tableData = data.events.map((e) => {
          return {
            location: e.name ?? 'WFH undefined DC',
            locationId: e.location,
            city: e.city,
            locationType: e.type,
            vials: [
              {
                value: Number(e.total),
                labelOut: formatAmountNumber(e.total),
                stackLabelOut: formatAmountNumber(e.total),
                stack: [
                  {
                    value: Number(e.damagedQuantity),
                    label: formatAmountNumber(e.damagedQuantity),
                    labelOut: null,
                    color: 'green',
                    type: vialDestructionReason.DAMAGED,
                  },
                  {
                    value: Number(e.expiredQuantity),
                    label: formatAmountNumber(e.expiredQuantity),
                    labelOut: null,
                    color: 'blue',
                    type: vialDestructionReason.EXPIRED,
                  },
                  {
                    value: Number(e.recalledQuantity),
                    label: formatAmountNumber(e.recalledQuantity),
                    labelOut: null,
                    color: 'dark-orange',
                    type: vialDestructionReason.RECALLED,
                  },
                ],
              },
            ],
          };
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          message: "Couldn't get data for events tab. Please try later.",
        });
      } finally {
        loading.close();
      }
    },
    handleSortChange(val) {
      this.sort = val.order ? `${val.prop}:${val.order}` : null;
      this.fetchEvents();
    },
    formatAmountNumberWithoutDecimals,
    formatAmountNumber,
    formatPercent,
    getStepSize(scope, reduction = 0.75) {
      const maxValue = Math.max(
        ...this.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []),
      );
      return maxValue > 0
        ? (scope.column.realWidth * reduction) /
            Math.max(...this.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []))
        : 0;
    },
    getMaxValue() {
      return Math.max(
        ...this.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []),
      );
    },
    getMinValue() {
      return Math.min(
        ...this.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []),
      );
    },
    isLastRow(rowindex) {
      return rowindex === this.tableData.length - 1;
    },
    onRowClick(row) {
      openSideBar(sliderTypes.PRODUCT_DESTRUCTION_EVENT_LOG, {
        tableData: this.productDestructionLogs,
        locationName: row.location,
        locationType: row.locationType,
        locationId: row.locationId,
        city: row.city,
      });
    },
    getStockTooltipInfo(scope) {
      return {
        location: scope.row.location,
        damaged: formatRegularAmounts(
          scope.row.vials[0].stack
            .find((e) => e.type === vialDestructionReason.DAMAGED)
            ?.value?.toFixed(1),
        ),
        expired: formatRegularAmounts(
          scope.row.vials[0].stack
            .find((e) => e.type === vialDestructionReason.EXPIRED)
            ?.value?.toFixed(1),
        ),
        recalled: formatRegularAmounts(
          scope.row.vials[0].stack
            .find((e) => e.type === vialDestructionReason.RECALLED)
            ?.value?.toFixed(1),
        ),
        total: formatRegularAmounts(scope.row.vials[0].value?.toFixed(1)),
      };
    },
    getClassName(cell) {
      let classNames = 'overflow-visible';
      if (cell.columnIndex === 0) {
        classNames = ' location-name-column';
      }

      return classNames;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/variables';

.destroyed-products-page-wrapper {
  .main-white-box {
    h1 {
      text-align: start;
      margin-bottom: 34px;
    }
  }

  .content-wrapper {
    .kpi-wrapper-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .kpi-component-wrapper {
        &:not(:last-child) {
          margin-right: 0.4em;
        }
      }
    }

    .destroyed-products-table-list-wrapper {
      margin-top: 1.5em;
      ::v-deep {
        .location-name-column {
          .cell {
            word-break: break-word;
            color: var(--black-primary);
            text-align: left;
            height: 42px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            display: flex;
            .column-name {
              margin-right: auto;
              margin-top: auto;
              margin-bottom: auto;
            }
          }
        }
        .overflow-visible {
          .cell {
            overflow: visible;
          }
        }

        .label-medium {
          color: var(--black-disabled);
          th {
            font-weight: 400;
          }
        }
        .header-row {
          th {
            font-size: 14px;
            color: var(--black-tertiary);
          }
        }
        .adjusted-table-height {
          height: 80px;
          .el-table__cell {
            padding-top: 0;
          }
          td {
            padding-top: 5px;
            padding-bottom: 5px;
            .grid-axis-lines-wrapper {
              height: 50px;
            }
            .cell {
              margin-top: -26px;
            }
          }
          .location-name-column {
            .column-name {
            }
          }
        }
      }
      ::v-deep .base-table-height {
        height: 22px;
        td {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }

      ::v-deep .el-table {
        .column-with-bars {
          .cell {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}
</style>
