<template>
  <base-slider v-bind="$props" v-on="$listeners" title="GO BACK" :size="['75%', '100%']">
    <div class="product-destruction-event-log-slider" v-if="productDestructionEventLogDetails">
      <h2 class="label-extra-large">Product destruction event log</h2>
      <div class="product-destruction-event-log-slider-content">
        <div class="content-header">
          <h3 class="label-large">
            Location: {{ productDestructionEventLogDetails.locationName
            }}{{
              productDestructionEventLogDetails.city
                ? ', ' + productDestructionEventLogDetails.city
                : ''
            }}
          </h3>
          <h5 class="label-medium">
            Location type:
            <span class="label-bold">{{
              resolveLocationType(productDestructionEventLogDetails.locationType)
            }}</span>
          </h5>
        </div>

        <div class="destroyed-product-event-log">
          <div class="product-destruction-event-log-table">
            <product-event-log-table :handle-sort-change="handleSortChange" :table-data="events" />
          </div>
          <div class="pagination">
            <el-pagination
              @size-change="handleLimitChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-sizes="[5, 10, 25, 50, 100]"
              :page-size="limit"
              :total="total"
              layout="prev, pager, next, sizes, total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </base-slider>
</template>

<script>
import { resolveLocationType } from '../../utils/nameResolver';
import BaseSlider from './BaseSlider';
import ProductEventLogTable from '../ProductEventLogTable';
export default {
  name: 'ProductDestructionEventLog',
  components: { ProductEventLogTable, BaseSlider },
  props: {
    productDestructionEventLogDetails: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    inbound: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      limit: 10,
      total: 0,
      sortBy: 'eventTime:descending',
      events: [],
    };
  },
  computed: {
    selectedFilters() {
      return this.$store.getters.getAllLocationFilters;
    },
  },
  watch: {
    selectedFilters: {
      deep: true,
      handler() {
        this.fetchLocationEvents();
      },
    },
    productDestructionEventLogDetails: {
      deep: true,
      handler(newValue) {
        if (newValue?.locationId) {
          this.$nextTick(() => {
            this.fetchLocationEvents();
          });
        }
      },
    },
  },
  methods: {
    async fetchLocationEvents() {
      if (!this.productDestructionEventLogDetails?.locationId) return;
      const loading = this.$loading({ target: '.destroyed-product-event-log' });
      try {
        const params = {
          ...this.selectedFilters,
          sortBy: this.sortBy,
          limit: this.limit,
          offset: this.currentPage * this.limit - this.limit,
        };
        const { data } = await this.$http.get(
          `/events/destroyedProducts/${this.productDestructionEventLogDetails.locationId}`,
          {
            params,
          },
        );

        this.events = data.events.map((e) => {
          return {
            eventId: e.event,
            eventTime: e.eventTime,
            submittedBy: e.submittedBy,
            destructionReason: e.reason,
            allVialsDestroyed: Number(e.vialsCount),
            vialsDestroyed: e.vials,
            destructionDetails: {
              images: e.proofs?.map((e) => {
                return {
                  url: e,
                  id: Math.round(Math.random() * 10000),
                };
              }),
            },
          };
        });
        this.total = data.total;
      } catch (e) {
        this.$notify({ type: 'error', message: "Couldn't fetch location events" });
      } finally {
        loading.close();
      }

      setTimeout(() => {
        loading.close();
      }, 1200);
    },
    handleSortChange(sortBy) {
      this.sortBy = sortBy.order ? `${sortBy.prop}:${sortBy.order}` : null;
      this.fetchLocationEvents();
    },
    resolveLocationType,
    handleLimitChange(limit) {
      this.limit = limit;
      this.fetchLocationEvents();
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.fetchLocationEvents();
    },
  },
};
</script>

<style scoped lang="scss">
.product-destruction-event-log-slider {
  padding-bottom: 1em;
  height: 100%;
  .product-destruction-event-log-slider-content {
    align-self: start;
    text-align: start;
    height: 100%;
    .destroyed-product-event-log {
      margin-top: 40px;
      min-height: calc(100% - 199px);
      display: flex;
      flex-direction: column;

      .pagination {
        margin-top: auto;
        text-align: center;
        margin-bottom: 35px;
      }
    }

    .content-header,
    .product-destruction-event-log-table {
      padding-left: 4rem;
      padding-right: 1rem;
      ::v-deep {
        .overflow-visible {
          .cell {
            overflow: visible;
          }
        }
        thead {
          .label-medium {
            color: var(--black-disabled);
          }
        }
        .adjusted-table-height {
          height: 96px;
          .el-table__cell {
            padding-top: 0;
          }
        }
        .el-table {
          .column-with-bars {
            .cell {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
    }

    .content-header {
      margin-bottom: 1em;
      .label-medium {
        margin-bottom: 1em;
        color: var(--black-primary);
      }

      .label-large {
        color: var(--black-primary);
      }
    }

    .filter-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      * {
        margin-top: auto;
        margin-bottom: auto;
      }

      .red-flag-filter {
        text-align: start;

        h5 {
          padding-left: 0;
          margin-bottom: 8px;
        }
      }
    }
  }

  h2.label-extra-large {
    padding-left: 4rem;
    align-self: start;
    text-align: start;
    margin-bottom: 32px;
  }
}
</style>
