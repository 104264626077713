<template>
  <base-slider v-bind="$props" v-on="$listeners" :no-verification-check="true">
    <div class="verification-component">
      <div class="right-bar-verification-icon">
        <img :src="`${$endPoint}/assets/icons/validationIcon.svg`" alt="" />
      </div>
      <div class="verification-main-content">
        <div class="verification-header">
          <h2>Data verification</h2>
          <img
            :src="`${$endPoint}/assets/icons/close.svg`"
            alt=""
            class="close-button clickable"
            @click="$listeners.close()"
          />
        </div>
        <div class="dataset-verification-component">
          <img :src="`${$endPoint}/assets/images/check-circle.svg`" alt="" />
          <h2 class="label-big">Data verification</h2>
          <h5 class="label-small">
            Click the button below to verify data integrity on the OriginTrail Decentralized
            Knowledge Graph.
          </h5>
          <basic-button>Verify</basic-button>
        </div>
      </div>
    </div>
  </base-slider>
</template>

<script>
import BaseSlider from './BaseSlider';
import BasicButton from '../basic/BasicButton';
export default {
  name: 'verificationSlider',
  components: { BasicButton, BaseSlider },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.verification-component {
  display: flex;
  flex-direction: row;
  padding-top: 72px;
  .right-bar-verification-icon {
    width: 52px;
    img {
      align-self: center;
      padding: 0 4px;
    }
  }
  .verification-main-content {
    flex-grow: 1;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    .verification-header {
      height: 56px;
      display: flex;
      flex-direction: row;
      justify-content: right;
      .close-button {
        margin-left: auto;
      }
      * {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .dataset-verification-component {
      margin-bottom: auto;
      margin-top: 30%;
      display: flex;
      flex-direction: column;
      img {
        height: 72px;
        width: 72px;
        margin-bottom: 2em;
        margin-right: auto;
        margin-left: auto;
      }
      h2 {
        margin-bottom: 2em;
      }
      h5 {
        margin-bottom: 2em;
      }
    }
  }
}
</style>
