<template>
  <el-dialog
    title=""
    class=""
    :append-to-body="true"
    :show-close="false"
    :visible.sync="visibleModal"
    width="30%"
    @close="onClose"
  >
    <div class="resolve-dialog-wrapper">
      <div class="resolve-dialog-header">
        <img
          class="close-image clickable"
          @click="closeModal()"
          :src="`${$endPoint}/assets/icons/close.svg`"
          alt=""
        />
      </div>
      <div class="resolve-dialog-content" v-if="visibleModal">
        <h4 class="issue-title label-medium label-bold">Issue ID: {{ issueId }}</h4>
        <h5 class="issue-subtitle label-small">How was the issue resolved ?</h5>
        <div class="resolve-description-text-area">
          <textarea v-model="details"> </textarea>
        </div>
      </div>
      <div class="resolve-dialog-footer">
        <basic-button :big="true" @click="submit()"> SUBMIT </basic-button>
        <basic-button :big="true" :type="'info'" @click="visibleModal = false">
          CANCEL
        </basic-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import BasicButton from '../basic/BasicButton';

export default {
  name: 'ResolveOpenIssuesModal',
  components: { BasicButton },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    issueId: {
      type: String,
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      visibleModal: this.isVisible,
      details: null,
    };
  },
  watch: {
    isVisible: function (newValue) {
      this.visibleModal = newValue;
    },
  },
  methods: {
    closeModal() {
      this.visibleModal = false;
      if (this.onClose) {
        this.onClose();
      }
    },

    submit() {
      this.visibleModal = false;
      if (this.onSubmit) {
        this.onSubmit(this.issueId, this.details);
      }
    },
  },
};
</script>

<style scoped></style>
