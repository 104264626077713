import Vue from 'vue';
import VueRouter from 'vue-router';
import Events from '../views/Events.vue';
import ProductFlow from '@/views/ProductFlow';
import Utilization from '@/views/Utilization';
import Inventory from '@/views/Inventory';
import DestroyedProducts from '@/views/DestroyedProducts';
import ExpiredProducts from '@/views/ExpiredProducts';
import ResolvedIssues from '@/views/ResolvedIssues';
import Login from '@/views/auth/Login';
import ForgotPassword from '@/views/auth/ForgotPassword';
import store from '../store';
import HFIUtilization from '@/views/HFI/HFIUtilization';
import HFIInventory from '@/views/HFI/HFIInventory';
import HFITreatments from '@/views/HFI/HFITreatments';
import ResetPassword from '@/views/auth/ResetPassword';
import ChangePassword from '@/views/auth/ChangePassword';
import { contexts, roles } from '@/utils/enums';
Vue.use(VueRouter);

const canUserAccess = (routeMeta) => {
  const roleAccess =
    routeMeta.roles?.filter((v) => store.getters.getUserRole.includes(v))?.length > 0;
  if (routeMeta.public || !routeMeta.roles || (routeMeta.roles && roleAccess)) {
    return true;
  }
  return false;
};

const restoreSession = async () => {
  if (store.getters.isUserLoggedIn) {
    return;
  }

  const token = localStorage.getItem('wft');

  if (token) {
    await store.dispatch('fetchUserInfo', token);
  }
};

const routes = [
  {
    path: '/WFH/events',
    name: 'events',
    component: Events,
    meta: {
      context: contexts.WFH_ADMIN,
    },
  },
  {
    path: '/WFH/product-flow',
    name: 'product-flow',
    component: ProductFlow,
    meta: {
      context: contexts.WFH_ADMIN,
    },
  },
  {
    path: '/WFH/inventory',
    name: 'inventory',
    component: Inventory,
    meta: {
      context: contexts.WFH_ADMIN,
    },
  },
  {
    path: '/WFH/utilization',
    name: 'utilization',
    component: Utilization,
    meta: {
      context: contexts.WFH_ADMIN,
    },
  },
  {
    path: '/WFH/destroyed-products',
    name: 'destroyed-products',
    component: DestroyedProducts,
    meta: {
      context: contexts.WFH_ADMIN,
    },
  },
  {
    path: '/WFH/expired-products',
    name: 'expired-products',
    component: ExpiredProducts,
    meta: {
      context: contexts.WFH_ADMIN,
    },
  },
  {
    path: '/WFH/resolved-issues',
    name: 'resolved-issues',
    component: ResolvedIssues,
    meta: {
      context: contexts.WFH_ADMIN,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      public: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword,
    meta: {
      public: true,
    },
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPassword,
    meta: {
      public: true,
    },
  },
  {
    path: '/change-password',
    name: 'changePassword',
    component: ChangePassword,
    meta: {
      layout: false,
    },
  },
  {
    path: '/HFI/utilization',
    name: 'hfiUtilization',
    component: HFIUtilization,
    meta: {
      context: contexts.HFI_ADMIN,
    },
  },
  {
    path: '/HFI/inventory',
    name: 'hfiInventory',
    component: HFIInventory,
    meta: {
      context: contexts.HFI_ADMIN,
    },
  },
  {
    path: '/HFI/treatments',
    name: 'hfiTreatments',
    component: HFITreatments,
    meta: {
      context: contexts.HFI_ADMIN,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit('SHOW_GLOBAL_LOADER');
  if (to.meta.public) {
    return next();
  }
  try {
    await restoreSession();
    if (to.path === '/') {
      if (
        store.getters.getUserRole.includes(roles.HFI_ADMIN) &&
        store.getters.getCurrentContext === contexts.HFI_ADMIN
      ) {
        next('/HFI/utilization');
      }
      if (
        store.getters.getUserRole.includes(roles.WFH_ADMIN) &&
        store.getters.getCurrentContext === contexts.WFH_ADMIN
      ) {
        next('/WFH/events');
      }
    } else {
      if (to.meta.context) {
        if (
          to.meta.context === contexts.HFI_ADMIN &&
          store.getters.getUserRole.includes(roles.HFI_ADMIN)
        ) {
          store.commit('SWITCH_CONTEXT', contexts.HFI_ADMIN);
        } else {
          if (
            to.meta.context === contexts.WFH_ADMIN &&
            store.getters.getUserRole.includes(roles.WFH_ADMIN)
          ) {
            store.commit('SWITCH_CONTEXT', contexts.WFH_ADMIN);
          } else {
            next('/');
          }
        }
      }
    }

    if (!to.meta.public && !store.getters.isUserLoggedIn) {
      next('/login');
    } else {
      if (canUserAccess(to.meta)) {
        next();
      }
    }
  } catch (err) {
    if (from.name !== 'login') {
      next('/login');
    }
  }
});

router.afterEach(() => {
  store.commit('HIDE_GLOBAL_LOADER');
});

export default router;
