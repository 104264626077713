<template>
  <div class="destruction-photos-list-table">
    <div class="destruction-photos-header">
      <h4 class="label-big">Destruction details</h4>
    </div>

    <div class="destruction-photos-list">
      <table class="destruction-photos-info-table">
        <tr class="label-medium text-left">
          <td class="destruction-label">Reason:</td>
          <td class="destruction-field-value padding-custom">
            {{ resolveDestructionReason(destructionDetails.destructionReason) }}
          </td>
        </tr>
        <tr>
          <td class="destruction-label">Description:</td>
          <td class="destruction-field-value padding-custom">
            {{ destructionDetails.description }}
          </td>
        </tr>
      </table>
      <p class="destruction-label proof">Proof:</p>
      <div class="images">
        <div class="uploaded">
          <template v-for="(img, i) in imgObjects">
            <image-container
              class="image"
              :key="i"
              :img-object="img"
              @openImagesSlider="openImagesSlider(img, i)"
            ></image-container>
          </template>
          <div v-if="isLoading" class="image loading-indicator"></div>
        </div>
      </div>
    </div>
    <images-slider-component
      :is-visible="imagesSliderVisible"
      :images="imgObjects"
      :selected-image-index="selectedImageIndex"
      :on-close="() => (imagesSliderVisible = false)"
    ></images-slider-component>
  </div>
</template>

<script>
import { resolveDestructionReason } from '../utils/nameResolver';
import ImageContainer from '@/components/ImageContainer';
import ImagesSliderComponent from './modals/ImagesSliderComponent';

export default {
  name: 'EventDestructionPhotos',
  components: {
    ImagesSliderComponent,
    ImageContainer,
  },
  props: {
    eventDestructionDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imgObjects: [],
      isLoading: false,
      imagesSliderVisible: false,
      selectedImageIndex: null,
    };
  },
  computed: {
    destructionDetails() {
      if (!this.eventDestructionDetails) return {};

      return this.eventDestructionDetails;
    },
  },
  watch: {
    destructionDetails(val) {
      // eslint-disable-next-line no-prototype-builtins
      if (val !== undefined && val !== null && val.hasOwnProperty('proofs')) {
        if (Object.keys(val.proofs).length) {
          this.retrieveImages(val.proofs);
        }
      }
    },
  },
  methods: {
    resolveDestructionReason,
    async retrieveImages(proofs) {
      this.isLoading = true;
      this.imgObjects = [];
      const loader = this.$loading({ target: '.loading-indicator' });
      for (const chunk of this.getChunks(proofs, 3)) {
        const promises = chunk.map(this.fetchImage);
        await Promise.all(promises);
      }

      loader.close();
      this.isLoading = false;
    },
    getChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        res.push(arr.slice(i, i + chunkSize));
      }
      return res;
    },
    async fetchImage(longFileName) {
      const filename = longFileName?.split('/').pop();
      try {
        let url = `media/destruction/${filename}`;

        const response = await this.$http.get(url, {
          responseType: 'blob',
          timeout: 3000,
        });

        const reader = new window.FileReader();
        reader.readAsDataURL(response.data);
        reader.onload = () => {
          this.imgObjects.push({
            image: reader.result,
            filename: filename,
          });
        };
      } catch (e) {
        this.imgObjects.push({
          image: null,
          filename: filename,
        });
      }
    },
    openImagesSlider(img, index) {
      this.imagesSliderVisible = true;
      this.selectedImageIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">
.destruction-photos-list-table {
  .destruction-photos-header {
    text-align: start;
    align-self: start;
    padding: 1em 1em;

    ::v-deep .label-medium {
      color: var(--black);
    }

    p {
      margin-top: 1em;
    }
  }

  .destruction-photos-list {
    background-color: var(--white);
    margin-bottom: 12px;
    padding: 2px 1em 9px 1em;

    .images {
      display: flex;
      width: 100%;
      overflow-x: auto;

      .square {
        width: 144px;
        min-width: 144px;
        height: 144px;
        min-height: 144px;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--grey-100);
        border-radius: 4px;
        overflow: hidden;

        .icon {
          width: 32px;
          height: 32px;
        }
      }

      .preview {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .uploaded {
        position: relative;
        display: flex;
        overflow-x: initial;
        min-height: 144px;

        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        .image:not(:last-of-type) {
          margin-right: 15px;
        }

        .image {
          margin-bottom: 4px;
          cursor: pointer;

          &:hover {
            -webkit-filter: brightness(50%);
          }
        }

        .loading-indicator {
          width: 144px;
          min-width: 144px;
          height: 144px;
          min-height: 144px;
          background-color: var(--grey-100);
        }
      }
    }
  }
}

.destruction-photos-info-table {
  th,
  td.padding-custom {
    padding: 5px 12px;
  }
}

.destruction-label {
  color: var(--black-secondary);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  vertical-align: baseline;
  //width: 145px;
  //display: inline-block;
}

.proof {
  margin: 5px 2px 9px;
}

.destruction-field-value {
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--black);
  text-align: left;
  vertical-align: baseline;
  font-weight: 400;
}
</style>
