<template>
  <div class="tacked-bars-wrapper">
    <div
      class="single-bar-wrapper"
      v-for="(bar, i) of bars"
      :key="JSON.stringify(bar)"
      :style="{
        width: getAllBarWidth(bar) + 'px',
      }"
    >
      <popper-tooltip
        class="item"
        placement="top-start"
        :transition="null"
        :open-delay="400"
        :style="{ width: '209px' }"
        :disabled="noTooltip"
      >
        <span slot="content">
          <div
            @click="onBarClick(i)"
            class="clickable"
            v-if="bar.overflow"
            :style="{
              width: `${100 * step}px`,
              'margin-left': inverted ? `${maxWidth - 100 * step}px` : 0,
            }"
          >
            <single-bar-chart :label="bar.label" :overflow="true" />
          </div>
          <div
            @click="onBarClick(i)"
            v-else-if="!bar.stack"
            :class="{
              inverted: inverted,
              'bar-chart': true,
              clickable: clickable,
              [bar.color]: true,
              'border-radius-top': !inverted ? hasRadiusTop(i) : bars.length <= 1,
              'border-radius-bottom': !inverted ? hasRadiusBottom(i) : bars.length <= 1,
              'border-radius-top-left': inverted ? hasRadiusTop(i) : bars.length <= 1,
              'border-radius-bottom-left': inverted ? hasRadiusBottom(i) : bars.length <= 1,
              'no-padding': getBarWidth(bar.value) < 4,
            }"
            :style="{
              height: barHeight + 'px',
              width: getBarWidth(bar.value) + 'px',
              'margin-left': inverted ? `${maxWidth - getBarWidth(bar.value)}px` : 0,
            }"
          >
            <span
              class="text label-small"
              v-if="bar.label && getBarWidth(bar.value) > bar.label.length * 10 + 4"
              :style="{
                'font-size': `${barHeight <= 20 ? 11 : 14}px`,
              }"
            >
              {{ bar.label }}
            </span>
            <span
              class="label-small label-out"
              v-if="bar.labelOut || showBarLabelAsLabelOut(bar)"
              :style="{
                left: !inverted
                  ? `${getBarWidth(bar.value) + (getBarWidth(bar.value) < 4 ? 8 : 12)}px`
                  : `${
                      maxWidth - getBarWidth(bar.value) - Math.max(bar.labelOut.length * 10, 12)
                    }px`,
                margin: getBarWidth(bar.value) < 1 ? '4px 0px' : '',
              }"
            >
              {{ showBarLabelAsLabelOut(bar) ? bar.label : bar.labelOut }}
            </span>
          </div>
          <div v-if="bar.stack" class="stacked-bar">
            <div
              v-for="(stackedBar, index) of bar.stack"
              :key="JSON.stringify(stackedBar)"
              :class="{
                inverted: inverted,
                'bar-chart': true,
                clickable: clickable,
                [stackedBar.color]: true,
                'border-radius-top':
                  !inverted &&
                  getVisibleStackIndividualIndex(bar.stack, index) ===
                    getVisibleStackIndex(bar.stack) - 1
                    ? hasRadiusTop(index, bar.stack, i)
                    : bars.length <= 1 && index === bar.stack.length - 1,
                'border-radius-bottom':
                  !inverted &&
                  getVisibleStackIndividualIndex(bar.stack, index) ===
                    getVisibleStackIndex(bar.stack) - 1
                    ? hasRadiusBottom(index, bar.stack, i)
                    : bars.length <= 1 && index === bar.stack.length - 1,
                'border-radius-top-left':
                  inverted && index === 0
                    ? hasRadiusTop(index, bar.stack, i)
                    : bars.length <= 1 && index === 0,
                'border-radius-bottom-left':
                  inverted && index === 0
                    ? hasRadiusBottom(index, bar.stack, i)
                    : bars.length <= 1 && index === 0,
                'text-center':
                  getBarWidth(stackedBar.value) <=
                  stackedBar.label.length * labelCharacterWidth * 1.2,
                'text-hidden':
                  getBarWidth(stackedBar.value) <=
                  stackedBar.label.length * labelCharacterWidth * 1.6,
              }"
              :style="{
                width: getBarWidth(stackedBar.value) + 'px',
                'margin-left':
                  inverted && index === 0
                    ? `${maxWidth - getBarWidth(getCumulativeValueOfaStack(bar.stack))}px`
                    : 0,
              }"
            >
              <span class="text label-small label-inside" v-if="stackedBar.label">
                {{ stackedBar.label }}
              </span>
              <span
                class="text label-small label-inside label-outside-stack"
                v-if="stackedBar.labelOut"
                :style="{
                  display:
                    getBarWidth(stackedBar.value) <=
                    stackedBar.labelOut.length * labelCharacterWidth * 1.6
                      ? 'block'
                      : 'none',
                  left: !inverted
                    ? `${getBarWidth(stackedBar.value) + 15}px`
                    : `${
                        maxWidth - getBarWidth(stackedBar.value) - stackedBar.labelOut.length * 12
                      }px`,
                }"
              >
                {{ stackedBar.labelOut }}
              </span>
              <span
                class="label-small label-out"
                v-if="bar.stackLabelOut && index === bar.stack.length - 1"
                :style="{
                  left: !inverted
                    ? `${getBarWidth(getCumulativeValueOfaStack(bar.stack)) + 10}px`
                    : `${
                        maxWidth -
                        getBarWidth(getCumulativeValueOfaStack(bar.stack)) -
                        bar.stackLabelOut.length * 10
                      }px`,
                }"
              >
                {{ bar.stackLabelOut }}
              </span>
            </div>
          </div>
        </span>

        <div slot="tooltip-content" class="wfh-tooltip">
          <slot name="tooltip"></slot> <slot :name="`tooltip${i}`"></slot>
        </div>
      </popper-tooltip>
    </div>
  </div>
</template>

<script>
import SingleBarChart from './SingleBarChart';
import PopperTooltip from '../basic/PopperTooltip';
export default {
  name: 'DoubleTackedChart',
  components: { PopperTooltip, SingleBarChart },
  props: {
    color: {
      type: String,
      default: 'blue',
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: Number,
      default: 10,
    },
    stack: {
      type: Array,
      default: null,
    },
    maxWidth: {
      type: Number,
      default: 180,
    },
    minWidth: {
      type: Number,
      default: 10,
    },
    bars: {
      type: Array,
      default: () => [],
    },
    step: {
      type: Number,
      default: 10,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    labelCharacterWidth: {
      type: Number,
      default: 8,
    },
    barHeight: {
      type: Number,
      default: 24,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    noTooltip: {
      type: Boolean,
      default: false,
    },
    autoSwitchLabels: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showBarLabelAsLabelOut(bar) {
      return (
        bar.label &&
        this.autoSwitchLabels &&
        this.getBarWidth(bar.value) < bar.label.length * 10 + 4
      );
    },
    getBarWidth(value) {
      return value < 0 ? 0 : Math.max((isNaN(value) ? 0 : value) * this.$props.step, 0);
    },
    hasRadiusTop(index, stack, barIndex, inverted) {
      if (stack) {
        if (stack.length === 1) return true;
        if (
          this.getVisibleStackIndividualIndex(stack, index) !== inverted
            ? 0
            : this.getVisibleStackIndex(stack) - 1
        )
          return false;
        if (barIndex === (!inverted ? 0 : this.$props.bars.length - 1)) return true;
        return this.getValue(barIndex - 1) < this.getValue(barIndex);
      } else {
        // usual none stacking behavior, uses first index as bar index
        if (index === 0) return true;
        return this.getValue(index - 1) < this.getValue(index);
      }
    },
    hasRadiusBottom(index, stack, barIndex, inverted) {
      if (stack) {
        if (stack.length === 1) return true;
        if (index !== inverted ? 0 : stack.length - 1) return false;
        if (barIndex === this.$props.bars.length - 1) return true;
        return this.getValue(barIndex + 1) < this.getValue(barIndex);
      } else {
        // usual none stacking behavior, uses first index as bar index
        if (index === this.$props.bars.length - 1) return true;
        return this.getValue(index + 1) < this.getValue(index);
      }
    },
    getValue(barIndex) {
      return this.$props.bars[barIndex].stack
        ? this.getCumulativeValueOfaStack(this.$props.bars[barIndex].stack)
        : this.$props.bars[barIndex].value;
    },
    getCumulativeValueOfaStack(stack) {
      return stack.reduce((p, c) => {
        p += Math.max(c.value, 0);
        return p;
      }, 0);
    },

    onBarClick(Id) {
      if (this.$listeners.click) {
        this.$listeners.click(Id);
      }
    },

    getAllBarWidth(bar) {
      let barValueWidth = !bar.stack
        ? this.getBarWidth(bar.value) + (bar.value === 0 ? 8 : 12)
        : this.getBarWidth(this.getCumulativeValueOfaStack(bar.stack));
      return Math.max(
        barValueWidth +
          (bar.labelOut ? bar.labelOut.length * 10 : 0) +
          (bar.stackLabelOut ? bar.stackLabelOut.length * 10 : 0) +
          (this.inverted ? this.maxWidth - barValueWidth : 0),
        80,
      );
    },
    getVisibleStackIndex(stack) {
      return stack.filter((e) => Number(e.value ?? 0) > 0).length - 1;
    },
    getVisibleStackIndividualIndex(stack, ogIndex) {
      return stack.slice(0, ogIndex).filter((e) => Number(e.value ?? 0) > 0).length - 1;
    },
  },
};
</script>

<style scoped lang="scss">
.tacked-bars-wrapper {
  position: relative;
  .single-bar-wrapper {
    position: relative;
    width: auto;
  }
  .bar-chart {
    display: flex;
    height: 24px;
    padding: 4px 8px 4px 0px;
    .text {
      word-break: keep-all;
      margin-left: auto;
    }
    .label-out {
      position: absolute;
      color: var(--black-secondary);
    }
    &.no-padding {
      padding: 0;
    }
    .label-outside-stack {
      position: absolute;
      margin: 4px;
    }
    &.inverted {
      &:not(.no-padding) {
        padding-left: 8px;
      }
      .text {
        margin-right: auto;
        margin-left: unset;
      }
      .label-outside-stack {
      }
    }
    &.blue {
      background-color: var(--background-blue);
      ::v-deep .text {
        color: var(--white);
      }
    }
    &.green {
      background-color: var(--primary-color);
      ::v-deep .text {
        color: var(--white);
      }
    }
    &.grey {
      background-color: var(--grey-200);
      ::v-deep .text {
        color: var(--black-secondary);
      }
    }
    &.pale-teal {
      background-color: var(--background-pale-teal);
      ::v-deep .text {
        color: var(--black-secondary);
      }
    }
    &.light-teal {
      background-color: var(--background-light-teal);
      ::v-deep .text {
        color: var(--black-secondary);
      }
    }
    &.pale-red {
      background-color: var(--background-pale-red);
      ::v-deep .text {
        color: var(--black-secondary);
      }
    }
    &.dark-red {
      background-color: var(--background-dark-red);
      ::v-deep .text {
        color: var(--black-secondary);
      }
    }
    &.dark-orange {
      background-color: var(--dark-orange);
      ::v-deep .text {
        color: var(--black-secondary);
      }
    }
    &.grey-30 {
      background-color: var(--background-grey-30);
      ::v-deep .text {
        color: var(--black-secondary);
      }
    }
    &.dark-blue {
      background-color: var(--button-blue);
      ::v-deep .text {
        color: var(--white);
      }
    }
    &.grey-300 {
      background-color: var(--background-grey-300);
      ::v-deep .text {
        color: var(--white);
      }
    }
    &.border-radius-top {
      border-top-right-radius: 4px;
    }
    &.border-radius-bottom {
      border-bottom-right-radius: 4px;
    }
    &.border-radius-top-left {
      border-top-left-radius: 4px;
    }
    &.border-radius-bottom-left {
      border-bottom-left-radius: 4px;
    }
  }
  .stacked-bar {
    display: flex;
    flex-direction: row;
    word-break: keep-all;

    .bar-chart {
      &.text-center {
        word-break: keep-all;

        .text:not(.label-outside-stack) {
          margin: auto;
        }
      }
      &.text-hidden {
        .text:not(.label-outside-stack) {
          display: none;
        }
        padding: 0;
        .label-out {
          margin: 4px 8px 4px 0px;
        }
      }
    }
  }
}
</style>
