<template>
  <div class="product-flow-page-wrapper">
    <div class="main-white-box">
      <h1 class="page-headline">Product Flow</h1>
      <div class="product-flow-content no-lines-table">
        <el-table
          :data="tableData"
          header-row-class-name="label-small margin-header header-row"
          :default-sort="{ prop: 'date', order: 'descending' }"
          row-class-name="adjusted-table-height"
          @cell-click="onCellClick"
          :cell-class-name="getClassName"
        >
          <el-table-column
            prop="inboundVials"
            label="Inbound flow of vials"
            align="center"
            header-align="left"
          >
            <template slot-scope="scope">
              <grid-axis
                :default="getMaxValue(scope.row.inboundVials.general) === 0"
                :inverted="true"
                :max-value="getMaxValue(scope.row.inboundVials.general)"
                :step="getStepSize(scope, true, 0.8)"
                :max-width="scope.column.realWidth"
                :min-value="getMinValue(scope.row.inboundVials.general)"
                :width-reduction="0.8"
                :formatter="formatAmountNumberWithoutDecimals"
              />
              <double-tacked-chart
                :bars="scope.row.inboundVials.general"
                :inverted="true"
                :max-width="scope.column.realWidth"
                :step="getStepSize(scope, true, 0.8)"
                :bar-height="20"
              >
                <div slot="tooltip">
                  <div slot="content">
                    <ul class="basic-list">
                      <li>Location name: {{ getInboundTooltipInfo(scope).locationName }}</li>
                      <li>
                        Expected (shipped by WFH): {{ getInboundTooltipInfo(scope).expected }}
                      </li>
                      <li>
                        Actual (received by {{ getInboundTooltipInfo(scope).receivedName }}):
                        {{ getInboundTooltipInfo(scope).received }}
                      </li>
                    </ul>
                  </div>
                </div>
              </double-tacked-chart>
            </template>
          </el-table-column>
          <el-table-column
            prop="sourceName"
            label="Distribution Center"
            :width="180"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <div class="column-word">
                {{
                  `${scope.row.distributionCenter}${scope.row.city ? ', ' + scope.row.city : ''}`
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="outboundVials"
            label="Outbound flow of vials"
            align="center"
            header-align="right"
          >
            <template slot-scope="scope">
              <grid-axis
                v-if="getMaxValue(scope.row.outboundVials.general) > 0"
                :inverted="false"
                :max-value="getMaxValue(scope.row.outboundVials.general)"
                :step="getStepSize(scope, false, 0.8)"
                :max-width="scope.column.realWidth"
                :min-value="getMinValue(scope.row.outboundVials.general)"
                :width-reduction="0.8"
                :formatter="formatAmountNumberWithoutDecimals"
              />
              <double-tacked-chart
                :max-value="getMaxValue(scope.row.outboundVials.general)"
                :min-value="getMinValue(scope.row.outboundVials.general)"
                :bars="scope.row.outboundVials.general"
                :max-width="scope.column.realWidth"
                :step="getStepSize(scope, false, 0.8)"
                :bar-height="20"
              >
                <div slot="tooltip">
                  <div slot="content">
                    <ul class="basic-list">
                      <li>Location name: {{ getOutboundTooltipInfo(scope).locationName }}</li>
                      <li>
                        Expected (shipped by {{ getOutboundTooltipInfo(scope).receivedName }}):
                        {{ getOutboundTooltipInfo(scope).expected }}
                      </li>
                      <li>
                        Actual (received by HTCs): {{ getOutboundTooltipInfo(scope).received }}
                      </li>
                    </ul>
                  </div>
                </div>
              </double-tacked-chart>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import DoubleTackedChart from '../components/charts/DoubleTackedChart';
import GridAxis from '../components/basic/GridAxis';
import { openSideBar } from '../utils/eventBus';

import { productFlowPageDescription, sliderTypes } from '../utils/enums';
import {
  formatAmountNumber,
  formatAmountNumberWithoutDecimals,
  formatRegularAmounts,
} from '../utils/numberFormatter';
import {} from '../utils/list';
export default {
  name: 'ProductFlow',
  components: { GridAxis, DoubleTackedChart },
  data() {
    return {
      bars: [
        {
          value: 18,
          label: '18',
          labelOut: '15k',
          color: 'green',
        },
        {
          value: 12,
          label: '12',
          labelOut: '12k',
          color: 'blue',
        },
      ],
      tableData: [],
    };
  },
  computed: {
    selectedFilters() {
      return this.$store.getters.getAllLocationFilters;
    },
  },
  watch: {
    selectedFilters: {
      deep: true,
      handler() {
        this.fetchEvents();
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.fetchEvents();
    });
  },
  methods: {
    async fetchEvents() {
      const loading = this.$loading();
      try {
        const { data } = await this.$http.get('events/productFlow', {
          params: { ...this.selectedFilters, sortBy: this.sort },
        });
        this.tableData = data.map((e) => {
          const actualOutbound = Number(e.actualOutbound ?? '0');
          const expectedOutBound = Number(e.expectedOutBound ?? '0');
          const actualInbound = Number(e.actualInbound ?? '0');
          const expectedInbound = Number(e.expectedInBound ?? '0');
          return {
            inboundVials: {
              general: [
                {
                  value: expectedInbound,
                  labelOut: formatAmountNumber(expectedInbound),
                  color: 'blue',
                  valueType: productFlowPageDescription.PRODUCT_AMOUNT_EXPECTED,
                },
                {
                  value: actualInbound,
                  labelOut: formatAmountNumber(actualInbound),
                  color: 'green',
                  valueType: productFlowPageDescription.PRODUCT_AMOUNT_RECEIVED,
                },
              ],
            },
            city: e.city,
            type: e.type,
            distributionCenter: e.sourceName,
            source: e.source,
            outboundVials: {
              general: [
                {
                  value: expectedOutBound,
                  labelOut: formatAmountNumber(expectedOutBound),
                  color: 'blue',
                  valueType: productFlowPageDescription.PRODUCT_AMOUNT_EXPECTED,
                },
                {
                  value: actualOutbound,
                  labelOut: formatAmountNumber(actualOutbound),
                  color: 'green',
                  valueType: productFlowPageDescription.PRODUCT_AMOUNT_RECEIVED,
                },
              ],
            },
          };
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          message: "Couldn't get data for events tab. Please try later.",
        });
      } finally {
        loading.close();
      }
    },
    handleSortChange(val) {
      this.sort = val.order ? `${val.prop}:${val.order}` : null;
      this.fetchEvents();
    },
    formatAmountNumberWithoutDecimals,
    formatAmountNumber,
    getStepSize(scope, inbound = true, reduction = 0.9) {
      const maxValue = Math.max(
        ...(inbound
          ? scope.row.inboundVials.general.map((e) => e.value)
          : scope.row.outboundVials.general.map((e) => e.value)),
      );
      return maxValue > 0
        ? (scope.column.realWidth * reduction) /
            Math.max(
              ...(inbound
                ? scope.row.inboundVials.general.map((e) => e.value)
                : scope.row.outboundVials.general.map((e) => e.value)),
            )
        : 0;
    },

    getMaxValue(bars) {
      return Math.max(...bars.map((e) => e.value));
    },
    getMinValue(bars) {
      return Math.min(...bars.map((e) => e.value));
    },

    onCellClick(row, column) {
      const data = row;
      const type = column.property;

      const sliderData = {
        source: data.source,
        distributionCenter: data.distributionCenter,
        city: data.city,
        locationType: data.type,
        locationName: data.distributionCenter,
      };
      switch (type) {
        case 'inboundVials': {
          openSideBar(sliderTypes.INBOUND_FLOW, sliderData, {});
          break;
        }
        case 'outboundVials': {
          openSideBar(sliderTypes.INBOUND_FLOW, sliderData, { inbound: false });
          break;
        }
        default: {
          console.log('none');
        }
      }
    },

    getInboundTooltipInfo(scope) {
      return {
        locationName: `${scope.row.distributionCenter}${
          scope.row.city ? ', ' + scope.row.city : ''
        }`,
        expected: formatRegularAmounts(
          scope.row.inboundVials.general
            .find((e) => e.valueType === productFlowPageDescription.PRODUCT_AMOUNT_EXPECTED)
            ?.value?.toFixed(1),
        ),
        received: formatRegularAmounts(
          scope.row.inboundVials.general
            .find((e) => e.valueType === productFlowPageDescription.PRODUCT_AMOUNT_RECEIVED)
            ?.value?.toFixed(1),
        ),
        receivedName: `${scope.row.distributionCenter}${
          scope.row.city ? ', ' + scope.row.city : ''
        }`,
      };
    },
    getOutboundTooltipInfo(scope) {
      return {
        locationName: `${scope.row.distributionCenter}${
          scope.row.city ? ', ' + scope.row.city : ''
        }`,
        expected: formatRegularAmounts(
          scope.row.outboundVials.general
            .find((e) => e.valueType === productFlowPageDescription.PRODUCT_AMOUNT_EXPECTED)
            ?.value?.toFixed(1),
        ),
        received: formatRegularAmounts(
          scope.row.outboundVials.general
            .find((e) => e.valueType === productFlowPageDescription.PRODUCT_AMOUNT_RECEIVED)
            ?.value?.toFixed(1),
        ),
        receivedName: `${scope.row.distributionCenter}${
          scope.row.city ? ', ' + scope.row.city : ''
        }`,
      };
    },
    getClassName(cell) {
      let classNames = '';
      if (cell.columnIndex === 1) {
        classNames += ' location-name-column';
      }

      return classNames;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/variables';

.product-flow-page-wrapper {
  .main-white-box {
    background-color: var(--white);
    padding: 24px;

    h1 {
      text-align: start;
      margin-bottom: 34px;
    }
  }

  .product-flow-content {
    ::v-deep .label-small {
      th {
        font-weight: 400;
      }
    }

    ::v-deep .header-row {
      th {
        font-size: 14px;
        color: var(--black-tertiary);
        text-align: center;
      }
    }
  }

  ::v-deep .adjusted-table-height {
    height: 96px;
    .el-table__cell {
      padding-top: 0;
    }

    td {
      padding-top: 5px;
      padding-bottom: 5px;
      .grid-axis-lines-wrapper {
        height: 60px;
      }
      .cell {
        margin-top: -20px;
      }
    }
  }

  ::v-deep .location-name-column {
    .cell {
      color: var(--black-primary);
      text-align: center;
      height: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: flex;
      .column-word {
        margin: auto;
      }
    }
  }

  ::v-deep .margin-header {
    th {
    }
  }

  ::v-deep .el-table {
    .cell {
      padding-left: 0;
      padding-right: 0;
    }
    .column-word {
      .cell {
        word-break: break-word;
      }
    }
  }
}
</style>
