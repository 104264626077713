<template>
  <base-slider v-bind="$props" v-on="$listeners" title="GO BACK" :size="['75%', '100%']">
    <div class="utilization-product-breakdown-slider" v-if="utilizationProductDetails">
      <h2 class="label-extra-large">Utilization: Product breakdown</h2>
      <div class="utilization-product-breakdown-slider-content">
        <div class="content-header">
          <h3 class="label-large">Country: {{ utilizationProductDetails.countryName }}</h3>
          <h3 class="label-large" v-if="utilizationProductDetails.treatmentCenterName">
            Treatment center: {{ utilizationProductDetails.treatmentCenterName }}
          </h3>
          <h5 class="label-medium total">
            Total vials shipped:

            <span class="label-bold">{{ totalShipped }}</span>
          </h5>
          <h5 class="label-medium total">
            Total vials utilized:

            <span class="label-bold">{{ totalUtilized }}</span>
          </h5>
        </div>

        <div class="utilization-product-breakdown-table">
          <utilization-product-breakdown-table
            @sortChange="handleSortChange"
            :table-data="tableData"
            :max="utilizedMax"
          />
          <div class="pagination">
            <el-pagination
              @size-change="handleLimitChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-sizes="[5, 10, 25, 50, 100]"
              :page-size="limit"
              :total="total"
              layout="prev, pager, next, sizes, total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </base-slider>
</template>

<script>
import { resolveLocationType } from '../../utils/nameResolver';
import BaseSlider from './BaseSlider';
import UtilizationProductBreakdownTable from '../UtilizationProductBreakdownTable';
import { formatAmountNumber } from '@/utils/numberFormatter';
import { vialsShipmentStatus, vialStatus } from '@/utils/enums';
import { prettyPrintDecimal } from '@/utils/utils';

export default {
  name: 'UtilizationProductBreakdown',
  components: { UtilizationProductBreakdownTable, BaseSlider },
  props: {
    utilizationProductDetails: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      limit: 10,
      total: 0,
      sortBy: 'utilized:descending',
      utilizedMax: 0,
    };
  },
  computed: {
    totalShipped() {
      return prettyPrintDecimal(this.utilizationProductDetails.totalShipped);
    },
    totalUtilized() {
      return prettyPrintDecimal(this.utilizationProductDetails.totalUtilized);
    },
  },
  watch: {
    utilizationProductDetails() {
      this.resetPagination();
      this.fetchProductData();
    },
  },
  methods: {
    resetPagination() {
      this.currentPage = 1;
      this.limit = 10;
      this.total = 0;
      this.sortBy = 'utilized:descending';
    },
    fetchProductData() {
      if (this.utilizationProductDetails.id) {
        this.fetchLocationBreakdown(this.utilizationProductDetails.id);
      } else {
        this.fetchCountryBreakdown(this.utilizationProductDetails.countryName);
      }
    },
    handleSortChange(sortBy) {
      this.sortBy = sortBy;
      this.fetchProductData();
    },
    handleLimitChange(limit) {
      this.limit = limit;
      this.fetchProductData();
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.fetchProductData();
    },
    resolveLocationType,
    async fetchLocationBreakdown(locationId) {
      const params = {
        ...this.utilizationProductDetails.filters,
        sortBy: !this.sortBy.endsWith('null') ? this.sortBy : 'utilized:descending',
        limit: this.limit,
        offset: this.currentPage * this.limit - this.limit,
      };
      this.$nextTick(async () => {
        const l = this.$loading({ target: '.utilization-product-breakdown-slider' });
        try {
          const { data } = await this.$http.get(`utilization/locations/${locationId}`, {
            params,
          });
          this.total = data.total;
          this.utilizedMax = data.max;
          this.tableData = data.products.map((e) => {
            return {
              manufacturer: e.manufacturer,
              product: e.productName,
              lot: e.lot,
              utilization: parseFloat(e.utilization),
              vials: [
                {
                  value: e.shippedTo,
                  label: formatAmountNumber(e.shippedTo),
                  color: 'blue',
                  type: vialsShipmentStatus.VIALS_SHIPPED,
                },
                {
                  value: e.utilized,
                  type: vialsShipmentStatus.VIALS_USED,
                  labelOut: formatAmountNumber(e.utilized),
                  stackLabelOut: formatAmountNumber(e.utilized),
                  stack: [
                    {
                      value: e.surgery,
                      label: formatAmountNumber(e.surgery),
                      ogValue: e.surgery,
                      labelOut: null,
                      color: 'grey',
                      type: vialStatus.SURGERY,
                    },
                    {
                      value: e.acuteBleed,
                      label: formatAmountNumber(e.acuteBleed),
                      ogValue: e.acuteBleed,
                      labelOut: null,
                      color: 'pale-teal',
                      type: vialStatus.ACUTE_BLOOD,
                    },
                    {
                      value: e.prophylaxis,
                      label: formatAmountNumber(e.prophylaxis),
                      ogValue: e.prophylaxis,
                      labelOut: null,
                      color: 'light-teal',
                      type: vialStatus.PROPHYLAXIS,
                    },
                    {
                      value: e.other,
                      label: formatAmountNumber(e.other),
                      ogValue: e.other,
                      labelOut: null,
                      color: 'pale-red',
                      type: vialStatus.OTHER,
                    },
                    {
                      value: e.destroyed,
                      label: formatAmountNumber(e.destroyed),
                      ogValue: e.destroyed,
                      labelOut: null,
                      color: 'dark-red',
                      type: vialStatus.DESTROYED,
                    },
                  ],
                },
              ],
            };
          });
        } catch (e) {
          this.$notify({ message: 'Failed to fetch product breakdown.', type: 'error' });
        } finally {
          l.close();
        }
      });
    },
    fetchCountryBreakdown(country) {
      const params = {
        ...this.utilizationProductDetails.filters,
        sortBy: !this.sortBy.endsWith('null') ? this.sortBy : 'eventTime:descending',
        limit: this.limit,
        offset: this.currentPage * this.limit - this.limit,
      };

      this.$nextTick(async () => {
        const l = this.$loading({ target: '.utilization-product-breakdown-slider' });
        try {
          const { data } = await this.$http.get(`utilization/countries/${country}`, {
            params,
          });
          this.total = data.total;
          this.utilizedMax = data.max;

          this.tableData = data.products.map((e) => {
            return {
              manufacturer: e.manufacturer,
              product: e.productName,
              lot: e.lot,
              utilization: parseFloat(e.utilization),
              vials: [
                {
                  value: e.shippedTo,
                  label: formatAmountNumber(e.shippedTo),
                  color: 'blue',
                  type: vialsShipmentStatus.VIALS_SHIPPED,
                },
                {
                  value: e.utilized,
                  type: vialsShipmentStatus.VIALS_USED,
                  labelOut: formatAmountNumber(e.utilized),
                  stackLabelOut: formatAmountNumber(e.utilized),
                  stack: [
                    {
                      value: e.surgery,
                      label: formatAmountNumber(e.surgery),
                      ogValue: e.surgery,
                      labelOut: null,
                      color: 'grey',
                      type: vialStatus.SURGERY,
                    },
                    {
                      value: e.acuteBleed,
                      label: formatAmountNumber(e.acuteBleed),
                      ogValue: e.acuteBleed,
                      labelOut: null,
                      color: 'pale-teal',
                      type: vialStatus.ACUTE_BLOOD,
                    },
                    {
                      value: e.prophylaxis,
                      label: formatAmountNumber(e.prophylaxis),
                      ogValue: e.prophylaxis,
                      labelOut: null,
                      color: 'light-teal',
                      type: vialStatus.PROPHYLAXIS,
                    },
                    {
                      value: e.other,
                      label: formatAmountNumber(e.other),
                      ogValue: e.other,
                      labelOut: null,
                      color: 'pale-red',
                      type: vialStatus.OTHER,
                    },
                    {
                      value: e.destroyed,
                      label: formatAmountNumber(e.destroyed),
                      ogValue: e.destroyed,
                      labelOut: null,
                      color: 'dark-red',
                      type: vialStatus.DESTROYED,
                    },
                  ],
                },
              ],
            };
          });
        } catch (e) {
          this.$notify({ message: 'Failed to fetch product breakdown.', type: 'error' });
        } finally {
          l.close();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.utilization-product-breakdown-slider {
  padding-bottom: 1em;

  .utilization-product-breakdown-slider-content {
    align-self: start;
    text-align: start;

    .content-header,
    .utilization-product-breakdown-table {
      padding-left: 4rem;
      padding-right: 1rem;

      margin-top: 1.5em;
      ::v-deep .overflow-visible {
        .cell {
          overflow: visible;
        }
      }

      ::v-deep .adjusted-table-height {
        height: 96px;
        .el-table__cell {
          padding-top: 0;
        }
      }

      ::v-deep .el-table {
        .column-with-bars {
          .cell {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      .pagination {
        margin-top: auto;
        text-align: center;
        margin-bottom: 35px;
      }
    }

    .content-header {
      margin-bottom: 1em;

      .label-medium {
        margin-bottom: 1em;
      }

      .label-large {
        color: var(--black-primary);
      }

      .total {
        margin-top: 16px;

        &:last-of-type {
          margin-bottom: 32px;
        }
      }
    }

    .filter-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      * {
        margin-top: auto;
        margin-bottom: auto;
      }

      .red-flag-filter {
        text-align: start;

        h5 {
          padding-left: 0;
          margin-bottom: 8px;
        }
      }
    }
  }

  h2.label-extra-large {
    padding-left: 4rem;
    align-self: start;
    text-align: start;
    margin-bottom: 24px;
  }
}
</style>
