import {
  mapMarkers,
  centerType,
  eventType,
  vialDestructionReason,
  issueTypes,
  contexts,
} from './enums';

export const resolveLocationType = (key) => {
  switch (key) {
    case centerType.DISTRIBUTION_CENTER: {
      return 'Distribution center';
    }
    case centerType.TREATMENT_CENTER: {
      return 'Treatment center';
    }
    default:
      return key;
  }
};

export const resolveIssueType = (key) => {
  switch (key) {
    case issueTypes.IDLE_STOCK: {
      return 'Idle Stock';
    }
    case issueTypes.DAMAGED: {
      return 'Damaged product';
    }
    default:
      return key;
  }
};

export const resolveEventType = (key) => {
  switch (key) {
    case eventType.SHIPPING: {
      return 'Shipping';
    }
    case eventType.RECEIVING: {
      return 'Receiving';
    }
    case eventType.DISPENSING: {
      return 'Treatment';
    }
    case eventType.DESTRUCTION: {
      return 'Destroying';
    }
    default:
      return key;
  }
};

export const resolveDestructionReason = (key) => {
  switch (key) {
    case 'DAMAGED': {
      return 'Damaged product';
    }
    default:
      return key;
  }
};

export const resolveIconsFromLocationType = (key) => {
  switch (key) {
    case mapMarkers.RED_FLAG: {
      return 'RED_FLAG';
    }
    case mapMarkers.DANGER_DISTRIBUTION_CENTER: {
      return 'DANGER_DISTRIBUTION_CENTER';
    }
    case mapMarkers.DANGER_TREATMENT_CENTER: {
      return 'RED_SITE';
    }
    case mapMarkers.DISTRIBUTION_CENTER: {
      return 'DISTRIBUTION_CENTER';
    }
    case mapMarkers.TREATMENT_CENTER: {
      return 'SITE';
    }
    default:
      return 'SITE';
  }
};

export const resolveIconURIBasedOnMarkers = (key) => {
  switch (key) {
    case mapMarkers.RED_FLAG: {
      return `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/flag.svg`;
    }
    case mapMarkers.DANGER_DISTRIBUTION_CENTER: {
      return `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/dangerSite.svg`;
    }
    case mapMarkers.DANGER_TREATMENT_CENTER: {
      return `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/dangerTriangle.svg`;
    }
    case mapMarkers.DISTRIBUTION_CENTER: {
      return `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/distribution_center.svg`;
    }
    case mapMarkers.TREATMENT_CENTER: {
      return `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/site.svg`;
    }
    case mapMarkers.EVENT_MARKER: {
      return `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/event_marker.svg`;
    }
    default:
      return `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/site.svg`;
  }
};

export const resolveVialDestructionReasonName = (key) => {
  switch (key) {
    case vialDestructionReason.DAMAGED: {
      return 'Damaged';
    }
    case vialDestructionReason.EXPIRED: {
      return 'Expired';
    }
    case vialDestructionReason.RECALLED: {
      return 'Recalled';
    }
    default:
      return key;
  }
};

export const resolveVialDestructionColorName = (key) => {
  switch (key) {
    case vialDestructionReason.DAMAGED: {
      return 'green';
    }
    case vialDestructionReason.EXPIRED: {
      return 'blue';
    }
    case vialDestructionReason.RECALLED: {
      return 'orange';
    }
    default:
      return 'black';
  }
};

export const contextNameResolver = (key) => {
  switch (key) {
    case contexts.HFI_ADMIN: {
      return 'HFI Dashboard';
    }
    case contexts.WFH_ADMIN: {
      return 'WFH Dashboard';
    }
    default: {
      return 'WFH Dashboard';
    }
  }
};
