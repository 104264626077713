<template>
  <div class="resolved-issues-page-wrapper">
    <div class="main-white-box">
      <h1 class="page-headline">Resolved issue</h1>
      <div class="resolved-issues-list-wrapper">
        <el-table
          :data="tableData"
          header-row-class-name="label-medium header-row"
          :default-sort="{ prop: 'date', order: 'descending' }"
          cell-class-name="overflow-visible"
          @cell-click="onCellClick"
        >
          <el-table-column prop="issueId" label="Issue ID" :width="120" align="left">
            <template slot-scope="scope" slot="header">
              <header-with-sorting>
                {{ scope.column.label }}
              </header-with-sorting>
            </template>
          </el-table-column>
          <el-table-column
            prop="issueType"
            label="Issue type"
            :min-width="80"
            align="left"
            :formatter="(ev) => resolveIssueType(ev.issueType)"
          >
            <template slot-scope="scope" slot="header">
              <header-with-sorting>
                {{ scope.column.label }}
              </header-with-sorting>
            </template>
          </el-table-column>
          <el-table-column prop="eventId" label="Event ID" :min-width="80" align="left">
            <template slot-scope="scope" slot="header">
              <header-with-sorting>
                {{ scope.column.label }}
              </header-with-sorting>
            </template>
          </el-table-column>
          <el-table-column prop="location" label="Location" :min-width="100" align="left">
            <template slot-scope="scope" slot="header">
              <header-with-sorting>
                {{ scope.column.label }}
              </header-with-sorting>
            </template>
          </el-table-column>
          <el-table-column
            prop="resolvedDate"
            label="Date resolved"
            :min-width="100"
            align="left"
            :formatter="(row) => row.resolvedDate.toLocaleDateString()"
          >
            <template slot-scope="scope" slot="header">
              <header-with-sorting>
                {{ scope.column.label }}
              </header-with-sorting>
            </template>
          </el-table-column>
          <el-table-column
            prop="resolutionDetails"
            label="Resolution details"
            align="left"
            :min-width="100"
          >
            <template slot-scope="scope" slot="header">
              <header-with-sorting>
                {{ scope.column.label }}
              </header-with-sorting>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { resolvedIssues } from '../utils/list';
import { openSideBar } from '../utils/eventBus';
import { sliderTypes } from '../utils/enums';
import { resolveIssueType } from '../utils/nameResolver';
import HeaderWithSorting from '../components/basic/HeaderwithSorting';

export default {
  name: 'ResolvedIssues',
  components: { HeaderWithSorting },
  props: {
    tableData: {
      type: Array,
      default: () => resolvedIssues(),
    },
  },
  methods: {
    resolveIssueType,
    onCellClick(...args) {
      openSideBar(sliderTypes.EVENT_DETAILS, args[0]['event']);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/variables';

.resolved-issues-page-wrapper {
  .main-white-box {
    h1 {
      text-align: start;
      margin-bottom: 34px;
    }
    .page-headline-description {
      text-align: start;
      margin-bottom: 34px;
    }
    .resolved-issues-list-wrapper {
      ::v-deep .label-medium {
        color: var(--black-disabled);
        th {
          font-weight: 400;
        }
      }
    }
    ::v-deep .header-row {
      th {
        font-size: 14px;
        color: var(--black-tertiary);
      }
    }
  }
}
</style>
