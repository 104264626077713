<template>
  <div class="login-page-wrapper">
    <div class="login-card">
      <div class="title-row" v-if="!passwordResetSent">
        <img :src="`${$endPoint}/assets/icons/aidtrust-logo.svg`" />
        <h3 class="label-large page-name">Forgot Password</h3>
        <p class="label-medium">
          Enter you email and we will send you a link to reset your password.
        </p>
      </div>
      <div class="title-row" v-else>
        <img :src="`${$endPoint}/assets/icons/aidtrust-logo.svg`" />
        <h3 class="label-large page-name">Check your email</h3>
        <p class="label-medium">You'll receive an email with a link to reset your password.</p>
      </div>
      <div class="login-form" v-if="!passwordResetSent">
        <el-form
          :rules="rules"
          :model="formModel"
          :hide-required-asterisk="true"
          @submit.native.prevent
        >
          <el-form-item label="Email" prop="email">
            <el-input
              v-model="formData.email.value"
              clearable
              :class="{ 'input--error': formData.email.error }"
              @blur="formData.email.validate()"
              placeholder="Please input your email"
              @keydown.native.enter="resetPassword"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" class="w-100" @click="resetPassword">SEND</el-button>
      </div>
      <div class="forgot-password-section label-small" v-if="!passwordResetSent">
        <div @click="goBack" class="forgot-password">Go back</div>
      </div>
    </div>
  </div>
</template>

<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';

export default {
  name: 'ForgotPassword',
  mixins: [FormValidationMixin],
  data() {
    return {
      email: '',
      password: '',
      passwordResetSent: false,
      formData: {
        email: { value: '', error: false, validate: () => this.isEmail('email') },
      },
      rules: {
        email: [
          {
            required: true,
            message: '* Please fill in your email',
            trigger: ['blur', 'change'],
          },
          {
            type: 'email',
            message: '* Please fill in a correct email',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  methods: {
    resetPassword(event) {
      if (event) {
        event.stopImmediatePropagation();
      }
      if (!this.validateForm()) {
        return;
      }
      const loader = this.$loading({ target: '.login-card' });
      this.isLoading = true;
      this.$http
        .post('auth/password-reset/emails', {
          email: this.formData.email.value,
        })
        .then(() => {
          this.passwordResetSent = true;
        })
        .catch(({ data }) => {
          this.$notify({
            type: 'error',
            message: data.message ?? "Couldn't reset your password, please contact our support",
          });
        })
        .finally(() => {
          this.isLoading = false;
          loader.close();
        });
    },

    goBack() {
      history.back();
    },
  },
};
</script>

<style scoped lang="scss">
.login-page-wrapper {
  display: flex;
  height: 100vh;
  overflow-y: scroll;
  background: var(--background-grey);
  .login-card {
    background: var(--white);
    padding: 32px;
    margin: auto;
    width: 420px;
    box-shadow: 0px 2px 4px rgba(82, 97, 115, 0.2);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    .title-row {
      margin-top: 12px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      * {
        margin-top: 21px;
      }
      img {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 22px;
      }
      .label-medium {
        line-height: 24px;
      }
    }
    .page-name {
      color: var(--black-primary);
    }
    .login-form {
      margin-bottom: 49px;
      margin-top: 52px;
      .el-form-item {
        margin-bottom: 15px;
      }
      .el-button {
        margin-top: 12px;
        font-family: Roboto;
      }
      .el-button {
        width: 100%;
        background-color: var(--button-blue);
        border-color: var(--button-blue);
      }
    }
    .forgot-password-section {
      margin-top: auto;
      margin-bottom: 22px;
      display: flex;
      .forgot-password {
        margin: auto;
        text-decoration: underline;
        color: var(--blue-brand);
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
