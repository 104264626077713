<template>
  <div class="login-page-wrapper">
    <div class="login-card">
      <div class="title-row">
        <img :src="`${$endPoint}/assets/icons/aidtrust-logo.svg`" />
        <h3 class="label-large page-name">Change Password</h3>
      </div>
      <div class="login-form">
        <el-form
          ref="changePasswordform"
          :rules="rules"
          :model="formModel"
          :hide-required-asterisk="true"
        >
          <el-form-item label="Current password" prop="currentPassword">
            <el-input
              v-model="formData.currentPassword.value"
              clearable
              :class="{ 'input--error': formData.currentPassword.error }"
              @blur="formData.currentPassword.validate()"
              placeholder="Please input your password"
              type="password"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="New Password" prop="password">
            <el-input
              v-model="formData.password.value"
              clearable
              :class="{ 'input--error': formData.password.error }"
              @blur="formData.password.validate()"
              placeholder="Please input your password"
              type="password"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="Confirm Password" prop="confirmPassword">
            <el-input
              v-model="formData.confirmPassword.value"
              clearable
              :class="{ 'input--error': formData.confirmPassword.error }"
              @blur="formData.confirmPassword.validate()"
              placeholder="Please confirm your password"
              type="password"
              show-password
              @keydown.native.enter="onChangePassword"
            ></el-input>
          </el-form-item>
        </el-form>
        <ErrorMessageBox :message="submittingError" v-if="!!submittingError" />
        <el-button type="primary" class="w-100" @click="onChangePassword">SAVE</el-button>
        <el-button type="info" class="w-100" @click="goBack">CANCEL</el-button>
      </div>
      <div class="forgot-password-section">
        <a href="/forgot-password" class="forgot-password label-small">Forgot password</a>
      </div>
    </div>
  </div>
</template>

<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';
import ErrorMessageBox from '@/components/basic/ErrorMessageBox';

export default {
  name: 'ChangePassword',
  components: { ErrorMessageBox },
  mixins: [FormValidationMixin],
  data() {
    return {
      email: '',
      password: '',
      changed: false,
      submittingError: null,
      formData: {
        password: { value: '', error: false, validate: () => this.isNotEmpty('password', 6) },
        confirmPassword: {
          value: '',
          error: false,
          validate: () => this.isNotEmpty('confirmPassword', 6),
        },
        currentPassword: {
          value: '',
          error: false,
          validate: () => this.isNotEmpty('currentPassword', 6),
        },
      },
      rules: {
        password: [
          {
            required: true,
            message: '* Please fill in your new password',
            trigger: ['blur', 'change'],
          },
          {
            min: 6,
            message: '* Your new password needs to be at least 6 characters at length',
            trigger: ['blur', 'change'],
          },
        ],
        confirmPassword: [
          {
            validator: (r, v, c) => (this.dependentOn('password', v, true) ? c() : c(false)),
            message: '* New passwords do not match.',
            trigger: ['blur', 'change'],
          },
          {
            required: true,
            message: '* Please confirm your password',
            trigger: ['blur', 'change'],
          },
        ],
        currentPassword: [
          {
            required: true,
            message: '* Please fill in your password',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  methods: {
    onChangePassword() {
      this.submittingError = null;
      if (
        !this.validateForm() ||
        this.formData.password.value !== this.formData.confirmPassword.value
      ) {
        return;
      }
      this.isLoading = true;
      const loader = this.$loading({ target: '.login-card' });
      this.$http
        .post('/auth/password-change', {
          newPassword: this.formData.password.value,
          newPassword_confirmation: this.formData.confirmPassword.value,
          currentPassword: this.formData.currentPassword.value,
        })
        .then(() => {
          this.$notify({
            type: 'success',
            message: 'Password changed successfully',
          });
          this.changed = true;
          setTimeout(() => {
            this.$router.push({ name: 'login' });
            this.$store.commit('LOGOUT');
          }, 200);
        })
        .catch(({ data }) => {
          this.submittingError = data.message ?? 'Unknown error occurred';
          this.$notify({
            type: 'error',
            message: "Couldn't change your password, please contact our support",
          });
        })
        .finally(() => {
          loader.close();
          this.isLoading = false;
        });
    },
    goBack() {
      history.back();
    },
  },
};
</script>

<style scoped lang="scss">
.login-page-wrapper {
  display: flex;
  height: 100vh;
  overflow-y: scroll;
  background: var(--background-grey);
  .login-card {
    background: var(--white);
    padding: 32px;
    margin: auto;
    width: 420px;
    box-shadow: 0px 2px 4px rgba(82, 97, 115, 0.2);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    .title-row {
      margin-top: 12px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      * {
        margin-top: 21px;
      }
      img {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 22px;
      }
    }
    .page-name {
      color: var(--black-primary);
    }
    .login-form {
      margin-bottom: 49px;
      margin-top: 52px;
      .el-form-item {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
      .el-button {
        margin-top: 25px;
        font-family: Roboto;
      }
      .el-button + .el-button {
        margin-left: 0px;
        margin-top: 16px;
      }
      .el-button {
        width: 100%;
        background-color: var(--button-blue);
        border-color: var(--button-blue);
        &.el-button--info {
          background-color: var(--grey-150);
          border-color: var(--grey-150);
          color: var(--black);
        }
      }
      .error-label {
        text-align: left;
        margin-top: 6px;
      }
      .error-box {
        margin-top: 28px;
        margin-bottom: 16px;
      }
    }
    .forgot-password-section {
      margin-top: auto;
      margin-bottom: 22px;
      display: flex;
      .forgot-password {
        margin: auto;
        text-decoration: underline;
        color: var(--blue-brand);
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
