<template>
  <div class="small-image-box" ref="imageContainer" @click="openImage()">
    <img :src="imgObject.image" :alt="imgObject.filename" />
  </div>
</template>

<script>
export default {
  name: 'ImageContainer',
  props: {
    imgObject: {
      type: Object,
    },
  },
  methods: {
    openImage() {
      this.$emit('openImagesSlider', this.imgObject);
    },
  },
};
</script>

<style scoped lang="scss">
.small-image-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 144px;
  min-width: 144px;
  height: 144px;
  min-height: 144px;
  background-color: var(--grey-100);
  border-radius: 4px;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
  img {
    min-width: 100%;
    height: auto;
  }
}
</style>
