<template>
  <el-button
    :class="{
      'black-button': true,
      [color]: !!color,
    }"
    :plain="!text"
    :type="text ? 'text' : 'plain'"
    v-bind="$props"
    v-on="$listeners"
  >
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  name: 'BlackButton',
  props: {
    text: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/variables';
.black-button {
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}
.black-button.is-plain,
.black-button.is-plain:focus {
  background: var(--black);
  color: var(--white);
  border: none;
  &.blue {
    background: var(--button-navigation-blue);
    &:hover {
      background: var(--button-navigation-blue);
      color: var(--white);
      border: none;
    }
  }
  &:focus {
    border: none;
  }
  &:hover {
    background: var(--black);
    color: var(--white);
    border: none;
  }
}
.black-button.el-button--text,
.black-button.el-button--text:focus {
  background: transparent;
  color: var(--white-primary);
  padding-right: 20px;
  padding-left: 20px;
  border: none;
  &:hover {
    background: var(--grey-100);
    color: var(--black-primary);
    border: none;
  }
}
</style>
