import { render, staticRenderFns } from "./EventDetailSlider.vue?vue&type=template&id=45f6fa66&scoped=true&"
import script from "./EventDetailSlider.vue?vue&type=script&lang=js&"
export * from "./EventDetailSlider.vue?vue&type=script&lang=js&"
import style0 from "./EventDetailSlider.vue?vue&type=style&index=0&id=45f6fa66&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f6fa66",
  null
  
)

export default component.exports