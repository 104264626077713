<template>
  <div>
    <el-dialog
      title=""
      class=""
      :append-to-body="true"
      :show-close="false"
      :visible.sync="visibleModal"
      width="40%"
      @close="onClose"
    >
      <div class="resolve-dialog-wrapper">
        <div class="resolve-dialog-header">
          <img
            class="close-image clickable"
            @click="closeModal()"
            :src="`${$endPoint}/assets/icons/close.svg`"
            alt=""
          />
        </div>
        <div class="resolve-dialog-content" v-if="visibleModal">
          <h4 class="issue-title label-medium label-bold">Event ID: {{ eventId }}</h4>
          <div class="destruction-photos-list" v-if="images">
            <div class="images">
              <div class="uploaded">
                <template v-for="(img, i) in imgObjects">
                  <image-container
                    class="image"
                    :key="i"
                    :img-object="img"
                    @openImagesSlider="openImagesSlider(img, i)"
                  ></image-container>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <images-slider-component
      :is-visible="imagesSliderVisible"
      :images="imgObjects"
      :selected-image-index="selectedImageIndex"
      :on-close="() => (imagesSliderVisible = false)"
    ></images-slider-component>
  </div>
</template>

<script>
import ImagesSliderComponent from '../modals/ImagesSliderComponent';
import ImageContainer from '@/components/ImageContainer';
export default {
  name: 'DestructionEventImagesModal',
  components: {
    ImagesSliderComponent,
    ImageContainer,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: String,
      default: null,
    },
    images: {
      type: Array,
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      visibleModal: this.isVisible,
      imgObjects: [],
      imagesSliderVisible: false,
      selectedImageIndex: null,
    };
  },
  watch: {
    isVisible: function (newValue) {
      this.visibleModal = newValue;
      if (this.visibleModal) {
        this.$nextTick(() => {
          this.retrieveImages(this.images.map((e) => e.url));
        });
      }
    },
  },
  methods: {
    closeModal() {
      this.visibleModal = false;
      if (this.onClose) {
        this.onClose();
      }
    },
    async retrieveImages(proofs) {
      this.isLoading = true;
      this.imgObjects = [];
      const loader = this.$loading({ target: '.resolve-dialog-wrapper' });
      for (const chunk of this.getChunks(proofs, 3)) {
        const promises = chunk.map(this.fetchImage);
        await Promise.all(promises);
      }
      loader.close();
      this.isLoading = false;
    },
    getChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        res.push(arr.slice(i, i + chunkSize));
      }
      return res.filter((e) => !!e);
    },
    async fetchImage(longFileName) {
      const filename = longFileName?.split('/').pop();
      try {
        let url = `media/destruction/${filename}`;

        const response = await this.$http.get(url, {
          responseType: 'blob',
          timeout: 3000,
        });

        const reader = new window.FileReader();
        reader.readAsDataURL(response.data);
        reader.onload = () => {
          this.imgObjects.push({
            image: reader.result,
            filename: filename,
          });
        };
      } catch (e) {
        this.imgObjects.push({
          image: null,
          filename: filename,
        });
      }
    },
    openImagesSlider(img, index) {
      this.imagesSliderVisible = true;
      this.selectedImageIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">
.resolve-dialog-wrapper {
  .destruction-photos-list {
    background-color: var(--white);
    margin-bottom: 12px;
    padding: 0 1em;
    .images {
      display: inline-flex;
      width: 100%;
      overflow-x: auto;

      .square {
        width: 144px;
        min-width: 144px;
        height: 144px;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--grey-100);
        border-radius: 4px;
        overflow: hidden;

        .icon {
          width: 32px;
          height: 32px;
        }
      }

      .preview {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .uploaded {
        display: inline-flex;
        overflow-x: auto;
        .small-image-box {
          &:not(last-child) {
            margin-right: 18px;
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  }
}
</style>
