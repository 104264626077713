<template>
  <popper
    class="wfh_tooltip"
    :root-class="'wfh_tooltip'"
    trigger="hover"
    :disabled="disabled"
    :options="{
      placement: 'top-end',
      modifiers: {
        offset: { offset: null, fn: fn },
        keepTogether: {
          enabled: true,
          fn: keepTogetherFn,
        },
      },
      disabled: disabled,
    }"
    :delay-on-mouse-over="openDelay"
    :append-to-body="true"
    :visible-arrow="true"
  >
    <div class="popper wfh_tooltip">
      <slot name="tooltip-content"> </slot>
    </div>

    <div slot="reference" @mousemove="mousemove" ref="contentComponent">
      <slot name="content"> </slot>
    </div>
  </popper>
</template>

<script>
import Popper from 'vue-popperjs';

export default {
  name: 'PopperTooltip',
  components: { Popper },
  props: {
    openDelay: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      offset: null,
    };
  },
  created() {},

  methods: {
    fn(args) {
      args.offsets.popper.left =
        args.offsets.reference.left + this.offset - args.offsets.popper.width / 2;
      args.attributes['x-out-of-boundaries'] = true;
      return args;
    },
    keepTogetherFn(args) {
      const offsetDifference = args.offsets.reference.left - args.offsets.popper.left;
      args.arrowStyles.left = offsetDifference + this.offset - 6;
      return args;
    },
    mousemove(args) {
      var rect = this.$refs.contentComponent.getBoundingClientRect();
      this.offset = args.clientX - rect.left;
    },
  },
};
</script>

<style scoped></style>
