<template>
  <l-map
    :style="{ height: height ? height + 'px' : `max(${550 + 'px'}, 60vh)` }"
    :zoom="zoom"
    :center="center"
    class="leaflet-map"
    @ready="onMapReady()"
    :options="{ zoomControl: false }"
  >
    <l-tile-layer :url="url"></l-tile-layer>
    <l-marker
      :key="marker.id"
      v-for="marker of markers"
      v-bind="marker"
      :icon="resolveMarker(marker)"
      @click="onMarkerClick(marker)"
    >
      <l-tooltip v-if="showTooltip" :options="tooltipOptions">
        <ul>
          <!--          <li v-if="marker.data.event">EventID : {{ marker.data.event.eventId }}</li>-->
          <!--          <li v-if="marker.data.event">-->
          <!--            EventType : {{ resolveEventType(marker.data.event.eventType) }}-->
          <!--          </li>-->
          <!--          <li v-if="marker.data.event">-->
          <!--            Event date : {{ marker.data.event.eventDate.toLocaleDateString() }}-->
          <!--          </li>-->
          <li>
            Location name : {{ marker.data.location.name
            }}{{ marker.data.location.city ? ', ' + marker.data.location.city : '' }}
          </li>
          <li>Location type : {{ resolveLocationType(marker.data.location.type) }}</li>
          <li v-if="!marker.data.event"># of events : {{ marker.data.location.eventCount }}</li>
          <!--          <li v-if="!marker.data.event && marker.data.redFlagEvents">-->
          <!--            Red flag events :-->
          <!--            {{ marker.data.redFlagEvents }}-->
          <!--          </li>-->
          <!--          <li v-if="marker.data.event">-->
          <!--            Issue type : {{ resolveIssueType(marker.data.event.issueType) }}-->
          <!--          </li>-->
          <!--          <li v-if="marker.data.event">Submitted By : {{ marker.data.event.submittedBy.email }}</li>-->
        </ul>
      </l-tooltip>
    </l-marker>
    <l-control-zoom position="topright"></l-control-zoom>
  </l-map>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import stockData from '../utils/list';
import { LMap, LMarker, LTileLayer, LTooltip, LControlZoom } from 'vue2-leaflet';
import { resolveLocationType, resolveIssueType, resolveEventType } from '../utils/nameResolver';
import { centerType } from '../utils/enums';
export default {
  name: 'SiteMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LControlZoom,
  },
  props: {
    center: {
      type: Array,
      default: () => stockData.initialCoordinates,
    },
    markers: {
      type: Array,
      default: () => [],
    },
    zoom: {
      type: Number,
      default: 5,
    },
    height: {
      type: Number,
      default: 500,
    },
    onMarkerClick: {
      type: Function,
      default: () => {},
    },
    showTooltip: {
      default: true,
    },
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      tooltipOptions: {
        offset: {
          x: 3,
          y: 0,
        },
      },
    };
  },
  methods: {
    resolveLocationType,
    resolveIssueType,
    resolveEventType,
    resolveMarker(marker) {
      if (!marker) {
        return;
      }

      switch (marker.data.location.type) {
        case 'RED_FLAG': {
          return stockData.icons.redFlag;
        }
        case 'DANGER_DISTRIBUTION_CENTER': {
          return stockData.icons.triangleError;
        }
        case 'DANGER_TREATMENT_CENTER': {
          return stockData.icons.redBox;
        }
        case centerType.TREATMENT_CENTER: {
          return stockData.icons.blueBox;
        }
        case centerType.DISTRIBUTION_CENTER: {
          return stockData.icons.distributionCenter;
        }
        case 'EVENT_MARKER': {
          return stockData.icons.eventMarker;
        }
        default:
          return stockData.icons.blueBox;
      }
    },
    onMapReady() {
      document.getElementsByClassName('leaflet-control-attribution')[0].style.display = 'none';
      if (document.getElementsByClassName('leaflet-control-attribution').length > 1) {
        document.getElementsByClassName('leaflet-control-attribution')[1].style.display = 'none';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.leaflet-tooltip {
  ul {
    list-style: none;
    text-align: left;
    padding: 0;

    li {
      font-size: 14px;
      line-height: 21px;
      font-family: Roboto;
      font-weight: 400;
    }
  }
}
</style>
