import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import '@hyjiacan/vue-slideout/lib/slideout.css';
import initApplication from './utils/initApplication';
import locale from 'element-ui/lib/locale/lang/en';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

Vue.config.productionTip = false;
Vue.prototype.$endPoint = process.env.VUE_APP_FRONTEND_ENDPOINT;
Vue.use(ElementUI, { locale });
Vue.use(PerfectScrollbar);

initApplication.initialize().then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#wfh-dashboard-application-wrapper');
});
