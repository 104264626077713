<template>
  <el-button
    :class="{
      'basic-button': true,
      big: big,
    }"
    :type="text ? 'text' : type ? type : ''"
    v-bind="$props"
    v-on="$listeners"
  >
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  name: 'BasicButton',
  props: {
    text: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
.basic-button {
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  &.el-button {
    padding: 8px 12px;
    border-radius: 4px;
  }

  &.big {
    padding: 12px 20px;
  }
}
.basic-button,
.basic-button:focus {
  background: var(--button-blue);
  color: var(--white);
  &:hover {
    background: var(--white);
    color: var(--button-blue);
    border-color: var(--button-blue);
  }
}
.basic-button.el-button--text,
.basic-button.el-button--text:focus {
  background: transparent;
  color: var(--white-primary);
  padding-right: 20px;
  padding-left: 20px;
  &:hover {
    background: var(--white);
    color: var(--button-blue);
    border-color: var(--button-blue);
  }
}

.basic-button.el-button--info,
.basic-button.el-button--info:focus {
  background: var(--grey-100);
  color: var(--black);
  border-color: var(--grey-100);
  padding-right: 20px;
  padding-left: 20px;
  &:hover {
    background: var(--black);
    color: var(--grey-100);
    border-color: var(--black);
  }
}
</style>
