import Vue from 'vue';
const axios = require('axios');
export default {
  async initialize() {
    await this.getToken();
  },
  async loadElementCss() {
    const applicationWrapper = document.getElementById('wfh-dashboard-application-wrapper');
    const elementCSS = document.createElement('link');
    elementCSS.rel = 'stylesheet';
    elementCSS.href = 'https://unpkg.com/element-ui/lib/theme-chalk/index.css';
    return applicationWrapper.appendChild(elementCSS);
  },
  async getToken() {
    if (process.env.VUE_APP_ENV === 'local') {
      let argsArray = [];
      this._defineAxiosAndEndpoint(argsArray);
    } else {
      const args = this._getScriptArgs();
      this._defineAxiosAndEndpoint(args);
    }
  },
  generateLocalToken() {
    return axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/token`, {
      headers: { Authorization: `Bearer ${process.env.VUE_APP_BACKEND_TOKEN}` },
    });
  },
  _getScriptArgs() {
    const tracelabsJs = document.getElementById('tracelabs_js');
    const query = tracelabsJs.src.replace(/^[^?]+\??/, '');
    const lets = query.split('&');
    const args = {};
    let processed = 0;

    for (let i = 0; i < lets.length; i++) {
      const pair = lets[i].split('=');
      args[pair[0]] = decodeURI(pair[1]).replace(/\+/g, ' ');
      processed++;
      if (processed === lets.length) {
        return args;
      }
    }
  },
  _defineAxiosAndEndpoint() {
    const addInterceptors = (axios) => {
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          return Promise.reject(error.response);
        },
      );
    };
    this._addConfig('baseUrl', process.env.VUE_APP_BACKEND_ENDPOINT);
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.baseURL = process.env.VUE_APP_BACKEND_ENDPOINT;
    addInterceptors(axios);
    Vue.prototype.$http = axios;
  },
  _addConfig(key, value) {
    Vue.config[key] = value;
  },
};
