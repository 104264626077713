<template>
  <div>
    <div
      v-if="!overflow"
      :class="{
        'bar-chart': true,
        [color]: true,
      }"
      :style="{ width: getBarWidth + 'px' }"
    >
      <span class="text label-small" v-if="label">
        {{ label }}
      </span>
    </div>
    <div v-if="overflow" class="bar-chart overflow">
      <span class="text label-small" v-if="label">
        {{ label }}
      </span>
      <span class="limiter"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleBarChart',
  props: {
    color: {
      type: String,
      default: 'blue',
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: Number,
      default: 10,
    },
    maxValue: {
      type: Number,
      default: 10,
    },
    maxWidth: {
      type: Number,
      default: 180,
    },
    minWidth: {
      type: Number,
      default: 10,
    },
    overflow: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getBarWidth() {
      return Math.max(this.$props.value * this.getStepSize(), 20);
    },
  },
  methods: {
    getStepSize() {
      return (this.$props.maxWidth * 0.9) / this.$props.maxValue;
    },
  },
};
</script>

<style scoped lang="scss">
.bar-chart {
  border-radius: 4px;
  display: flex;
  height: 24px;
  padding: 4px 8px 4px 0px;
  .text {
    margin-left: auto;
  }
  &.blue {
    background-color: var(--background-blue);
    ::v-deep .text {
      color: var(--white);
    }
  }

  &.overflow {
    position: relative;
    width: calc(100% + 20px);
    margin-left: -20px;
    background-color: var(--background-darker-red);
    ::v-deep .text {
      color: var(--white);
      text-align: center;
      margin-right: auto;
      margin-left: 28px;
    }
    .limiter {
      width: 2px;
      position: absolute;
      top: -2px;
      left: 20px;
      height: 30px;
      background-color: var(--black);
    }
  }
}
</style>
