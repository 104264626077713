<template>
  <base-slider v-bind="$props" v-on="$listeners" title="GO BACK" :size="['75%', '100%']">
    <div class="inbound-flow-slider" v-if="vialFlowDetail">
      <h2 class="label-extra-large label-bold">
        {{ inbound ? 'Inbound' : 'Outbound' }} flow of vials
      </h2>
      <div class="inbound-flow-slider-content">
        <div class="content-header">
          <h3 class="label-large label-bold">
            Location:
            {{
              `${vialFlowDetail.locationName}${
                vialFlowDetail.city ? ', ' + vialFlowDetail.city : ''
              }`
            }}
          </h3>
          <div class="filter-row">
            <h5 class="label-medium">
              Location type:
              <span class="label-bold">{{ resolveLocationType(vialFlowDetail.locationType) }}</span>
            </h5>
          </div>
        </div>

        <div class="inbound-flow-table" v-if="tableData">
          <vial-flow-table
            :handleSortChange="handleSortChange"
            :table-data="tableData.tableData"
            :inbound="inbound"
            :current-location-name="tableData.locationName"
            :default-sort="sort"
          />
        </div>
      </div>
    </div>
  </base-slider>
</template>

<script>
import { resolveLocationType } from '../../utils/nameResolver';
import VialFlowTable from '../VialFlowTable';
import BaseSlider from './BaseSlider';
import { formatAmountNumber } from '../../utils/numberFormatter';
import { productFlowPageDescription } from '../../utils/enums';

export default {
  name: 'InboundVialFlow',
  components: { VialFlowTable, BaseSlider },
  props: {
    vialFlowDetail: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    inbound: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableData: null,
      sort: {
        prop: this.inbound ? 'expectedInBound' : 'expectedOutBound',
        order: 'descending',
      },
    };
  },
  computed: {
    selectedFilters() {
      return this.$store.getters.getAllLocationFilters;
    },
  },
  watch: {
    inbound(n) {
      this.sort = {
        prop: n ? 'expectedInBound' : 'expectedOutBound',
        order: 'descending',
      };
    },
    vialFlowDetail(newValue) {
      if (newValue) {
        this.fetchEvents();
      }
    },
  },
  methods: {
    handleSortChange(val) {
      this.sort = this.parseSort(val);
      this.fetchEvents();
    },
    parseSort(val) {
      return val.order ? `${val.prop}:${val.order}` : null;
    },
    async fetchEvents() {
      const loading = this.$loading({ target: '.inbound-flow-table' });
      try {
        const url = `/events/productFlow/${this.inbound ? 'inbound' : 'outbound'}/${
          this.vialFlowDetail.source
        }`;
        const { data } = await this.$http.get(url, {
          params: {
            ...this.selectedFilters,
            sortBy: this.sort.order ? this.parseSort(this.sort) : this.sort,
          },
        });
        let tableData = {
          locationName: this.vialFlowDetail.distributionCenter,
          city: this.vialFlowDetail.city,
          locationType: 'DISTRIBUTION_CENTER',
        };
        tableData.tableData = data.map((e) => {
          const actual = Number(this.inbound ? e.actualInbound : e.actualOutbound);
          const expected = Number(this.inbound ? e.expectedInBound : e.expectedOutBound);
          return {
            [this.inbound ? 'origin' : 'destination']: this.inbound
              ? e.sourceName
              : this.vialFlowDetail.distributionCenter,
            city: e.city,
            type: e.type,
            locationName: e.sourceName,
            vials: [
              {
                value: expected,
                label: formatAmountNumber(expected?.toFixed(1)),
                labelOut: formatAmountNumber(expected?.toFixed(1)),
                color: 'blue',
                valueType: productFlowPageDescription.PRODUCT_AMOUNT_EXPECTED,
              },
              {
                value: actual,
                label: formatAmountNumber(actual?.toFixed(1)),
                labelOut: formatAmountNumber(actual?.toFixed(1)),
                color: 'green',
                valueType: productFlowPageDescription.PRODUCT_AMOUNT_RECEIVED,
              },
            ],
          };
        });
        this.tableData = tableData;
      } catch (e) {
        this.$notify({
          type: 'error',
          message: "Couldn't get data for events tab. Please try later.",
        });
      } finally {
        loading.close();
      }
    },
    resolveLocationType,
  },
};
</script>

<style scoped lang="scss">
.inbound-flow-slider {
  padding-bottom: 1em;
  .label-bold {
    color: var(--black);
  }
  .inbound-flow-slider-content {
    align-self: start;
    text-align: start;

    .content-header,
    .inbound-flow-table {
      padding-left: 4rem;
      padding-right: 1rem;
    }

    .content-header {
      margin-bottom: 1em;
    }

    .filter-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      * {
        margin-top: auto;
        margin-bottom: auto;
      }
      h5 {
      }

      .red-flag-filter {
        text-align: start;

        h5 {
          padding-left: 0;
          margin-bottom: 8px;
        }
      }
    }
  }

  h2.label-extra-large {
    padding-left: 4rem;
    align-self: start;
    text-align: start;
    margin-bottom: 24px;
  }
}
</style>
