<template>
  <div>
    <destruction-event-images-modal
      :is-visible="eventImageVisible"
      :images="images"
      :event-id="destructionEventEventId"
      :on-close="() => (eventImageVisible = false)"
    />
    <destruction-event-products-modal
      :is-visible="eventProductsVisible"
      :products="productList"
      :event-id="destructionEventEventId"
      :on-close="() => (eventProductsVisible = false)"
    />
    <el-table
      :data="tableData"
      header-row-class-name="label-medium header-row"
      :default-sort="{ prop: 'eventTime', order: 'descending' }"
      cell-class-name="overflow-visible"
      @cell-click="onCellClick"
      @sort-change="handleSortChange"
    >
      <el-table-column
        prop="eventId"
        label="Event ID"
        :min-width="170"
        align="left"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="eventTime"
        sortable="custom"
        label="Event date"
        :min-width="180"
        align="left"
        class-name="break-word"
        :formatter="(ev) => dateToUTCFormatter(ev.eventTime) + ' UTC'"
      >
      </el-table-column>
      <el-table-column
        prop="submittedBy"
        label="Submitted by"
        :min-width="190"
        align="left"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="reason"
        label="Reason"
        :width="130"
        align="left"
        :formatter="(scope) => resolveVialDestructionReasonName(scope.destructionReason)"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        class-name="label-link clickable"
        prop="vialsCount"
        label="# of vials destroyed"
        align="left"
        min-width="190"
        sortable="custom"
        :formatter="(row) => row.allVialsDestroyed"
      >
      </el-table-column>
      <el-table-column
        min-width="120"
        class-name="label-link clickable"
        prop="proof"
        label="Proof"
        align="left"
      >
        <template> See photos </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { resolveVialDestructionReasonName } from '../utils/nameResolver';
import DestructionEventImagesModal from './modals/DestructionEventImagesModal';
import DestructionEventProductsModal from './modals/DestroyedProductsVialsModal';
import { dateToUTCFormatter } from '../utils/numberFormatter';
export default {
  name: 'ProductEventLogTable',
  components: { DestructionEventProductsModal, DestructionEventImagesModal },
  props: {
    tableData: {
      type: Array,
      default: null,
    },
    handleSortChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      images: null,
      destructionEventEventId: null,
      eventImageVisible: false,
      eventProductsVisible: false,
      productList: null,
    };
  },
  methods: {
    dateToUTCFormatter,
    resolveVialDestructionReasonName,
    openDestructionProductList(scope) {
      this.productList = scope.vialsDestroyed;
      this.destructionEventEventId = scope.eventId?.toString();
      this.eventProductsVisible = true;
    },
    openProofsModal(scope) {
      this.images = scope.destructionDetails.images;
      this.destructionEventEventId = scope.eventId?.toString();
      this.eventImageVisible = true;
    },

    onCellClick(...args) {
      if (args[1].property === 'vialsCount') {
        this.openDestructionProductList(args[0]);
      }
      if (args[1].property === 'proof') {
        this.openProofsModal(args[0]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep thead {
  .label-link {
    color: var(--black-disabled);
    cursor: default;
  }
}

::v-deep {
  .header-row {
    th {
      font-size: 14px;
      color: var(--black-tertiary);
      font-weight: 400;
    }
  }
  .overflow-visible {
    &:not(.label-link) {
      .cell {
        color: var(--black-primary);
      }
    }
  }
  .break-word {
    .cell {
      word-break: break-word;
    }
  }
}
</style>
