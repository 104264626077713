<template>
  <base-slider
    @close="onBackButtonCalled"
    v-bind="$props"
    v-on="$listeners"
    title="GO BACK"
    :size="['75%', '100%']"
  >
    <resolve-open-issues-modal
      :is-visible="visibleResolvingDialog"
      :issue-id="selectedRowForResolution ? selectedRowForResolution.issueId.toString() : null"
      :on-close="closeModal"
      :on-submit="resolveIssue"
    />
    <div class="event-detail-wrapper" v-if="eventDetail">
      <h2 class="label-large">Event {{ eventDetail.eventId }}</h2>
      <div class="event-details-details">
        <el-row>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
            <ul>
              <li>
                Event type:
                <span class="label-bold">{{ resolveEventType(eventDetail.type) }}</span>
              </li>
              <li>
                Event date:
                <span class="label-bold">{{
                  dateToUTCFormatter(eventDetail.eventTime) + ' UTC'
                }}</span>
              </li>
              <li>
                Location:
                <span class="label-bold"
                  >{{ eventDetail.location.name
                  }}{{
                    this.eventDetail.location.city ? ', ' + this.eventDetail.location.city : ''
                  }}</span
                >
              </li>
            </ul>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8" :offset="1">
            <ul>
              <li>
                Destination: <span class="label-bold">{{ eventDetail.destination || '-' }}</span>
              </li>
              <li>
                Submitted By: <span class="label-bold">{{ eventDetail.submittedBy }}</span>
              </li>
              <!--              <li>-->
              <!--                Red Flag: <span class="label-bold">{{ eventDetail.isRedFlag }}</span>-->
              <!--              </li>-->
            </ul>
          </el-col>
        </el-row>
      </div>
      <div class="products-wrapper">
        <el-row>
          <el-col :xs="24" :sm="12" :md="13" :lg="13" :xl="13">
            <div class="product-list grey-box-background">
              <products-list-table :table-data="tableData" :current-event-type="eventDetail.type" />
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="10" :lg="10" :xl="10" :offset="1">
            <div class="event-coordination-map grey-box-background" v-if="eventMapMarkers">
              <h4 class="label-big">Event Coordinates</h4>
              <map-legend :legend-item="getMapLegend()" />
              <site-map
                :height="220"
                :center="[eventDetail.coordinates.latitude, eventDetail.coordinates.longitude]"
                :markers="eventMapMarkers"
                :show-tooltip="false"
              />
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="treatment-details grey-box-background" v-if="isTreatmentEvent">
        <event-treatment-detail :event-treatment-details="treatmentDetails" />
      </div>
      <div class="destruction-details grey-box-background" v-if="isDestructionEvent">
        <event-destruction-photos :event-destruction-details="destructionDetails" />
      </div>
      <div class="open-issues grey-box-background" v-if="eventDetail.issues">
        <open-issues-table :table-data="eventDetail.issues" :on-resolve-click="openResolveModal" />
      </div>
      <div class="resolved-issues grey-box-background" v-if="eventDetail.resolvedIssues">
        <resolved-issues-table :table-data="eventDetail.resolvedIssues" />
      </div>
    </div>
  </base-slider>
</template>

<script>
import BaseSlider from './BaseSlider';
import { resolveEventType, resolveIconURIBasedOnMarkers } from '../../utils/nameResolver';
import ProductsListTable from '../ProductsListTable';
import SiteMap from '../LeafletSiteMap';
import MapLegend from '../mapLegends/MapLegend';
import OpenIssuesTable from '../OpenIssuesTable';
import { centerType, mapMarkers, eventType, sliderTypes } from '../../utils/enums';
import ResolvedIssuesTable from '../ResolvedIssuesTable';
import EventTreatmentDetail from '../EventTreatmentDetail';
import EventDestructionPhotos from '../EventDestructionPhotos';
import ResolveOpenIssuesModal from '../modals/ResolveOpenIssuesModal';
import { openSideBar } from '../../utils/eventBus';
import { dateToUTCFormatter } from '../../utils/numberFormatter';
export default {
  name: 'EventDetailSlider',
  components: {
    ResolveOpenIssuesModal,
    EventDestructionPhotos,
    EventTreatmentDetail,
    ResolvedIssuesTable,
    OpenIssuesTable,
    MapLegend,
    SiteMap,
    ProductsListTable,
    BaseSlider,
  },
  props: {
    eventDetail: {
      type: Object,
      default: () => {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
    ogList: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dateToUTCFormatter,
      mapHeight: 250,
      visibleResolvingDialog: false,
      selectedRowForResolution: null,
      products: [],
      treatmentDetails: null,
      destructionDetails: null,
    };
  },
  computed: {
    selectedFilters() {
      return this.$store.getters.getAllLocationFilters;
    },
    isDestructionEvent() {
      return this.eventDetail?.type === eventType.DESTRUCTION;
    },
    isTreatmentEvent() {
      return this.eventDetail?.type === eventType.DISPENSING;
    },
    tableData() {
      return {
        vialCount: this.products.reduce((p, c) => (p += c.numOfVials ?? 0), 0) ?? 0,
        data: this.products,
      };
    },
    eventMapMarkers() {
      return [
        {
          latLng: [
            this.eventDetail.location.coordinates.latitude,
            this.eventDetail.location.coordinates.longitude,
          ],
          data: {
            location: this.eventDetail.location,
          },
        },
        {
          latLng: [this.eventDetail.coordinates.latitude, this.eventDetail.coordinates.longitude],
          data: {
            location: {
              type: 'EVENT_MARKER',
            },
          },
        },
      ].filter((e) => e.latLng.every((l) => !!l));
      // return [
      //   {
      //     id: this.eventDetail.location,
      //     latLng: [this.eventDetail.coordinates.latitude, this.eventDetail.coordinates.longitude],
      //     icon: this.eventDetail.location.type,
      //   },
      // ];
    },
  },
  watch: {
    eventDetail() {
      this.destructionDetails = null;
      this.treatmentDetails = null;
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    resolveEventType,
    resolveIssue() {
      // TODO add issue resolving processing
      this.visibleResolvingDialog = false;
    },
    openResolveModal(row) {
      this.selectedRowForResolution = row;
      this.visibleResolvingDialog = true;
    },
    closeModal() {
      this.visibleResolvingDialog = false;
    },
    onBackButtonCalled({ buttonClicked } = {}) {
      if (this.ogList?.location && buttonClicked) {
        openSideBar(sliderTypes.EVENT_LIST, this.ogList.location);
      }
    },
    async fetchEventProducts() {
      this.$nextTick(async () => {
        const loading = this.$loading({ target: '.product-list' });

        try {
          const { data } = await this.$http.get(`events/${this.eventDetail.id}/products`, {
            params: {
              ...this.selectedFilters,
            },
          });
          this.products = data.products;
        } catch (e) {
          this.$notify({
            type: 'error',
            message: "Couldn't fetch event products.",
          });
        } finally {
          loading.close();
        }
      });
    },
    fetchData() {
      if (!this.eventDetail) {
        return;
      }
      this.fetchEventProducts();

      if (this.eventDetail?.type === eventType.DESTRUCTION) {
        this.fetchDestructionDetails();
      }

      if (this.eventDetail?.type === eventType.DISPENSING) {
        this.fetchTreatmentDetails();
      }
    },
    async fetchDestructionDetails() {
      this.destructionDetails = {}; // show destruction details panel
      this.$nextTick(async () => {
        const loading = this.$loading({ target: '.destruction-details' });
        try {
          const { data } = await this.$http.get(
            `events/${this.eventDetail.id}/details?type=destruction`,
          );
          this.destructionDetails = data;
        } catch (e) {
          this.$notify({ type: 'error', message: "Couldn't fetch destruction details" });
        } finally {
          loading.close();
        }
      });
    },
    fetchTreatmentDetails() {
      this.treatmentDetails = {}; // show treatment details panel
      this.$nextTick(async () => {
        const loading = this.$loading({ target: '.treatment-details' });
        try {
          const { data } = await this.$http.get(
            `events/${this.eventDetail.id}/details?type=treatment`,
          );
          this.treatmentDetails = data;
        } catch (e) {
          this.$notify({ type: 'error', message: "Couldn't fetch treatment details" });
        } finally {
          loading.close();
        }
      });
    },
    getMapLegend() {
      const marker = this.getMarker();
      return [
        {
          label: `${this.eventDetail.location?.name}${
            this.eventDetail.location?.city ? ', ' + this.eventDetail.location?.city : ''
          }`,
          icon: resolveIconURIBasedOnMarkers(marker),
        },
        {
          label: 'Event coordinates',
          icon: resolveIconURIBasedOnMarkers(mapMarkers.EVENT_MARKER),
        },
      ];
    },
    getMarker() {
      let marker = '';

      if (this.eventDetail.location.type === centerType.TREATMENT_CENTER) {
        marker = mapMarkers.TREATMENT_CENTER;
      } else {
        marker = mapMarkers.DISTRIBUTION_CENTER;
      }
      return marker;
    },
  },
};
</script>

<style scoped lang="scss">
.event-detail-wrapper {
  padding: 0 4rem 1em 4em;
  .products-wrapper {
    .event-coordination-map {
      padding: 15px 40px;
      * {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        align-self: start;
        text-align: start;
      }
    }

    .product-list {
      padding: 8px;
    }

    .map-legend-wrapper {
      margin-bottom: 24px;
    }
  }

  h2 {
    align-self: start;
    text-align: start;
    color: var(--black-primary);
  }
  .event-details-details {
    margin-bottom: 12px;
    ul {
      list-style: none;
      text-align: start;
      align-content: start;
      li {
        color: var(--black-secondary);
        margin: 1em 0;
        .label-bold {
          color: var(--black);
        }
      }
    }
  }

  .open-issues,
  .resolved-issues,
  .destruction-details,
  .treatment-details {
    margin-top: 16px;
    padding: 8px;
  }
}
</style>
