<template>
  <div class="inventory-page-wrapper">
    <div class="main-white-box">
      <h1 class="page-headline">Inventory</h1>
      <div class="inventory-table-list-wrapper no-lines-table">
        <el-table
          :data="tableData"
          header-row-class-name="label-medium text-center header-row"
          :default-sort="{ prop: 'totalVials', order: 'descending' }"
          :row-class-name="
            (r, i) => (isLastRow(r.rowIndex) ? 'adjusted-table-height' : 'base-table-height')
          "
          :cell-class-name="getClassName"
          @sort-change="handleSortChange"
        >
          <el-table-column
            prop="utilizationPercent"
            label="Storage capacity"
            align="left"
            :class-name="'column-with-bars'"
            sortable="custom"
          >
            <template slot-scope="scope">
              <grid-axis
                :inverted="true"
                :max-value="getMaxValue(scope, true)"
                :step="getStepSize(scope, 0.9, true)"
                :max-width="scope.column.realWidth"
                :min-value="getMinValue(scope, true)"
                :width-reduction="0.9"
                :show-axis-numbers="isLastRow(scope.$index)"
                :grid-count="2"
                :formatter="(v) => `${v}%`"
              />
              <double-tacked-chart
                v-if="!scope.row.storage.overflow"
                :bars="scope.row.storage"
                :inverted="true"
                :max-width="scope.column.realWidth"
                :step="getStepSize(scope, 0.9, true)"
                @click="(barIndex) => onEventBarClick(scope, barIndex)"
              >
                <div slot="tooltip">
                  <div slot="content">
                    <ul class="basic-list" v-if="!getStorageTooltipInfo(scope).overflow || true">
                      <li>Location name: {{ getStorageTooltipInfo(scope).locationName }}</li>
                      <li>Capacity (vials): {{ getStorageTooltipInfo(scope).capacity }}</li>
                      <li>Current inventory (vials): {{ getStorageTooltipInfo(scope).current }}</li>
                    </ul>
                    <ul class="basic-list" v-if="getStorageTooltipInfo(scope).overflow && false">
                      <li>IDLE STOCK WARNING</li>
                      <li>Click for details</li>
                    </ul>
                  </div>
                </div>
              </double-tacked-chart>
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="locationName"
            label="Location"
            :width="180"
            :class-name="'column-word'"
            align="left"
          >
            <template slot-scope="scope">
              <div class="column-name">
                {{ `${scope.row.locationName}${scope.row.city ? ', ' + scope.row.city : ''}` }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="totalVials"
            label="# of vials in stock"
            align="left"
            :class-name="'column-with-bars'"
            sortable="custom"
          >
            <template slot-scope="scope">
              <grid-axis
                :inverted="false"
                :max-value="getMaxValue(scope)"
                :step="getStepSize(scope, 0.8, false)"
                :max-width="scope.column.realWidth"
                :min-value="getMinValue(scope)"
                :width-reduction="0.8"
                :show-axis-numbers="isLastRow(scope.$index)"
                :formatter="formatAmountNumberWithoutDecimals"
              />
              <double-tacked-chart
                :max-value="getMaxValue(scope)"
                :min-value="getMinValue(scope)"
                :bars="scope.row.vials"
                :max-width="scope.column.realWidth"
                :step="getStepSize(scope, 0.8)"
              >
                <div slot="tooltip">
                  <div slot="content">
                    <ul class="basic-list">
                      <li>Location name: {{ getStockTooltipInfo(scope).locationName }}</li>
                      <li>
                        >3 months:
                        {{ getStockTooltipInfo(scope).threeMonths }}
                      </li>
                      <li>1-3 months: {{ getStockTooltipInfo(scope).oneToThree }}</li>
                      <li>0-1 months: {{ getStockTooltipInfo(scope).zeroToOne }}</li>
                      <li>Expired: {{ getStockTooltipInfo(scope).expired }}</li>
                      <li>Total: {{ getStockTooltipInfo(scope).total }}</li>
                    </ul>
                  </div>
                </div>
              </double-tacked-chart>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import DoubleTackedChart from '../../components/charts/DoubleTackedChart';
import { productFlowPageDescription, inventoryExpiryStages, sliderTypes } from '@/utils/enums';
import GridAxis from '../../components/basic/GridAxis';
import { inventoryRedFlagExample } from '@/utils/list';
import eventBus, { openSideBar } from '../../utils/eventBus';
import {
  formatAmountNumber,
  formatAmountNumberWithoutDecimals,
  formatRegularAmounts,
} from '@/utils/numberFormatter';
export default {
  name: 'HFIInventory',
  components: { GridAxis, DoubleTackedChart },
  data() {
    return {
      tableData: [],
      sort: 'totalVials:descending',
    };
  },
  computed: {
    selectedFilters() {
      return this.$store.getters.getAllLocationFilters;
    },
  },
  watch: {
    selectedFilters: {
      deep: true,
      handler() {
        this.fetchEvents();
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.fetchEvents();
    });
  },
  beforeDestroy() {
    eventBus.$off('filtersChange');
  },
  methods: {
    async fetchEvents() {
      const loading = this.$loading();
      try {
        const { data } = await this.$http.get('events/inventory', {
          params: { ...this.selectedFilters, sortBy: this.sort },
        });
        this.tableData = data
          .filter((e) => Number(e.storageCapacity) > 0)
          .map((e) => {
            const storageCapacity = parseInt(e.storageCapacity ?? '0');
            const totalVials = parseFloat(e.totalVials ?? '0');
            const totalUnfilteredVials = parseFloat(e.totalVialsUnfiltered ?? '0');
            const vialPercentageCount = Math.round(
              (totalUnfilteredVials < 0 ? 0 : totalUnfilteredVials / Number(storageCapacity ?? 1)) *
                100,
            );
            let overflowStorage;
            if (vialPercentageCount > 100) {
              overflowStorage = {
                value: 100,
                capacity: storageCapacity,
                labelOut: null,
                overflow: true,
                label: vialPercentageCount + '%',
                overflowPercent: vialPercentageCount - 100,
                event: inventoryRedFlagExample,
                vialCount: totalUnfilteredVials,
              };
            }
            return {
              storage: [
                overflowStorage ?? {
                  value: totalUnfilteredVials ? 100 : 0,
                  stackLabelOut: null,
                  capacity: storageCapacity ?? 0,
                  stack: [
                    {
                      value: totalUnfilteredVials ? 100 - vialPercentageCount : 100,
                      label: '',
                      labelOut: null,
                      color: 'grey',
                      ogValue: Number(storageCapacity ?? 0) - totalUnfilteredVials,
                    },
                    {
                      value: totalUnfilteredVials && storageCapacity ? vialPercentageCount : 0,
                      used: true,
                      label:
                        totalUnfilteredVials && storageCapacity ? vialPercentageCount + '%' : '',
                      labelOut: 0 < vialPercentageCount ? `${vialPercentageCount}%` : null,
                      forceLabelOut: true,
                      color: 'grey-30',
                      ogValue: totalUnfilteredVials ? totalUnfilteredVials : 0,
                    },
                  ],
                },
              ],
              locationName: e.locationName,
              city: e.city,
              vials: [
                {
                  value: totalVials,
                  labelOut: formatAmountNumber(totalVials),
                  color: 'green',
                  valueType: productFlowPageDescription.PRODUCT_AMOUNT_EXPECTED,
                  stackLabelOut: formatAmountNumber(totalVials),
                  stack: [
                    {
                      value: Number(e.sumMoreThanThreeMonths ?? '0'),
                      label: formatAmountNumber(Number(e.sumMoreThanThreeMonths ?? '0')),
                      ogValue: Number(e.sumMoreThanThreeMonths ?? '0'),
                      labelOut: null,
                      color: 'grey',
                      type: inventoryExpiryStages.THREE_MONTHS_EXPIRY,
                    },
                    {
                      value: Number(e.sumBetween1And3Months ?? '0'),
                      label: formatAmountNumber(Number(e.sumBetween1And3Months ?? '0')),
                      ogValue: Number(e.sumBetween1And3Months ?? '0'),
                      labelOut: null,
                      color: 'pale-teal',
                      type: inventoryExpiryStages.ONE_TO_THREE_MONTHS_EXPIRY,
                    },
                    {
                      value: Number(e.sumLessThan1Month ?? '0'),
                      label: formatAmountNumber(Number(e.sumLessThan1Month ?? '0')),
                      ogValue: Number(e.sumLessThan1Month ?? '0'),
                      labelOut: null,
                      color: 'light-teal',
                      type: inventoryExpiryStages.ZERO_TO_ONE_MONTHS_EXPIRY,
                    },
                    {
                      value: Number(e.sumExpired ?? '0'),
                      label: formatAmountNumber(Number(e.sumExpired ?? '0')),
                      ogValue: Number(e.sumExpired ?? '0'),
                      labelOut: null,
                      color: 'pale-red',
                      type: inventoryExpiryStages.EXPIRED,
                    },
                  ],
                },
              ],
            };
          });
      } catch (e) {
        this.$notify({
          type: 'error',
          message: "Couldn't get data for events tab. Please try later.",
        });
      } finally {
        loading.close();
      }
    },
    handleSortChange(val) {
      this.sort = val.order ? `${val.prop}:${val.order}` : null;
      this.fetchEvents();
    },
    formatAmountNumber,
    formatAmountNumberWithoutDecimals,
    getStepSize(scope, reduction = 0.9, storage = false) {
      return (scope.column.realWidth * reduction) / this.getMaxValue(scope, storage);
    },

    getMaxValue(scope, storage = false) {
      return storage
        ? 100
        : Math.max(...this.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []));
    },
    getMinValue(scope, storage = false) {
      return storage
        ? 0
        : Math.min(...this.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []));
    },

    isLastRow(rowindex) {
      return rowindex === this.tableData.length - 1;
    },

    getStorageTooltipInfo(scope) {
      const isOverflow = scope.row.storage[0].overflow;
      return {
        locationName: scope.row.locationName + `${scope.row.city ? ', ' + scope.row.city : ''}`,
        capacity: formatRegularAmounts(scope.row.storage[0].capacity),
        current: formatRegularAmounts(
          isOverflow
            ? scope.row.storage[0].vialCount
            : scope.row.storage[0].stack
            ? scope.row.storage[0].stack.find((e) => e.used).ogValue
            : 0,
        ),
        overflow: isOverflow,
        overflowPercent: scope.row.storage[0].overflowPercent,
      };
    },
    getStockTooltipInfo(scope) {
      return {
        locationName: scope.row.locationName + `${scope.row.city ? ', ' + scope.row.city : ''}`,
        threeMonths: formatRegularAmounts(
          scope.row.vials[0].stack
            .find((e) => e.type === inventoryExpiryStages.THREE_MONTHS_EXPIRY)
            ?.ogValue?.toFixed(1),
        ),
        oneToThree: formatRegularAmounts(
          scope.row.vials[0].stack
            .find((e) => e.type === inventoryExpiryStages.ONE_TO_THREE_MONTHS_EXPIRY)
            ?.ogValue?.toFixed(1),
        ),
        zeroToOne: formatRegularAmounts(
          scope.row.vials[0].stack
            .find((e) => e.type === inventoryExpiryStages.ZERO_TO_ONE_MONTHS_EXPIRY)
            ?.ogValue?.toFixed(1),
        ),
        expired: formatRegularAmounts(
          scope.row.vials[0].stack
            .find((e) => e.type === inventoryExpiryStages.EXPIRED)
            ?.ogValue?.toFixed(1),
        ),
        total: formatRegularAmounts(scope.row.vials[0].value?.toFixed(1)),
      };
    },

    onEventBarClick(scope, barIndex) {
      if (scope.row.storage[barIndex].overflow && 1 != 1) {
        openSideBar(sliderTypes.EVENT_DETAILS, scope.row.storage[barIndex].event);
      }
    },

    getHeaderStyle(cell) {
      if (cell.columnIndex === 0) {
        return {
          'padding-left': `${cell.column.realWidth - 100 * this.getStepSize(cell, 0.9, true)}px`,
        };
      }

      return {};
    },

    getClassName(cell) {
      let classNames = 'overflow-visible';
      if (cell.columnIndex === 1) {
        classNames += ' location-name-column';
      }

      return classNames;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../scss/variables';

.inventory-page-wrapper {
  .main-white-box {
    h1 {
      text-align: start;
      margin-bottom: 34px;
    }
    .inventory-table-list-wrapper {
      margin-top: 1.5em;
      ::v-deep .overflow-visible {
        .cell {
          overflow: visible;
        }
      }
      ::v-deep .location-name-column {
        .cell {
          color: var(--black-primary);
          text-align: center;
          height: 42px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          display: flex;
          .column-name {
            margin: auto;
          }
        }
      }
      ::v-deep .label-medium {
        th {
          font-weight: 400;
        }
      }

      ::v-deep .text-center {
        th {
          text-align: center;
        }
      }
      ::v-deep .header-row {
        th {
          font-size: 14px;
          color: var(--black-tertiary);
        }
      }

      ::v-deep .adjusted-table-height {
        height: 96px;
        .el-table__cell {
          padding-top: 0;
        }

        td {
          padding-top: 5px;
          padding-bottom: 5px;
          .grid-axis-lines-wrapper {
            height: 50px;
          }
          .cell {
            margin-top: -40px;
          }
        }
      }

      ::v-deep .base-table-height {
        height: 22px;
        td {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }

      ::v-deep .el-table {
        .column-with-bars {
          .cell {
            padding-left: 0;
            padding-right: 0;
          }
        }
        .column-word {
          .cell {
            word-break: break-word;
          }
        }
      }
    }
  }
}
</style>
