<template>
  <div>
    <verification-slider
      :visible="sliderType === sliderTypes.VERIFICATION"
      :noHeader="true"
      @closed="sliderType = null"
      @close="sliderType = null"
    />
    <event-detail-slider
      :visible="sliderType === sliderTypes.EVENT_DETAILS"
      :event-detail="sliderData.eventDetails"
      @closed="
        sliderType = null;
        sliderData.eventDetails = null;
      "
      v-bind="extraProps.eventDetails"
    />
    <event-list-slider
      :visible="sliderType === sliderTypes.EVENT_LIST"
      :location-details="sliderData.eventList"
      @closed="
        sliderType = null;
        sliderData.eventList = null;
      "
      v-bind="extraProps.eventList"
    />
    <inbound-vial-flow
      :visible="sliderType === sliderTypes.INBOUND_FLOW"
      :vial-flow-detail="sliderData.InboundFlowData"
      @closed="
        sliderType = null;
        sliderData.InboundFlowData = null;
      "
      v-bind="extraProps.InboundFlowData"
    />
    <utilization-product-breakdown
      :visible="sliderType === sliderTypes.UTILIZATION_PRODUCT_BREAKDOWN"
      :utilization-product-details="sliderData.utilizationProductBreakdown"
      @closed="
        sliderType = null;
        sliderData.utilizationProductBreakdown = null;
      "
      v-bind="extraProps.utilizationProductBreakdown"
    />
    <product-destruction-event-log
      :visible="sliderType === sliderTypes.PRODUCT_DESTRUCTION_EVENT_LOG"
      :product-destruction-event-log-details="sliderData.productDestructionEventLog"
      @closed="
        sliderType = null;
        sliderData.productDestructionEventLog = null;
      "
      v-bind="extraProps.productDestructionEventLog"
    />
  </div>
</template>

<script>
import { sliderTypes } from '../../utils/enums';
import VerificationSlider from './VerificationSlider';
import eventbus from '../../utils/eventBus';
import EventDetailSlider from './EventDetailSlider';
import EventListSlider from './EventListSlider';
import InboundVialFlow from './InboundVialFlow';
import UtilizationProductBreakdown from './UtilizationProductBreakdown';
import ProductDestructionEventLog from './ProductDestructionEventLog';

export default {
  name: 'IndexSlider',
  components: {
    ProductDestructionEventLog,
    UtilizationProductBreakdown,
    InboundVialFlow,
    EventListSlider,
    EventDetailSlider,
    VerificationSlider,
  },
  props: {
    slider: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      sliderTypes,
      sliderType: this.$props.slider,
      sliderData: {},
      extraProps: {},
      sliding: false,
      callback: null,
    };
  },
  mounted() {
    eventbus.$on('openSidebar', ({ key, data, extraProps }) => {
      this.sliding = true;
      this.sliderType = null;
      setTimeout(() => {
        this.sliderType = key;
        switch (key) {
          case sliderTypes.VERIFICATION: {
            this.sliderData.verification = data;
            if (extraProps) this.extraProps.verification = extraProps;
            break;
          }
          case sliderTypes.EVENT_LIST: {
            this.sliderData.eventList = data;
            if (extraProps) this.extraProps.eventList = extraProps;
            break;
          }
          case sliderTypes.EVENT_DETAILS: {
            this.sliderData.eventDetails = data;
            if (extraProps) this.extraProps.eventDetails = extraProps;
            break;
          }
          case sliderTypes.INBOUND_FLOW: {
            this.sliderData.InboundFlowData = data;
            if (extraProps) this.extraProps.InboundFlowData = extraProps;
            break;
          }
          case sliderTypes.UTILIZATION_PRODUCT_BREAKDOWN: {
            this.sliderData.utilizationProductBreakdown = data;
            if (extraProps) this.extraProps.utilizationProductBreakdown = extraProps;
            break;
          }
          case sliderTypes.PRODUCT_DESTRUCTION_EVENT_LOG: {
            this.sliderData.productDestructionEventLog = data;
            if (extraProps) this.extraProps.productDestructionEventLog = extraProps;
            break;
          }

          default: {
            break;
          }
        }
      }, 50);
    });
  },
};
</script>

<style scoped>
::v-deep .slider-content {
  height: calc(100% - 64px);
}
</style>
