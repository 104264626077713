import axios from 'axios';
import dayjs from 'dayjs';
import { centerType } from '../../utils/enums';

const defaultValue = { label: 'All', value: null };

const mapSimpleFilter = (arr) => {
  const array = arr.map((e) => {
    return { label: e, value: e };
  });

  return [defaultValue, ...array];
};

export default {
  state: {
    filtersValues: {
      country: [],
      locationType: ['Country distribution center', 'Treatment center'],
      location: [],
      manufacturer: [],
    },
    selectedFilters: {
      country: null,
      locationType: null,
      location: null,
      manufacturer: null,
      dateRange: ['2022-01-01', dayjs().format('YYYY-MM-DD')],
      includeEmptyLocations: true,
    },
    utilizationView: null,
    hfiUtilizationView: null,
  },
  mutations: {
    activateFilter(state, payload) {
      state.selectedFilters[payload.filter] = payload.value;
      if (['locationType', 'country'].includes(payload.filter)) {
        state.selectedFilters.location = null;
      }
    },
    setUtilizationView(state, payload) {
      state.utilizationView = payload;
    },
    setHFIUtilizationView(state, payload) {
      state.hfiUtilizationView = payload;
    },
    resetAllFilters(state) {
      state.selectedFilters = {
        country: null,
        locationType: null,
        location: null,
        manufacturer: null,
        dateRange: ['2022-01-01', dayjs().format('YYYY-MM-DD')],
        includeEmptyLocations: true,
      };
    },
  },
  actions: {
    async fetchAvailableCountries(ctx) {
      const { data } = await axios.get('/filters/countries');
      ctx.state.filtersValues.country = data.countries;
    },
    async fetchAvailableManufacturers(ctx) {
      const { data } = await axios.get('/filters/manufacturers');
      ctx.state.filtersValues.manufacturer = data.manufacturers;
    },
    async fetchAvailableLocations(ctx) {
      const { data } = await axios.get('/filters/locations', {
        params: {
          includeEmptyLocations: ctx.state.selectedFilters.includeEmptyLocations,
          manufacturer: ctx.state.selectedFilters.manufacturer,
        },
      });
      ctx.state.filtersValues.location = data.locations;
    },
  },
  getters: {
    getCountryFilters(state) {
      return mapSimpleFilter(state.filtersValues.country);
    },
    getLocationTypeFilters(state) {
      return [
        defaultValue,
        ...state.filtersValues.locationType.map((e) => ({ label: e, value: e })),
      ];
    },
    getLocationFilters(state) {
      let locations = state.filtersValues.location;
      if (state.selectedFilters.country) {
        locations = locations.filter((e) => e.country === state.selectedFilters.country);
      }

      if (state.selectedFilters.locationType) {
        locations = locations.filter((e) => e.type === state.selectedFilters.locationType);
      }

      return [
        defaultValue,
        ...locations
          .map((e) => {
            return {
              label: `${e.name}${e.city ? ', ' + e.city : ''}`,
              value: e.id,
            };
          })
          .sort((a, b) => (a.label > b.label ? 1 : -1)),
      ];
    },
    getManufacturerFilters(state) {
      return mapSimpleFilter(state.filtersValues.manufacturer);
    },
    getAllLocationFilters(state) {
      return {
        country: state.selectedFilters.country,
        type: state.selectedFilters.locationType,
        manufacturer: state.selectedFilters.manufacturer,
        location: state.selectedFilters.location,
        dateRange: state.selectedFilters.dateRange,
        includeEmptyLocations: state.selectedFilters.includeEmptyLocations,
      };
    },
    getUtilizationView(state) {
      return state.utilizationView;
    },
    getHFIUtilizationView(state) {
      return state.hfiUtilizationView;
    },
    getSelectedFilters(state) {
      return { ...state.selectedFilters };
    },
    getTreatmentCenters(state) {
      return [
        defaultValue,
        ...state.filtersValues.location
          .filter((e) => {
            return e.type === 'Treatment center';
          })
          .map((e) => {
            return {
              label: `${e.name}${e.city ? ', ' + e.city : ''}`,
              value: e.id,
            };
          })
          .sort((a, b) => (a.label > b.label ? 1 : -1)),
      ];
    },
    getDistributionCenters(state) {
      return [
        defaultValue,
        ...state.filtersValues.location
          .filter((e) => {
            return e.type === centerType.DISTRIBUTION_CENTER;
          })
          .map((e) => {
            return {
              label: `${e.name}${e.city ? ', ' + e.city : ''}`,
              value: e.id,
            };
          })
          .sort((a, b) => (a.label > b.label ? 1 : -1)),
      ];
    },
  },
};
