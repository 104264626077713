<template>
  <div id="wfh-dashboard-application-wrapper">
    <main-layout />
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import './scss/globals';
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-size: 62.5%;
  overflow: hidden;
}
body {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  width: 100%;
  font-size: 18px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

#nav {
  display: inline-flex;

  div {
    margin-right: 10px;
  }
}

/* app colors go here */
/*temporary values*/
:root {
  --primary-color: #00a3ad;
  --teal-color: #038e96;
  --primary-danger: #de5757;
  --primary-grey: #ededed;
  --grey-100: #f6f6f6;
  --grey-150: #ededed;
  --grey-200: #dfdfdf;
  --grey-250: #e4e7ed;
  --grey-300: #e6e6e6;
  --grey-600: #131415;
  --disabled-white: #89898a;
  --secondary-grey-600: #606266;
  --white: #ffffff;
  --red-400: #de5757;
}
</style>

<script>
import MainLayout from './views/layout/MainLayout';
import initApplication from './utils/initApplication';
export default {
  name: 'App',
  components: { MainLayout },
  mounted() {
    initApplication.loadElementCss();
    this.backendAuthTesting();
  },
  methods: {
    backendAuthTesting() {
      this.$http
        .get('/frontend-testing')
        .then((response) => {
          console.log(response, 'success');
        })
        .catch((error) => {
          console.error(error, 'error');
        });
    },
  },
};
</script>
