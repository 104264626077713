<template>
  <div class="expired-products-page-wrapper">
    <div class="main-white-box">
      <h1 class="page-headline">Expired products</h1>
      <h5 class="page-headline-description label-medium">
        The table below provides an overview of events where users have scanned expired products.
      </h5>
      <div class="expired-products-list-wrapper">
        <el-table
          :data="tableData"
          @sort-change="handleSortChange"
          header-row-class-name="label-medium header-row"
          :default-sort="{ prop: 'eventTime', order: 'descending' }"
          cell-class-name="overflow-visible"
        >
          <el-table-column prop="id" label="Event ID" sortable :width="120" align="left">
            <template v-slot="scope">
              <div :title="scope.row.id">
                {{ scope.row.id | shortenId }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            class-name="no-word-break-cell"
            prop="eventTime"
            label="Event date"
            sortable
            :min-width="80"
            align="left"
          >
            <template v-slot="scope">
              {{ dateToUTCFormatter(scope.row.eventTime) + ' UTC' }}
            </template>
          </el-table-column>
          <el-table-column
            class-name="no-word-break-cell"
            sortable
            prop="location"
            label="Location"
            :min-width="100"
            align="left"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="submittedBy"
            label="Submitted by"
            :min-width="100"
            align="left"
          >
          </el-table-column>
          <el-table-column
            prop="productName"
            sortable
            label="Product name"
            :min-width="100"
            align="left"
          >
          </el-table-column>
          <el-table-column prop="lot" sortable label="LOT" align="left" :min-width="80">
          </el-table-column>
          <el-table-column
            prop="expiryDate"
            label="Expiry date"
            align="left"
            sortable
            :min-width="90"
          >
            <template v-slot="scope">
              {{ scope.row.expiryDate | prettyPrintDate }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          @size-change="handleLimitChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[5, 10, 25, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="prev, pager, next, sizes, total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { dateToUTCFormatter } from '@/utils/numberFormatter';

const { shortenUuid } = require('../utils/utils');
export default {
  name: 'ExpiredProducts',
  filters: {
    prettyPrintDate(val) {
      return new Date(val).toLocaleDateString();
    },
    shortenId(val) {
      return shortenUuid(val);
    },
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      limit: 10,
      total: 0,
      sortBy: 'eventTime:descending',
      utilizedMax: 0,
    };
  },
  computed: {
    selectedFilters() {
      return this.$store.getters.getSelectedFilters;
    },
  },
  watch: {
    selectedFilters() {
      this.fetchExpiredProducts();
    },
  },
  created() {
    this.fetchExpiredProducts();
  },
  methods: {
    dateToUTCFormatter,
    async fetchExpiredProducts() {
      const l = this.$loading();
      const { country, location, dateRange } = this.selectedFilters;
      try {
        const { data } = await this.$http.get('expired-products', {
          params: {
            country,
            location,
            dateRange,
            offset: this.currentPage * this.limit - this.limit,
            limit: this.limit,
            sortBy: this.sortBy,
          },
        });

        this.total = data.total;
        this.tableData = data.products.map((e) => {
          return {
            ...e,
            location: e.location + ', ' + e.city,
          };
        });
      } catch (e) {
        this.$notify({ type: 'error', message: "Couldn't fetch expired products." });
      } finally {
        l.close();
      }
    },
    handleSortChange(val) {
      const prop = val.prop === 'vials' ? 'utilized' : val.prop;
      this.sortBy = `${prop}:${val.order}`;
      this.fetchExpiredProducts();
    },
    handleLimitChange(limit) {
      this.limit = limit;
      this.fetchExpiredProducts();
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.fetchExpiredProducts();
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/variables';

.expired-products-page-wrapper {
  height: 100%;
  overflow: auto;

  .main-white-box {
    min-height: 100%;
    padding-bottom: 60px;
    position: relative;
    h1 {
      text-align: start;
      margin-bottom: 34px;
    }
    .page-headline-description {
      text-align: start;
      margin-bottom: 34px;
    }
    .expired-products-list-wrapper {
      min-height: calc(100% - 58px);
      ::v-deep .header-row {
        th {
          font-size: 14px;
          color: var(--black-tertiary);
          font-weight: 400;
        }
      }
      ::v-deep .el-table__body {
        .no-word-break-cell {
          .cell {
            word-break: keep-all;
          }
        }
        .cell {
          color: var(--black-primary);
          font-weight: 400;
        }
      }
    }
  }
  .pagination {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 16px;
  }
}
</style>
