<template>
  <div class="map-legend-wrapper">
    <div class="map-legend-item" v-if="distributionCenter">
      <div class="text label-small">Distribution center</div>
      <img class="icon" :src="`${$endPoint}/assets/icons/distribution_center.svg`" alt="" />
    </div>
    <div class="map-legend-item" v-if="treatmentCenter">
      <div class="text label-small">Treatment center</div>
      <img class="icon" :src="`${$endPoint}/assets/icons/site.svg`" alt="" />
    </div>
    <div class="map-legend-item" v-if="redFlagEvent">
      <div class="text label-small">Red flag event</div>
      <img class="icon" :src="`${$endPoint}/assets/icons/flag.svg`" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteMapLegend',
  props: {
    distributionCenter: {
      type: Boolean,
      default: true,
    },
    treatmentCenter: {
      type: Boolean,
      default: true,
    },
    redFlagEvent: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.map-legend-wrapper {
  .map-legend-item {
    display: flex;
    flex-direction: row;
    padding: 0.2em 0;
    .text {
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
    }
    .icon {
      margin-left: auto;
      margin-top: auto;
      margin-bottom: auto;
    }

    border-bottom: 1px solid var(--border-black);
  }
}
</style>
