import axios from 'axios';
import { contexts, roles } from '@/utils/enums';

export default {
  state: {
    role: null,
    email: null,
    location: null,
    userId: null,
    name: null,
    linkedUserId: null,
  },
  mutations: {
    SET_USER_DATA(state, data) {
      state.role = data.roles;
      state.userId = data.id;
      state.name = data.name;
      state.email = data.email;
    },
    LOGOUT(state) {
      state.role = null;
      state.email = null;
      state.location = null;
      state.userId = null;
      delete axios.defaults.headers.common['Authorization'];
      localStorage.removeItem('wft');
    },
  },
  actions: {
    async signIn(store, credentials) {
      const { data } = await axios.post('/auth/login', credentials);
      axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
      localStorage.setItem('wft', data.token);
      return data;
    },

    async fetchUserInfo({ commit }, token) {
      if (token) {
        //Needs to be removed when implementing
        const actualToken = localStorage.getItem('wft');
        axios.defaults.headers.common['Authorization'] = `Bearer ${actualToken}`;
      }
      const { data } = await axios.get('/auth/me');
      commit('SET_USER_DATA', data);
      commit(
        'SWITCH_CONTEXT',
        data.roles.includes(roles.HFI_ADMIN) ? contexts.HFI_ADMIN : contexts.WFH_ADMIN,
      );
    },
  },
  getters: {
    isUserLoggedIn(state) {
      return state.role !== null;
    },
    getUserRole(state) {
      return state.role;
    },
    getUserName(state) {
      return state.name;
    },
    getUserEmail(state) {
      return state.email;
    },
  },
};
