import { contexts } from '@/utils/enums';

export default {
  state: {
    connectionStatus: null,
    showGlobalLoader: false,
    globalLoaderObject: null,
    loaderMessage: 'LOADING',
    currentContext: contexts.WFH_ADMIN,
  },
  mutations: {
    SHOW_STATUS_LINE(state, mode) {
      state.connectionStatus = mode;
    },
    HIDE_STATUS_LINE(state) {
      state.connectionStatus = null;
    },
    SHOW_GLOBAL_LOADER(state, message = 'LOADING') {
      state.loaderMessage = message;
      state.showGlobalLoader = true;
      state.globalLoaderObject = this._vm.$loading({
        target: '.wfh-dashboard-application-wrapper',
      });
    },
    HIDE_GLOBAL_LOADER(state) {
      state.showGlobalLoader = false;
      if (state.globalLoaderObject) {
        state.globalLoaderObject.close();
        state.globalLoaderObject = null;
      }
    },
    SWITCH_CONTEXT(state, newContext) {
      state.currentContext = newContext;
    },
  },
  actions: {
    showConnectionStatus(context, mode) {
      context.commit('SHOW_STATUS_LINE', mode);

      setTimeout(() => {
        context.commit('HIDE_STATUS_LINE');
      }, 2000);
    },
    switchContextTo(context, newContext) {
      context.commit('SWITCH_CONTEXT', newContext);
    },
  },
  getters: {
    getConnectionStatus(state) {
      return state.connectionStatus;
    },
    isGlobalLoading(state) {
      return state.showGlobalLoader;
    },
    getLoaderMessage(state) {
      return state.loaderMessage;
    },
    getCurrentContext(state) {
      return state.currentContext;
    },
  },
};
