import { Icon } from 'leaflet';
import { formatAmountNumber } from './numberFormatter';
import { centerType, issueTypes, vialDestructionReason } from './enums';

const icons = {
  redFlag: new Icon({
    iconRetinaUrl: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/flag.svg`,
    iconUrl: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/flag.svg`,
    tooltipAnchor: [11, 11],
    iconSize: [22, 22],
  }),
  blueBox: new Icon({
    iconRetinaUrl: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/site.svg`,
    iconUrl: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/site.svg`,
    tooltipAnchor: [11, 11],
    iconSize: [22, 22],
  }),
  redBox: new Icon({
    iconRetinaUrl: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/dangerSite.svg`,
    iconUrl: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/dangerSite.svg`,
    tooltipAnchor: [11, 11],
    iconSize: [22, 22],
  }),
  triangleError: new Icon({
    iconRetinaUrl: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/dangerTriangle.svg`,
    iconUrl: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/dangerTriangle.svg`,
    tooltipAnchor: [11, 11],
    iconSize: [22, 22],
  }),
  distributionCenter: new Icon({
    iconRetinaUrl: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/distribution_center.svg`,
    iconUrl: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/distribution_center.svg`,
    tooltipAnchor: [11, 11],
    iconSize: [22, 22],
  }),
  eventMarker: new Icon({
    iconRetinaUrl: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/event_marker.svg`,
    iconUrl: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/icons/event_marker.svg`,
    tooltipAnchor: [11, 11],
    iconSize: [22, 22],
  }),
};
const initialCoordinates = [20.5937, 78.9629];
const events = {
  refFlag: () => ({
    eventId: Math.floor(Math.random() * 10000),
    eventType: 'SHIPPING',
    eventDate: new Date(),
    issueType: 'IDLE_STOCK',
    isRedFlag: true,
    isResolved: false,
    coordinates: [
      initialCoordinates[0] + Math.cos(Math.random()) * Math.random() * 8,
      initialCoordinates[1] + Math.sin(Math.random()) * Math.random() * 8,
    ],
    submittedBy: {
      id: 'ABSCDDF-2324234-QSD-GGFG',
      email: 'jhon@doe.com',
    },
    issues: [
      {
        issueId: 87654,
        issueType: 'IDLE_STOCK',
        numberOfVials: 48,
        products: [
          {
            productName: 'Elocate 750',
            lot: 'PC2000',
            numberOfVials: 20,
          },
          {
            productName: 'Alprolix 1000',
            lot: 'PC2001',
            numberOfVials: 29,
          },
        ],
      },
      {
        issueId: 68993,
        issueType: 'IDLE_STOCK',
        numberOfVials: 12,
      },
    ],
    resolvedIssues: [
      {
        issueId: 12664,
        issueType: 'IDLE_STOCK',
        numberOfVials: 12,
        resolvedDate: new Date(),
        resolutionInfo: 'User scanned product from a different location post receipt',
      },
    ],
    products: [
      {
        productName: 'Elocate 750',
        lot: 'PC2000',
        numberOfVials: 2000,
      },
      {
        productName: 'Alprolix 1000',
        lot: 'PC2001',
        numberOfVials: 129,
      },
      {
        productName: 'Elocate 750',
        lot: 'PC2000',
        numberOfVials: 1000,
      },
    ],
  }),
  refFlagDestruction: () => ({
    eventId: Math.floor(Math.random() * 10000),
    eventType: 'DESTRUCTION',
    eventDate: new Date(),
    issueType: 'DAMAGED',
    isRedFlag: true,
    isResolved: false,
    coordinates: [
      initialCoordinates[0] + Math.cos(Math.random()) * Math.random() * 8,
      initialCoordinates[1] + Math.sin(Math.random()) * Math.random() * 8,
    ],
    submittedBy: {
      id: 'ABSCDDF-2324234-QSD-GGFG',
      email: 'jhon@doe.com',
    },
    issues: [
      {
        issueId: 87654,
        issueType: 'IDLE_STOCK',
        numberOfVials: 48,
        products: [
          {
            productName: 'Elocate 750',
            lot: 'PC2000',
            numberOfVials: 20,
          },
          {
            productName: 'Alprolix 1000',
            lot: 'PC2001',
            numberOfVials: 29,
          },
        ],
      },
      {
        issueId: 68993,
        issueType: 'IDLE_STOCK',
        numberOfVials: 12,
      },
    ],
    resolvedIssues: [
      {
        issueId: 12664,
        issueType: 'IDLE_STOCK',
        numberOfVials: 12,
        resolvedDate: new Date(),
        resolutionInfo: 'User scanned product from a different location post receipt',
      },
    ],
    destructionDetails: {
      reason: 'DAMAGED',
      images: [
        {
          id: Math.round(Math.random() * 10000),
          url: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/images/default-destroyed-product.png`,
        },
        {
          id: Math.round(Math.random() * 10000),
          url: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/images/default-destroyed-product.png`,
        },
        {
          id: Math.round(Math.random() * 10000),
          url: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/images/default-destroyed-product.png`,
        },
        {
          id: Math.round(Math.random() * 10000),
          url: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/images/default-destroyed-product.png`,
        },
      ],
    },
    products: [
      {
        productName: 'Elocate 750',
        lot: 'PC2000',
        numberOfVials: 2000,
      },
      {
        productName: 'Alprolix 1000',
        lot: 'PC2001',
        numberOfVials: 129,
      },
      {
        productName: 'Elocate 750',
        lot: 'PC2000',
        numberOfVials: 1000,
      },
    ],
  }),
  treatment: () => ({
    eventId: Math.floor(Math.random() * 10000),
    eventType: 'DISPENSING',
    eventDate: new Date(),
    issueType: 'IDLE_STOCK',
    isRedFlag: false,
    isResolved: false,
    coordinates: [
      initialCoordinates[0] + Math.cos(Math.random()) * Math.random() * 8,
      initialCoordinates[1] + Math.sin(Math.random()) * Math.random() * 8,
    ],
    submittedBy: {
      id: 'ABSCDDF-2324234-QSD-GGFG',
      email: 'jhon@doe.com',
    },
    issues: [
      {
        issueId: 87654,
        issueType: 'IDLE_STOCK',
        numberOfVials: 48,
        products: [
          {
            productName: 'Elocate 750',
            lot: 'PC2000',
            numberOfVials: 20,
          },
          {
            productName: 'Alprolix 1000',
            lot: 'PC2001',
            numberOfVials: 29,
          },
        ],
      },
      {
        issueId: 68993,
        issueType: 'IDLE_STOCK',
        numberOfVials: 12,
      },
    ],
    resolvedIssues: [
      {
        issueId: 12664,
        issueType: 'IDLE_STOCK',
        numberOfVials: 12,
        resolvedDate: new Date(),
        resolutionInfo: 'User scanned product from a different location post receipt',
      },
    ],
    treatmentDetails: {
      patientId: '23456789',
      diagnosis: 'diagnosis',
      physicians: {
        name: 'Jhon doe',
      },
      treatmentDate: new Date(),
      treatmentType: {
        type: 'Acute bleed',
        acuteBleedType: 'Joint',
      },
      description: '-',
      inhibitors: false,
      homeTreatment: false,
      IUsUsed: 1500,
    },
    products: [
      {
        productName: 'Elocate 750',
        lot: 'PC2000',
        numberOfVials: 2000,
      },
      {
        productName: 'Alprolix 1000',
        lot: 'PC2001',
        numberOfVials: 129,
      },
      {
        productName: 'Elocate 750',
        lot: 'PC2000',
        numberOfVials: 1000,
      },
    ],
  }),
  shipping: () => ({
    eventId: Math.floor(Math.random() * 10000),
    eventType: 'SHIPPING',
    eventDate: new Date(),
    isResolved: false,
    coordinates: [
      initialCoordinates[0] + Math.cos(Math.random()) * 2,
      initialCoordinates[1] + Math.sin(Math.random()) * 2,
    ],
    issueType: 'IDLE_STOCK',
    submittedBy: {
      id: 'ABSCDDF-2324234-QSD-GGFG',
      email: 'Adam@smith.com',
    },
    products: [
      {
        productName: 'Elocate 750',
        lot: 'PC2000',
        numberOfVials: 2000,
      },
      {
        productName: 'Alprolix 1000',
        lot: 'PC2001',
        numberOfVials: 129,
      },
      {
        productName: 'Elocate 750',
        lot: 'PC2000',
        numberOfVials: 1000,
      },
    ],
  }),
  shippingwithResolvedIssue: () => ({
    eventId: Math.floor(Math.random() * 10000),
    eventType: 'SHIPPING',
    eventDate: new Date(),
    isResolved: true,
    coordinates: [
      initialCoordinates[0] + Math.cos(Math.random()) * 2,
      initialCoordinates[1] + Math.sin(Math.random()) * 2,
    ],
    issueType: 'IDLE_STOCK',
    submittedBy: {
      id: 'ABSCDDF-2324234-QSD-GGFG',
      email: 'Adam@smith.com',
    },
    resolvedIssues: [
      {
        issueId: 12664,
        issueType: 'IDLE_STOCK',
        numberOfVials: 12,
        resolvedDate: new Date(),
        resolutionInfo: 'User scanned product from a different location post receipt',
      },
    ],
    products: [
      {
        productName: 'Elocate 750',
        lot: 'PC2000',
        numberOfVials: 2000,
      },
      {
        productName: 'Alprolix 1000',
        lot: 'PC2001',
        numberOfVials: 129,
      },
      {
        productName: 'Elocate 750',
        lot: 'PC2000',
        numberOfVials: 1000,
      },
    ],
  }),
};

const getEventsData = () => {
  return [
    {
      locationId: 'UGF76-343HJ-Q300S',
      locationName: 'HFI DC',
      locationType: 'TREATMENT_CENTER',
      numberOfEvents: 49, // Must be calculated from events below
      redFlagEvent: 2, // Must be calculated from events below
      isAllResolved: false, // Must be calculated from events below
      coordinates: [
        initialCoordinates[0] + (Math.cos(Math.random()) * 20) / 4,
        initialCoordinates[1] + (Math.sin(Math.random()) * 20) / 4,
      ],
      events: {
        redFlags: Array.from(Array(2)).map(() => events.refFlag()),
        events: Array.from(Array(47)).map(() => events.shipping()),
      },
    },
    {
      locationId: 'UGF76-343HJ-QS888S',
      locationName: 'Treatment Center 1',
      locationType: 'TREATMENT_CENTER',
      numberOfEvents: 12, // Must be calculated from events below
      redFlagEvent: 0, // Must be calculated from events below
      isAllResolved: false, // Must be calculated from events below
      coordinates: [
        initialCoordinates[0] + (Math.cos(Math.random() * 10) * 3) / 4,
        initialCoordinates[1] + (Math.sin(Math.random() * 10) * 3) / 4,
      ],
      events: {
        redFlags: Array.from(Array(0)).map(() => events.refFlag()),
        events: Array.from(Array(12)).map(() => events.treatment()),
      },
    },
    {
      locationId: 'UGF76-343HJ-QSDQS',
      locationName: 'Distribution Center 1',
      locationType: 'DISTRIBUTION_CENTER',
      numberOfEvents: 4, // Must be calculated from events below
      redFlagEvent: 0, // Must be calculated from events below
      isAllResolved: true, // Must be calculated from events below
      coordinates: [
        initialCoordinates[0] + (Math.cos(Math.random() * 100) * 3) / 4,
        initialCoordinates[1] + (Math.sin(Math.random() * 100) * 3) / 4,
      ],
      events: {
        redFlags: Array.from(Array(0)).map(() => events.refFlag()),
        events: Array.from(Array(4)).map(() => events.shippingwithResolvedIssue()),
      },
    },
    {
      locationId: `UGF76-343HJ-QSDQS-${Math.floor(Math.random() * 10000)}`,
      locationName: 'Distribution Center 2',
      locationType: 'DISTRIBUTION_CENTER',
      numberOfEvents: 1, // Must be calculated from events below
      redFlagEvent: 0, // Must be calculated from events below
      isAllResolved: true, // Must be calculated from events below
      coordinates: [
        initialCoordinates[0] + (Math.cos(Math.random() * 100) * 3) / 4,
        initialCoordinates[1] + (Math.sin(Math.random() * 100) * 3) / 4,
      ],
      events: {
        redFlags: Array.from(Array(1)).map(() => events.refFlagDestruction()),
        events: Array.from(Array(0)).map(() => events.shippingwithResolvedIssue()),
      },
    },
  ];
};

const createMapMarkers = (locationsData) => {
  return locationsData.reduce((p, c) => {
    p.push({
      id: `LOCATION${Math.floor(Math.random() * 10000)}`,
      'lat-lng': c.coordinates,
      icon: c.events.redFlags?.length > 0 ? 'DANGER_' + c.locationType : c.locationType,
      data: {
        locationName: c.locationName,
        locationType: c.locationType,
        locationId: c.locationId,
        eventCount: c.numberOfEvents,
        redFlagEvents: c.redFlagEvent,
      },
    });
    c.events.redFlags.forEach((event) => {
      p.push({
        id: event.eventId,
        'lat-lng': event.coordinates,
        icon: 'RED_FLAG',
        data: {
          locationName: c.locationName,
          locationType: c.locationType,
          locationId: c.locationId,
          eventCount: c.numberOfEvents,
          redFlagEvents: c.redFlagEvent,
          event: event,
        },
      });
    });
    return p;
  }, []);
};

const data = getEventsData();

export const Icons = icons;
export const CreateMapMarkers = createMapMarkers;
export default {
  ogSlots: [
    {
      id: 1,
      'lat-lng': [initialCoordinates[0] + Math.cos(1) * 2, initialCoordinates[1] + Math.sin(1) * 2],
      icon: 'RED_FLAG', // SITE, DANGER_DISTRIBUTION_CENTER, RED_SITE
      data: {
        locationName: 'HFI DC ',
        locationType: 'DISTRIBUTION_CENTER',
        eventCount: 23,
        redFlagEvents: 2,
        events: events.refFlag,
      },
    },
    {
      id: 9,
      'lat-lng': [initialCoordinates[0] + 2, initialCoordinates[1] + 5],
      icon: 'RED_FLAG', // SITE, DANGER_DISTRIBUTION_CENTER, RED_SITE, DISTRIBUTION_CENTER
      data: {
        locationName: 'HFI DC ',
        locationType: 'DISTRIBUTION_CENTER',
        eventCount: 1,
        redFlagEvents: 1,
        event: events.refFlag,
      },
    },
    {
      id: 2,
      'lat-lng': [initialCoordinates[0] + Math.cos(2), initialCoordinates[1] + Math.sin(2) * 5],
      icon: 'DANGER_DISTRIBUTION_CENTER', // SITE, DANGER_DISTRIBUTION_CENTER, RED_SITE, DISTRIBUTION_CENTER
      data: {
        locationName: 'HFI DC ',
        locationType: 'DISTRIBUTION_CENTER',
        eventCount: 37,
        redFlagEvents: 1,
      },
    },
    {
      id: 3,
      'lat-lng': [
        initialCoordinates[0] + (Math.cos(3) * 3) / 4,
        initialCoordinates[1] + (Math.sin(3) * 3) / 4,
      ],
      icon: 'RED_SITE', // SITE, DANGER_DISTRIBUTION_CENTER, RED_SITE
      data: {
        locationName: 'HFI DC ',
        locationType: 'DISTRIBUTION_CENTER',
        eventCount: 24,
        redFlagEvents: 10,
      },
    },
    {
      id: 4,
      'lat-lng': [initialCoordinates[0] + Math.cos(4), initialCoordinates[1] + Math.sin(4)],
      icon: 'SITE', // SITE, DANGER_DISTRIBUTION_CENTER, RED_SITE, DISTRIBUTION_CENTER
      data: {
        locationName: 'HFI DC ',
        locationType: 'DISTRIBUTION_CENTER',
        eventCount: 12,
      },
    },
    {
      id: 5,
      'lat-lng': [
        initialCoordinates[0] + Math.cos(5) * 5,
        initialCoordinates[1] + (Math.sin(5) * 5) / 4,
      ],
      icon: 'SITE', // SITE, DANGER_DISTRIBUTION_CENTER, RED_SITE, DISTRIBUTION_CENTER
      data: {
        locationName: 'HFI DC ',
        locationType: 'DISTRIBUTION_CENTER',
        eventCount: 12,
      },
    },
    {
      id: 6,
      'lat-lng': [
        initialCoordinates[0] + Math.cos(6) * 6,
        initialCoordinates[1] + (Math.sin(6) * 6) / 4,
      ],
      icon: 'SITE', // SITE, DANGER_DISTRIBUTION_CENTER, RED_SITE, DISTRIBUTION_CENTER
      data: {
        locationName: 'HFI DC ',
        locationType: 'DISTRIBUTION_CENTER',
        eventCount: 12,
      },
    },
    {
      id: 7,
      'lat-lng': [
        initialCoordinates[0] + Math.cos(7) * 10,
        initialCoordinates[1] + (Math.sin(7) * 7) / 4,
      ],
      icon: 'SITE', // SITE, DANGER_DISTRIBUTION_CENTER, RED_SITE, DISTRIBUTION_CENTER
      data: {
        locationName: 'HFI DC ',
        locationType: 'DISTRIBUTION_CENTER',
        eventCount: 12,
      },
    },
    {
      id: 8,
      'lat-lng': [initialCoordinates[0] + 3, initialCoordinates[1] - 6],
      icon: 'SITE', // SITE, DANGER_DISTRIBUTION_CENTER, RED_SITE, DISTRIBUTION_CENTER
      data: {
        locationName: 'HFI DC ',
        locationType: 'DISTRIBUTION_CENTER',
        eventCount: 12,
      },
    },
  ],
  eventsData: data,
  slots: createMapMarkers(data),
  initialCoordinates,
  icons,
  mockEvents: [
    {
      locationName: 'Treatment center 123',
      locationType: 'TREATMENT_CENTER',
      numberOfEvents: 49,
      redFlagEvent: 2,
      eventId: 1,
    },
    {
      locationName: 'Treatment center 11',
      locationType: 'TREATMENT_CENTER',
      numberOfEvents: 19,
      redFlagEvent: 2,
      eventId: 2,
    },
    {
      locationName: 'Treatment center 87',
      locationType: 'TREATMENT_CENTER',
      numberOfEvents: 10,
      redFlagEvent: 0,
      eventId: 3,
    },
    {
      locationName: 'Treatment center 56',
      locationType: 'TREATMENT_CENTER',
      numberOfEvents: 24,
      redFlagEvents: 1,
      eventId: 4,
    },
  ],
};

export const inventoryRedFlagExample = {
  ...events.refFlag(),
  location: {
    ...getEventsData()[0],
    events: undefined,
  },
};
export const resolvedIssueEvent = {
  ...events.shippingwithResolvedIssue(),
  location: {
    ...getEventsData()[1],
    events: undefined,
  },
};

export const destroyedProductsExamples = () => {
  return Array.from(Array(20))
    .map((e, i) => {
      let maxValue = Math.ceil(1000 * Math.random());
      let values = [0, 0, 0];
      let sumParts = values[0];
      for (let i = 0; i < 2; i++) {
        values[i] = (maxValue - sumParts) * Math.random();
        sumParts += values[i];
      }
      values[2] = maxValue - sumParts;
      return {
        location: `Treatment Center ${i}`,
        locationType: centerType.TREATMENT_CENTER,
        vials: [
          {
            value: maxValue,
            labelOut: formatAmountNumber(maxValue),
            stackLabelOut: formatAmountNumber(maxValue),
            stack: [
              {
                value: values[0],
                label: formatAmountNumber(values[0]),
                labelOut: null,
                color: 'green',
                type: vialDestructionReason.DAMAGED,
              },
              {
                value: values[1],
                label: formatAmountNumber(values[1]),
                labelOut: null,
                color: 'blue',
                type: vialDestructionReason.EXPIRED,
              },
              {
                value: values[2],
                label: formatAmountNumber(values[2]),
                labelOut: null,
                color: 'dark-orange',
                type: vialDestructionReason.RECALLED,
              },
            ],
          },
        ],
      };
    })
    .sort((a, b) => (a.vials[0].value > b.vials[0].value ? -1 : 1));
};

export const productDestructionLogs = [
  {
    eventId: Math.ceil(Math.random() * 10000),
    eventDate: new Date(),
    submittedBy: 'jhon@doe.com',
    destructionReason: vialDestructionReason.DAMAGED,
    allVialsDestroyed: 50,
    vialsDestroyed: [
      {
        value: 30,
        product: 'Elocatate 750',
        lot: 'PC2009',
      },
      {
        value: 10,
        product: 'Alprolix 1000',
        lot: 'PC2001',
      },
      {
        value: 10,
        product: 'Kovaltry 2000',
        lot: '2712J4K',
      },
    ],
    destructionDetails: {
      images: [
        {
          id: Math.round(Math.random() * 10000),
          url: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/images/default-destroyed-product.png`,
        },
        {
          id: Math.round(Math.random() * 10000),
          url: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/images/default-destroyed-product.png`,
        },
        {
          id: Math.round(Math.random() * 10000),
          url: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/images/default-destroyed-product.png`,
        },
        {
          id: Math.round(Math.random() * 10000),
          url: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/images/default-destroyed-product.png`,
        },
      ],
    },
  },
  {
    eventId: Math.ceil(Math.random() * 10000),
    eventDate: new Date(),
    submittedBy: 'jhon@doe.com',
    destructionReason: vialDestructionReason.EXPIRED,
    allVialsDestroyed: 120,
    vialsDestroyed: [
      {
        value: 50,
        product: 'Elocatate 750',
        lot: 'PC2009',
      },
      {
        value: 10,
        product: 'Alprolix 1000',
        lot: 'PC2001',
      },
      {
        value: 60,
        product: 'Kovaltry 2000',
        lot: '2712J4K',
      },
    ],
    destructionDetails: {
      images: [
        {
          id: Math.round(Math.random() * 10000),
          url: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/images/default-destroyed-product.png`,
        },
        {
          id: Math.round(Math.random() * 10000),
          url: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/images/default-destroyed-product.png`,
        },
        {
          id: Math.round(Math.random() * 10000),
          url: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/images/default-destroyed-product.png`,
        },
        {
          id: Math.round(Math.random() * 10000),
          url: `${process.env.VUE_APP_FRONTEND_ENDPOINT}/assets/images/default-destroyed-product.png`,
        },
      ],
    },
  },
];

export const expiredProducts = () => {
  return Array.from(Array(6)).map((e, i) => {
    return {
      eventId: Math.ceil(Math.random() * 10000),
      eventDate: new Date(),
      location: `Treatment center ${i}`,
      submittedBy: 'jane@doe.com',
      product: `Elocatate ${150 * i}`,
      lot: 'PC2011',
      expiryDate: new Date(),
      event: inventoryRedFlagExample,
    };
  });
};

export const resolvedIssues = () => {
  return Array.from(Array(6)).map((e, i) => {
    return {
      issueId: Math.ceil(Math.random() * 50000),
      issueType: issueTypes.IDLE_STOCK,
      eventId: Math.ceil(Math.random() * 10000),
      resolvedDate: new Date(),
      location: `Treatment center ${i}`,
      resolutionDetails: 'User scanned product from a different location post receipt​',
      event: resolvedIssueEvent,
    };
  });
};
