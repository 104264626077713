<template>
  <div class="utilization-resolved-issues-page-wrapper">
    <div class="main-white-box">
      <h1 class="page-headline">Utilization</h1>
      <div class="content-wrapper">
        <div class="kpi-wrapper-content" v-if="general">
          <k-p-i-component
            :key="kpi.description"
            v-for="kpi of kpis"
            :description="kpi.description"
            :headline="kpi.title ? kpi.title : formatAmountNumber(kpi.value)"
          />
        </div>
        <div class="utilization-table-list-wrapper no-lines-table">
          <el-table
            :data="general ? siteTableData : tableData"
            header-row-class-name="label-medium header-row"
            :default-sort="{ prop: general ? 'utilized' : 'sumShippedTo', order: 'descending' }"
            :row-class-name="(r, i) => (isLastRow(r.rowIndex) ? 'adjusted-table-height' : '')"
            cell-class-name="overflow-visible"
            @row-click="(r) => onRowClick(r)"
            @sort-change="handleSortChange"
          >
            <el-table-column
              :prop="general ? 'name' : 'country'"
              :label="'Treatment center'"
              sortable="custom"
              :width="general ? 228 : 280"
              align="left"
              class-name="name-column"
            >
              <template slot-scope="scope">
                {{
                  `${scope.row.name ? scope.row.name : 'N/A'}${
                    scope.row.city ? ', ' + scope.row.city : ''
                  }`
                }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="general"
              prop="utilization"
              label="Utilization"
              :width="140"
              align="left"
              :formatter="(scope) => formatPercent(scope.utilization)"
              :sort-method="sortUtilization"
              sortable="custom"
            >
            </el-table-column>
            <el-table-column
              :prop="general ? 'utilized' : 'sumShippedTo'"
              label="# of vials"
              align="left"
              :width="general ? 420 : 580"
              :class-name="'column-with-bars'"
              sortable="custom"
              :sort-method="sortByTotalUtilization"
            >
              <template slot-scope="scope">
                <grid-axis
                  :max-value="getMaxValue(scope.row.vials, true)"
                  :step="getStepSize(scope, 0.9)"
                  :max-width="scope.column.realWidth"
                  :min-value="getMinValue(scope.row.vials, true)"
                  :width-reduction="0.9"
                  :show-axis-numbers="isLastRow(scope.$index)"
                  :formatter="formatAmountNumber"
                  :gridCount="4"
                  :default="getMaxValue(scope.row.vials, true) === 0"
                />
                <double-tacked-chart
                  :bars="scope.row.vials"
                  :inverted="false"
                  :max-width="scope.column.realWidth"
                  :step="getStepSize(scope, 0.9)"
                  :auto-switch-labels="!general"
                >
                  <div slot="tooltip" v-if="!general">
                    <div slot="content">
                      <ul class="basic-list">
                        <li>Treatment center: {{ getExpiryTooltipContent(scope).locationName }}</li>
                        <li>
                          Shipped by DCs:
                          {{ getExpiryTooltipContent(scope).shipped | prettyPrintDecimal }}
                        </li>
                        <li>
                          Used in last 30 days before expiry:
                          {{ getExpiryTooltipContent(scope).nearExpiry | prettyPrintDecimal }}
                        </li>
                        <li>
                          Scanned after expiration date:
                          {{ getExpiryTooltipContent(scope).expired | prettyPrintDecimal }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div slot="tooltip0" v-if="general">
                    <div slot="content">
                      <ul class="basic-list">
                        <li>Treatment center: {{ getStockTooltipInfo(scope).locationName }}</li>
                        <li>
                          Shipped by DCs:
                          {{ getStockTooltipInfo(scope).shipped | prettyPrintDecimal }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div slot="tooltip1" v-if="general">
                    <div slot="content">
                      <ul class="basic-list">
                        <li>Treatment center: {{ getStockTooltipInfo(scope).locationName }}</li>
                        <li>
                          Surgery: {{ getStockTooltipInfo(scope).surgery | prettyPrintDecimal }}
                        </li>
                        <li>
                          Acute bleed
                          {{ getStockTooltipInfo(scope).acuteBlood | prettyPrintDecimal }}
                        </li>
                        <li>
                          Prophylaxis:
                          {{ getStockTooltipInfo(scope).prophylaxis | prettyPrintDecimal }}
                        </li>
                        <li>Other: {{ getStockTooltipInfo(scope).other | prettyPrintDecimal }}</li>
                        <li>
                          Destroyed: {{ getStockTooltipInfo(scope).destroyed | prettyPrintDecimal }}
                        </li>
                        <li>
                          Total Utilized:
                          {{ getStockTooltipInfo(scope).totalStack | prettyPrintDecimal }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </double-tacked-chart>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KPIComponent from '../../components/basic/KPIComponent';
import { formatAmountNumber, formatPercent } from '@/utils/numberFormatter';
import {
  barChartColors,
  HFIUtilizationVialStatus,
  sliderTypes,
  vialsShipmentStatus,
  vialStatus,
} from '@/utils/enums';
import GridAxis from '../../components/basic/GridAxis';
import DoubleTackedChart from '../../components/charts/DoubleTackedChart';
import { openSideBar } from '@/utils/eventBus';
import { mapMutations } from 'vuex';
import { prettyPrintDecimal } from '@/utils/utils';
export default {
  name: 'HFIUtilization',
  components: { DoubleTackedChart, GridAxis, KPIComponent },
  filters: {
    prettyPrintDecimal(val) {
      return prettyPrintDecimal(val);
    },
  },
  props: {},
  data() {
    return {
      kpis: [
        {
          value: 0,
          description: 'Total vials shipped',
        },
        {
          value: 0,
          description: 'Total vials utilized',
        },
        {
          title: 'N/A',
          description: 'Utilization rate',
        },
      ],
      tableData: [],
      siteTableData: [],
      sort: 'sumShippedTo:descending',
    };
  },
  computed: {
    general() {
      return this.$store.getters.getHFIUtilizationView === 'general';
    },
    selectedFilters() {
      return this.$store.getters.getSelectedFilters;
    },
  },
  watch: {
    selectedFilters() {
      this.fetchUtilizationData();
    },
    general(newValue) {
      if (newValue) {
        this.sort = 'utilized:descending';
      } else {
        this.sort = 'sumShippedTo:descending';
      }
      this.fetchUtilizationData();
    },
  },
  created() {
    if (this.general) {
      this.sort = 'utilized:descending';
    } else {
      this.sort = 'sumShippedTo:descending';
    }
    this.fetchUtilizationData();
  },
  destroyed() {
    this.setUtilizationView(null);
  },
  methods: {
    ...mapMutations(['setUtilizationView']),
    sortByTotalUtilization(a, b) {
      const aUtilization = parseFloat(a.vials[1].value);
      const bUtilization = parseFloat(b.vials[1].value);
      return aUtilization - bUtilization;
    },
    handleSortChange(val) {
      this.sort = val.order ? `${val.prop}:${val.order}` : null;
      this.fetchUtilizationData();
    },
    async fetchUtilizationData() {
      if (this.general) {
        this.fetchLocationUtilization();
      } else {
        this.fetchExpiryUtilization();
      }
    },
    async fetchLocationUtilization() {
      const l = this.$loading();

      try {
        const { country, location, manufacturer, dateRange } = this.selectedFilters;
        const { data } = await this.$http.get('hfi/utilization/locations', {
          params: { country, location, manufacturer, dateRange, sortBy: this.sort },
        });
        this.kpis[0].value = data.summarized.totalVialsShipped;
        this.kpis[1].value = data.summarized.totalVialsUtilized;
        this.kpis[2].title = `${(data.summarized.utilizationRate * 100).toFixed(1)}%`;

        this.siteTableData = data.stats.map((e) => {
          return {
            id: e.id,
            treatmentCenter: `${e.name}${e.city ? ', ' + e.city : ''}`,
            country: e.country,
            city: e.city,
            name: e.name,
            utilization: e.utilization,
            vials: [
              {
                value: e.shippedTo ?? 0,
                label: formatAmountNumber(e.shippedTo),
                color: 'blue',
                type: vialsShipmentStatus.VIALS_SHIPPED,
              },
              {
                value: e.utilized,
                label: formatAmountNumber(e.utilized),
                color: 'blue',
                type: vialsShipmentStatus.VIALS_USED,
                stackLabelOut: formatAmountNumber(e.utilized),
                stack: [
                  {
                    value: e.surgery,
                    label: formatAmountNumber(e.surgery),
                    ogValue: e.surgery,
                    labelOut: null,
                    color: 'grey',
                    type: vialStatus.SURGERY,
                  },
                  {
                    value: e.acuteBleed,
                    label: formatAmountNumber(e.acuteBleed),
                    ogValue: e.acuteBleed,
                    labelOut: null,
                    color: 'pale-teal',
                    type: vialStatus.ACUTE_BLOOD,
                  },
                  {
                    value: e.prophylaxis,
                    label: formatAmountNumber(e.prophylaxis),
                    ogValue: e.prophylaxis,
                    labelOut: null,
                    color: 'light-teal',
                    type: vialStatus.PROPHYLAXIS,
                  },
                  {
                    value: e.other,
                    label: formatAmountNumber(e.other),
                    ogValue: e.other,
                    labelOut: null,
                    color: 'pale-red',
                    type: vialStatus.OTHER,
                  },
                  {
                    value: e.destroyed,
                    label: formatAmountNumber(e.destroyed),
                    ogValue: e.destroyed,
                    labelOut: null,
                    color: 'dark-red',
                    type: vialStatus.DESTROYED,
                  },
                ],
              },
            ],
          };
        });
      } catch (e) {
        this.$notify({ message: 'Failed to fetch location utilization data.', type: 'error' });
      } finally {
        l.close();
      }
    },
    async fetchExpiryUtilization() {
      const l = this.$loading();
      try {
        const { data } = await this.$http.get('hfi/utilization/expiry', {
          params: { ...this.selectedFilters, sortBy: this.sort },
        });

        this.tableData = data.map((e) => {
          return {
            country: e.country,
            utilization: e.utilization,
            city: e.city,
            name: e.name,
            vials: [
              {
                value: e.sumShippedTo ?? 0,
                label: formatAmountNumber(e.sumShippedTo ?? 0),
                color: barChartColors.BLUE,
                type: HFIUtilizationVialStatus.HFI_SHIPPED_BY_DCS,
              },
              {
                value: e.usedBeforeExpiry ?? 0,
                label: formatAmountNumber(e.usedBeforeExpiry ?? 0),
                color: barChartColors.LIGHT_TEAL,
                type: HFIUtilizationVialStatus.HFI_USED_ON_LAST_MONTH,
              },
              {
                value: e.ExpiredOnScanning ?? 0,
                label: formatAmountNumber(e.ExpiredOnScanning ?? 0),
                color: barChartColors.PALE_RED,
                type: HFIUtilizationVialStatus.EXPIRED_ON_SCANNING,
              },
            ],
          };
        });
      } catch (e) {
        this.$notify({ message: 'Failed to fetch location utilization data.', type: 'error' });
      } finally {
        l.close();
      }
    },
    sortUtilization(a, b) {
      let utilizationA = parseFloat(a.utilization);
      let utilizationB = parseFloat(b.utilization);
      utilizationA = isFinite(utilizationA) ? utilizationA : 0;
      utilizationB = isFinite(utilizationB) ? utilizationB : 0;
      return utilizationA - utilizationB;
    },
    formatAmountNumber,
    formatPercent,
    getStepSize(scope, reduction = 0.9) {
      const maxValue = Math.max(
        ...(this.general ? this.siteTableData : this.tableData).reduce(
          (p, c) => [...p, ...c.vials.map((t) => t.value)],
          [],
        ),
      );

      return maxValue > 0 ? (scope.column.realWidth * reduction) / maxValue : 0;
    },
    getMaxValue() {
      return Math.max(
        ...(this.general ? this.siteTableData : this.tableData).reduce((p, c) => {
          return [...p, ...c.vials.map((t) => t.value)];
        }, []),
      );
    },
    getMinValue() {
      return Math.min(
        ...(this.general ? this.siteTableData : this.tableData).reduce(
          (p, c) => [...p, ...c.vials.map((t) => t.value)],
          [],
        ),
      );
    },
    isLastRow(rowindex) {
      return rowindex === (this.general ? this.siteTableData : this.tableData).length - 1;
    },
    onRowClick(row) {
      if (row) return;
      openSideBar(sliderTypes.UTILIZATION_PRODUCT_BREAKDOWN, {
        id: row.id,
        countryName: row.country,
        treatmentCenterName: row.treatmentCenter,
        totalShipped: row.vials.find((e) => e.type === vialsShipmentStatus.VIALS_SHIPPED)?.value,
        totalUtilized: row.vials.find((e) => e.type === vialsShipmentStatus.VIALS_USED)?.value,
        filters: {
          manufacturer: this.selectedFilters.manufacturer,
          dateRange: this.selectedFilters.dateRange,
        },
      });
    },
    getStockTooltipInfo({ row: { name, city, country, vials } }) {
      return {
        shipped: vials.find((e) => e.type === vialsShipmentStatus.VIALS_SHIPPED)?.value,
        countryName: country,
        city: city,
        name: name,
        locationName: `${name ? name : 'N/A'}${city ? ', ' + city : ''}`,
        total: vials.reduce((p, c) => {
          p += parseFloat(c.value);
          return p;
        }, 0),
        totalStack: vials.find((e) => e.type === vialsShipmentStatus.VIALS_USED)?.value,
        surgery: this.findInStack(vials, vialsShipmentStatus.VIALS_USED, vialStatus.SURGERY),
        acuteBlood: this.findInStack(vials, vialsShipmentStatus.VIALS_USED, vialStatus.ACUTE_BLOOD),
        destroyed: this.findInStack(vials, vialsShipmentStatus.VIALS_USED, vialStatus.DESTROYED),
        prophylaxis: this.findInStack(
          vials,
          vialsShipmentStatus.VIALS_USED,
          vialStatus.PROPHYLAXIS,
        ),
        other: this.findInStack(vials, vialsShipmentStatus.VIALS_USED, vialStatus.OTHER),
      };
    },

    getExpiryTooltipContent({ row: { name, city, country, vials } }) {
      return {
        shipped: vials.find((e) => e.type === HFIUtilizationVialStatus.HFI_SHIPPED_BY_DCS)?.value,
        nearExpiry: vials.find((e) => e.type === HFIUtilizationVialStatus.HFI_USED_ON_LAST_MONTH)
          ?.value,
        expired: vials.find((e) => e.type === HFIUtilizationVialStatus.EXPIRED_ON_SCANNING)?.value,
        countryName: country,
        city: city,
        name: name,
        locationName: `${name ? name : 'N/A'}${city ? ', ' + city : ''}`,
        total: vials.reduce((p, c) => {
          p += parseFloat(c.value);
          return p;
        }, 0),
      };
    },

    findInStack(List, parentType, stackType) {
      return List.find((e) => e.type === parentType)?.stack.find((e) => e.type === stackType)
        ?.value;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../scss/variables';

.utilization-resolved-issues-page-wrapper {
  .main-white-box {
    h1 {
      text-align: start;
      margin-bottom: 34px;
    }
  }

  .content-wrapper {
    .kpi-wrapper-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .kpi-component-wrapper {
        &:not(:last-child) {
          margin-right: 0.4em;
        }
      }
    }

    .utilization-table-list-wrapper {
      margin-top: 1.5em;

      ::v-deep .el-table {
        .cell {
          word-break: break-word;
        }
      }

      ::v-deep .overflow-visible {
        .cell {
          overflow: visible;
        }
      }
      ::v-deep .label-medium {
        color: var(--black-tertiary);
        font-size: 14px;
        th {
          font-weight: 400;
        }
      }

      ::v-deep .adjusted-table-height {
        height: 96px;
        .el-table__cell {
          padding-top: 0;
        }

        td {
          padding-top: 5px;
          padding-bottom: 5px;
          .grid-axis-lines-wrapper {
            height: 60px;
          }
          .cell {
            margin-top: -30px;
          }
        }
      }

      ::v-deep .el-table {
        .column-with-bars {
          .cell {
            padding-left: 0;
            padding-right: 0;
          }
        }
        ::v-deep .header-row {
          th {
            font-size: 14px;
            color: var(--black-tertiary);
          }
        }
      }
    }
  }
}
</style>
