<template>
  <div class="basic-filter-wrapper">
    <h5 class="label-small">Date Range</h5>
    <el-date-picker
      v-model="value"
      @change="setFilter"
      class="date-range-select"
      type="daterange"
      placeholder="Start date"
    >
    </el-date-picker>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'DateTimeFilter',
  data() {
    return {
      value: this.$store.getters.getAllLocationFilters.dateRange,
    };
  },
  methods: {
    ...mapMutations(['activateFilter']),
    setFilter(val) {
      let value = val;
      if (value) {
        value = val.map((e) => {
          return dayjs(e).format('YYYY-MM-DD');
        });
      }

      this.activateFilter({ filter: 'dateRange', value });
    },
    resetLocalFilter() {
      this.value = this.$store.getters.getAllLocationFilters.dateRange;
    },
  },
};
</script>

<style lang="scss">
.label-small {
  margin-bottom: 8px;
  text-align: start;
}
.date-range-select {
  text-align: start;
  display: block;
  width: 100% !important;
}
</style>
