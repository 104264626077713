<template>
  <div class="product-breakdown-table">
    <el-table
      :data="tableData"
      header-row-class-name="label-medium"
      :row-class-name="(r, i) => (isLastRow(r.rowIndex) ? 'adjusted-table-height' : '')"
      cell-class-name="overflow-visible"
      @sort-change="handleSortChange"
      :default-sort="{ prop: 'vials', order: 'descending' }"
    >
      <el-table-column prop="manufacturer" sortable label="Manufacturer" :width="180" align="left">
      </el-table-column>
      <el-table-column prop="product" sortable label="Product" :width="180" align="left">
      </el-table-column>
      <el-table-column prop="lot" sortable label="Lot" :width="180" align="left"> </el-table-column>
      <el-table-column
        prop="utilization"
        sortable
        label="Utilization rate"
        :width="180"
        align="left"
        :formatter="(scope) => formatPercent(scope.utilization)"
      >
      </el-table-column>
      <el-table-column
        prop="vials"
        sortable
        label="# of vials"
        align="left"
        :class-name="'column-with-bars'"
        :sort-method="sortByUtilized"
      >
        <template slot-scope="scope">
          <grid-axis
            v-if="false"
            :inverted="false"
            :max-value="getMaxValue(scope)"
            :step="getStepSize(scope, 0.8, false)"
            :max-width="scope.column.realWidth"
            :min-value="getMinValue(scope)"
            :width-reduction="0.8"
            :show-axis-numbers="isLastRow(scope.$index)"
            :formatter="formatAmountNumber"
            :grid-count="4"
            :default="getMaxValue(scope.row.vials, true) === 0"
          />
          <double-tacked-chart
            :max-value="getMaxValue(scope)"
            :min-value="getMinValue(scope)"
            :bars="scope.row.vials"
            :max-width="scope.column.realWidth"
            :clickable="false"
            :step="getStepSize(scope, 0.8)"
          >
            <div slot="tooltip0">
              <div slot="content">
                <ul class="basic-list">
                  <li>
                    Shipped by WFH: {{ getStockTooltipInfo(scope).shipped | prettyPrintDecimal }}
                  </li>
                </ul>
              </div>
            </div>
            <div slot="tooltip1">
              <div slot="content">
                <ul class="basic-list">
                  <li>Surgery: {{ getStockTooltipInfo(scope).surgery | prettyPrintDecimal }}</li>
                  <li>
                    Acute bleed:
                    {{ getStockTooltipInfo(scope).acuteBlood | prettyPrintDecimal }}
                  </li>
                  <li>
                    Prophylaxis: {{ getStockTooltipInfo(scope).prophylaxis | prettyPrintDecimal }}
                  </li>
                  <li>Other: {{ getStockTooltipInfo(scope).other | prettyPrintDecimal }}</li>
                  <li>
                    Destroyed: {{ getStockTooltipInfo(scope).destroyed | prettyPrintDecimal }}
                  </li>
                  <li>
                    Total Utilized: {{ getStockTooltipInfo(scope).total | prettyPrintDecimal }}
                  </li>
                </ul>
              </div>
            </div>
          </double-tacked-chart>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { vialsShipmentStatus, vialStatus } from '../utils/enums';
import GridAxis from './basic/GridAxis';
import { formatAmountNumber, formatPercent } from '../utils/numberFormatter';
import DoubleTackedChart from './charts/DoubleTackedChart';
import { prettyPrintDecimal } from '@/utils/utils';

export default {
  name: 'UtilizationProductBreakdownTable',
  components: { DoubleTackedChart, GridAxis },
  filters: {
    prettyPrintDecimal(val) {
      return prettyPrintDecimal(val);
    },
  },
  props: {
    tableData: {
      type: Array,
      default: null,
    },
    site: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    formatAmountNumber,
    formatPercent,
    getStepSize(scope, reduction = 0.9) {
      const maxValue = this.getMaxValue();
      return maxValue > 0 ? (scope.column.realWidth * reduction) / maxValue : 0;
    },
    getMaxValue() {
      return Math.max(
        ...this.$props.tableData.reduce(
          (p, c) => [...p, ...c.vials.map((t) => t.value)],
          [this.max],
        ),
      );
    },
    getMinValue() {
      return Math.min(
        ...this.$props.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []),
      );
    },
    isLastRow(rowindex) {
      return rowindex === this.$props.tableData.length - 1;
    },

    getStockTooltipInfo(scope) {
      const a = {
        shipped: scope.row.vials.find((e) => e.type === vialsShipmentStatus.VIALS_SHIPPED).value,
        surgery: scope.row.vials
          .find((e) => e.type === vialsShipmentStatus.VIALS_USED)
          .stack.find((e) => e.type === vialStatus.SURGERY)?.value,
        acuteBlood: scope.row.vials
          .find((e) => e.type === vialsShipmentStatus.VIALS_USED)
          .stack.find((e) => e.type === vialStatus.ACUTE_BLOOD)?.value,
        destroyed: scope.row.vials
          .find((e) => e.type === vialsShipmentStatus.VIALS_USED)
          .stack.find((e) => e.type === vialStatus.DESTROYED)?.value,
        prophylaxis: scope.row.vials
          .find((e) => e.type === vialsShipmentStatus.VIALS_USED)
          .stack.find((e) => e.type === vialStatus.PROPHYLAXIS)?.value,
        other: scope.row.vials
          .find((e) => e.type === vialsShipmentStatus.VIALS_USED)
          .stack.find((e) => e.type === vialStatus.OTHER)?.value,
        total: scope.row.vials
          .find((e) => e.type === vialsShipmentStatus.VIALS_USED)
          .stack.reduce((p, c) => {
            p += parseFloat(c.value);
            return p;
          }, 0),
      };
      return a;
    },
    handleSortChange(val) {
      const prop = val.prop === 'vials' ? 'utilized' : val.prop;
      this.$emit('sortChange', `${prop}:${val.order}`);
    },
    sortByUtilized(a, b) {
      const aUtilization = parseFloat(a.vials[1].value);
      const bUtilization = parseFloat(b.vials[1].value);
      return aUtilization - bUtilization;
    },
  },
};
</script>

<style scoped lang="scss">
.product-breakdown-table {
  ::v-deep .label-medium {
    color: var(--black-tertiary);
    font-size: 14px;
    th {
      font-weight: 400;
    }
  }
}
</style>
