<template>
  <div
    :class="{
      'grid-axis-lines-wrapper': true,
      'no-ticks': !showAxisNumbers,
    }"
  >
    <div :class="{ 'grid-axis-lines': true, inverted }" :style="getGridTemplateColumns()">
      <div
        :class="`grid-item grid-border-right`"
        :key="axis.value * (i + 1)"
        v-for="(axis, i) of getAxisNumbers()"
      >
        <span class="axis-label" v-if="showAxisNumbers">
          {{ axis.label }}
        </span>
        <span
          v-if="showAxisNumbers && (inverted ? i === 0 : i === getAxisNumbers().length - 1)"
          class="axis-last-label"
          >{{ axis.lastValue }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GridAxis',
  props: {
    default: {
      type: Boolean,
      default: false,
    },
    maxValue: {
      type: Number,
      default: 10,
    },
    minValue: {
      type: Number,
      default: 10,
    },
    maxWidth: {
      type: Number,
      default: 200,
    },
    labelCharacterWidth: {
      type: Number,
      default: 8,
    },
    numberOfAxis: {
      type: Number,
      default: 4,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 10,
    },
    widthReduction: {
      type: Number,
      default: 0.9,
    },
    showAxisNumbers: {
      type: Boolean,
      default: true,
    },
    compensateForExcessValues: {
      type: Boolean,
      default: false,
    },
    formatter: {
      type: Function,
      default: null,
    },
    gridCount: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      axesCount: {},
    };
  },
  computed: {
    calculateNumberOfAxes() {
      return (
        this.gridCount ??
        (this.default
          ? 3
          : Math.min(
              Math.max(Math.floor(Math.log(this.$props.maxValue - this.$props.minValue)), 4),
              8,
            ))
      );
    },

    reducedMaxWidth() {
      return this.$props.maxWidth * this.$props.widthReduction;
    },
    divAxis() {
      return this.getBarWidth(this.default ? 1 : this.maxValue) / this.calculateNumberOfAxes;
    },
    modAxis() {
      return this.getBarWidth(this.default ? 1 : this.maxValue) % this.calculateNumberOfAxes;
    },
  },

  methods: {
    getAxisNumbers() {
      let increments = this.divAxis / this.step;
      const additions = this.modAxis > increments * 0.5 ? 1 : 0;
      increments =
        this.getBarWidth(this.maxValue) /
        (this.calculateNumberOfAxes + additions) /
        (this.default ? 1 : this.step);
      let axes = Array.from(Array(this.calculateNumberOfAxes + additions)).map((e, i) => {
        return {
          value: this.default ? 0 : increments * i,
          lastValue: this.default ? 0 : (this.calculateNumberOfAxes + additions) * increments,
          label: this.default ? 0 : increments * i,
        };
      });
      if (this.formatter) {
        axes = axes.map((e) => ({
          ...e,
          label: this.formatter(e.label),
          lastValue: this.formatter(e.lastValue),
        }));
      }
      if (this.$props.inverted) {
        axes = axes.reverse();
      }
      return axes;
    },
    getGridTemplateColumns() {
      const increments = this.divAxis / this.step;
      let additions = this.modAxis > increments * 0.5 ? 1 : 0;
      const axisLength = this.getBarWidth(this.maxValue) / (this.calculateNumberOfAxes + additions);

      let axisArray = Array.from(Array(this.calculateNumberOfAxes + additions)).map((e, i) => {
        if (this.compensateForExcessValues) {
          if (
            (this.$props.inverted ? i === 0 : i === this.calculateNumberOfAxes - 1) &&
            this.calculateNumberOfAxes * axisLength > this.reducedMaxWidth
          ) {
            return `${this.reducedMaxWidth - (this.calculateNumberOfAxes - 1) * axisLength}px`;
          }
        }
        return `${axisLength}px`;
      });
      if (this.$props.inverted) {
        axisArray = axisArray.reverse();
      }
      return `grid-template-columns: ${axisArray.join(' ')}; margin-left: ${
        this.$props.inverted ? this.maxWidth - this.getBarWidth(this.maxValue) : 0
      }px;`;
    },
    getBarWidth(value) {
      return value === 0 ? this.reducedMaxWidth : Math.max(value * this.$props.step, 20);
    },
  },
};
</script>

<style scoped lang="scss">
.grid-axis-lines-wrapper {
  height: 80%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  padding: 0;

  &.no-ticks {
    height: 100%;
  }

  .grid-axis-lines {
    display: grid;
    //grid-template-columns: repeat(4, 25%);
    max-width: 1920px;
    height: 100%;
    width: 100%;

    .grid-border-right {
      position: relative;
      border-right: 1px dashed var(--grey-200);
    }

    &.inverted {
      .grid-border-right {
        &:first-child {
          border-left: 1px dashed var(--grey-200);
        }

        .axis-last-label {
          position: absolute;
          left: 0;
          transform: translateX(-50%);
          bottom: -25px;
          color: var(--border-black);
        }

        .axis-label {
          position: absolute;
          right: 0;
          transform: translateX(50%);
          bottom: -25px;
          color: var(--border-black);
        }
      }
    }

    &:not(.inverted) {
      .grid-border-right {
        border-left: 1px dashed var(--grey-200);
        border-right: none;

        &:last-child {
          border-right: 1px dashed var(--grey-200);
        }
      }

      .axis-label {
        position: absolute;
        left: 0;
        transform: translateX(-50%);
        bottom: -25px;
        width: auto;
        color: var(--border-black);
      }

      .axis-last-label {
        position: absolute;
        right: 0;
        transform: translateX(50%);
        bottom: -25px;
        color: var(--border-black);
      }
    }
  }
}
</style>
