<template>
  <div class="map-legend-wrapper">
    <div
      :key="item.label"
      v-for="item of legendItem"
      :class="{ 'map-legend-item': true, header: item.header, 'no-separator': item.noSeparator }"
    >
      <div v-if="item.header" class="label-tiny item-header">
        {{ item.label }}
      </div>
      <div v-if="!item.header" class="small-label-legend text label-small">{{ item.label }}</div>
      <img v-if="item.icon" class="icon" :src="item.icon" alt="" />
      <div
        v-if="item.color"
        class="color-legend"
        :style="{
          'background-color': item.color,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MapLegend',
  props: {
    legendItem: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.map-legend-wrapper {
  .map-legend-item {
    width: 80%;
    display: flex;
    flex-direction: row;
    padding: 0.3em 1.5em 0.3em 0;
    .text {
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
      max-width: 80%;
    }
    .icon {
      margin-left: auto;
      margin-top: auto;
      margin-bottom: auto;
    }
    .color-legend {
      width: 40px;
      border-radius: 4px;
      height: 12px;
    }
    .small-label-legend {
      font-size: 14px;
    }

    &.no-separator {
      margin-bottom: 0.5em;
    }

    &:not(:last-child):not(.header):not(.no-separator) {
      border-bottom: 1px solid var(--border-black);
    }

    .item-header {
      max-width: 80%;
      border: none;
      margin-bottom: 1em;
      color: var(--black-disabled-15);
    }
  }
}
</style>
