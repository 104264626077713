<template>
  <div class="products-list-table">
    <div class="products-list-header">
      <h4 class="label-big">Products</h4>
      <p class="label-small">Total vials: {{ tableData.vialCount }}</p>
    </div>
    <div class="products-table">
      <el-table
        id="product-list-table"
        :data="tableData.data"
        header-cell-class-name="label-medium"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column prop="name" label="Product">
          <template slot-scope="scope" slot="header">
            {{ scope.column.label }}
          </template></el-table-column
        >
        <el-table-column prop="lot" label="LOT #">
          <template slot-scope="scope" slot="header">
            {{ scope.column.label }}
          </template></el-table-column
        >
        <el-table-column prop="numOfVials" label="# of vials">
          <template slot-scope="scope" slot="header">
            {{ scope.column.label }}
          </template></el-table-column
        >
        <el-table-column
          prop="portionUsed"
          label="Portion used"
          v-if="currentEventType === eventType.DISPENSING"
        >
          <template slot-scope="scope" slot="header">
            {{ scope.column.label }}
          </template>
          <template slot-scope="scope">
            {{ scope.row.portionUsed ? scope.row.portionUsed : scope.row.numOfVials }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { eventType } from '../utils/enums';
export default {
  name: 'ProductsListTable',
  props: {
    tableData: {
      type: Object,
      default: null,
    },
    currentEventType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      eventType,
    };
  },
};
</script>

<style scoped lang="scss">
.products-list-table {
  background-color: var(--grey-100);
  .products-list-header {
    text-align: start;
    align-self: start;
    padding: 1em 1em;
    h4 {
      margin-bottom: 1em;
    }
  }
  .products-table {
    max-height: 282px;
    overflow-y: auto;
    ::v-deep .label-medium {
      color: var(--black-disabled);
    }
  }
}
</style>
