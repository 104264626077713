<template>
  <div class="basic-filter-wrapper">
    <h5 class="label-small">Location type</h5>
    <el-select
      v-model="$store.state.filters.selectedFilters.locationType"
      placeholder="Select"
      v-bind="$props"
      v-on="$listeners"
      @change="setFilter"
      class="location-type-select"
    >
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import eventBus from '../../utils/eventBus';
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'LocationTypeFilter',
  data() {
    return {
      eventBus,
      value: null,
    };
  },
  computed: {
    ...mapGetters(['getLocationTypeFilters']),
    options() {
      return this.getLocationTypeFilters;
    },
  },
  methods: {
    ...mapMutations(['activateFilter']),
    setFilter(value) {
      this.activateFilter({ filter: 'locationType', value });
    },
  },
};
</script>

<style scoped lang="scss">
.label-small {
  margin-bottom: 8px;
  text-align: start;
}
.location-type-select {
  text-align: start;
  display: block;
}
</style>
