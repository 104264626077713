<template>
  <div class="main-container-wrapper">
    <div v-if="isPublicPage || isFullPage">
      <router-view />
    </div>
    <div class="main-content" v-else>
      <div class="main-row">
        <div class="sidebar">
          <sidebar>
            <div>
              <perfect-scrollbar class="filters-wrapper" :options="{ suppressScrollX: true }">
                <img class="mainLogo" :src="`${$endPoint}/assets/images/wfh-logo.png`" />
                <div v-if="currentRouteName === 'utilization'" class="context-switcher">
                  <utilization-page-context-switcher />
                </div>
                <div v-if="currentRouteName === 'hfiUtilization'" class="context-switcher">
                  <h-f-i-utilization-page-context-switcher />
                </div>
                <div
                  :key="filter"
                  :ref="filter"
                  v-for="filter of currentFilters"
                  :is="filter"
                ></div>
                <remove-filters-component
                  @resetLocalFilters="resetLocalFilter"
                  class="context-switcher"
                />
                <div v-if="currentRouteName === 'events'" class="map-legend">
                  <no-event-location-filter ref="no-event-location-filter" />
                  <site-map-legend />
                </div>
                <map-legend :legend-item="getMapLegend" />
              </perfect-scrollbar>
            </div>
          </sidebar>
        </div>
        <div class="inner-content-wrapper" id="validatorPanelTarget">
          <div class="main-page-wrapper">
            <div class="main-top-navigation">
              <div id="nav" class="navigation-buttons">
                <black-button
                  :key="route.name"
                  v-for="route of AvailableRoutes"
                  @click="navigateTo(route)"
                  :text="route.name !== currentRouteName"
                  color="blue"
                  >{{ route.label }}</black-button
                >
              </div>
              <div class="dropdowns-wrapper">
                <el-dropdown
                  @command="dashboardSwitcherCommands"
                  class="dashboard-management-dropdown"
                  :trigger="'click'"
                >
                  <span class="el-dropdown-link my-auto context-switcher">
                    <span class="current-context">
                      {{ getSelectedDashboardName }}
                    </span>
                    <img
                      class="icon-img"
                      :src="`${$endPoint}/assets/icons/context-switch-icon.svg`"
                    />
                  </span>
                  <el-dropdown-menu slot="dropdown" class="dashboard-management-dropdown-slider">
                    <el-dropdown-item
                      :command="contextItem"
                      class="regular-item"
                      :key="contextItem"
                      v-for="contextItem of getAvailableContexts"
                    >
                      {{ contextNameResolver(contextItem) }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown
                  @command="logoutCommands"
                  class="user-management-dropdown"
                  :trigger="'click'"
                >
                  <span class="el-dropdown-link my-auto">
                    <div class="circular-avatar">
                      <span class="text">{{ getUserNameAbbreviation }}</span>
                    </div>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="user-management-dropdown-slider">
                    <el-dropdown-item command="no-command" class="user-info-item">
                      <div class="user-info">
                        <div class="wrapper">
                          <div class="circular-avatar">
                            <span class="text">{{ getUserNameAbbreviation }}</span>
                          </div>
                          <div class="user-info-section">
                            <p class="label-small">{{ getUserName }}</p>
                            <p class="label-small">{{ getUseremail }}</p>
                          </div>
                        </div>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item command="change-password" class="regular-item">
                      Change password
                    </el-dropdown-item>
                    <el-dropdown-item command="logout" class="regular-item">
                      <div class="d-flex">
                        <div class="my-auto mr-auto">Logout</div>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="main-page-content">
              <div class="main-page-content-wrapper">
                <router-view />
                <index-slider />
              </div>
            </div>
          </div>
          <div class="validation-panel">
            <img
              @click="openSideBar(sliderTypes.VERIFICATION)"
              :src="`${$endPoint}/assets/icons/validationIcon.svg`"
              alt=""
              class="validation-check clickable"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlackButton from '../../components/BlackButton';
import Sidebar from './Sidebar';
import CountryFilter from '../../components/filters/CountryFilter';
import LocationTypeFilter from '../../components/filters/LocationTypeFilter';
import DateTimeFilter from '../../components/filters/DateTimeFilter';
import ManufacturerFilter from '../../components/filters/ManufacturerFilter';
import LocationFilter from '../../components/filters/LocationFilter';
import SiteMapLegend from '../../components/mapLegends/SiteMapLegend';
import NoEventLocationFilter from '../../components/filters/NoEventLocationFilter';
import DistributionCenterFilter from '../../components/filters/DistributionCenterFilter';
import TreatmentCenterFilter from '../../components/filters/TreatmentCenterFilter';
import { openSideBar } from '@/utils/eventBus';
import { contexts, sliderTypes } from '@/utils/enums';
import IndexSlider from '../../components/sliders/IndexSlider';
import MapLegend from '../../components/mapLegends/MapLegend';
import {
  productFlowMapLegend,
  inventoryMapLegend,
  utilizationPage,
  destroyedProductsPage,
  HFIMapLegends,
} from '@/utils/mapLegends';
import UtilizationPageContextSwitcher from '../../components/UtilizationPageContextSwitcher';
import HFIUtilizationPageContextSwitcher from '@/components/filters/HFIUtilizationPageContextSwitcher';
import { contextNameResolver } from '@/utils/nameResolver';
import RemoveFiltersComponent from '@/components/filters/removeFiltersComponent';
import { mapMutations } from 'vuex';
export default {
  name: 'MainLayout',
  components: {
    RemoveFiltersComponent,
    HFIUtilizationPageContextSwitcher,
    UtilizationPageContextSwitcher,
    MapLegend,
    IndexSlider,
    NoEventLocationFilter,
    SiteMapLegend,
    LocationFilter,
    ManufacturerFilter,
    DateTimeFilter,
    LocationTypeFilter,
    CountryFilter,
    Sidebar,
    BlackButton,
    DistributionCenterFilter,
    TreatmentCenterFilter,
  },
  data() {
    return {
      productFlowMapLegend,
      inventoryMapLegend,
      utilizationPage,
      routes: [
        {
          name: 'events',
          label: 'Events',
          filters: [
            'country-filter',
            'location-type-filter',
            'location-filter',
            'manufacturer-filter',
            'date-time-filter',
          ],
          roles: ['WFH_ADMIN'],
          context: [contexts.WFH_ADMIN],
        },
        {
          name: 'product-flow',
          label: 'Product flow',
          filters: [
            'country-filter',
            'distribution-center-filter',
            'manufacturer-filter',
            'date-time-filter',
          ],
          roles: ['WFH_ADMIN'],
          context: [contexts.WFH_ADMIN],
        },
        {
          name: 'inventory',
          label: 'Inventory',
          filters: [
            'country-filter',
            'location-type-filter',
            'location-filter',
            'manufacturer-filter',
          ],
          roles: ['WFH_ADMIN'],
          context: [contexts.WFH_ADMIN],
        },
        {
          name: 'utilization',
          label: 'Utilization',
          filters: ['country-filter', 'manufacturer-filter', 'date-time-filter'],
          roles: ['WFH_ADMIN'],
          context: [contexts.WFH_ADMIN],
        },
        {
          name: 'destroyed-products',
          label: 'Destroyed products',
          filters: [
            'country-filter',
            'location-type-filter',
            'location-filter',
            'manufacturer-filter',
            'date-time-filter',
          ],
          roles: ['WFH_ADMIN'],
          context: [contexts.WFH_ADMIN],
        },
        {
          name: 'expired-products',
          label: 'Expired products',
          filters: ['country-filter', 'location-filter', 'date-time-filter'],
          roles: ['WFH_ADMIN'],
          context: [contexts.WFH_ADMIN],
        },
        {
          name: 'hfiUtilization',
          label: 'Utilization',
          filters: ['treatment-center-filter', 'manufacturer-filter', 'date-time-filter'],
          roles: ['HFI_ADMIN'],
          context: [contexts.HFI_ADMIN],
        },
        {
          name: 'hfiInventory',
          label: 'Inventory',
          filters: ['location-type-filter', 'location-filter', 'manufacturer-filter'],
          roles: ['HFI_ADMIN'],
          context: [contexts.HFI_ADMIN],
        },
        {
          name: 'hfiTreatments',
          label: 'Treatments',
          filters: ['treatment-center-filter', 'manufacturer-filter', 'date-time-filter'],
          roles: ['HFI_ADMIN'],
          context: [contexts.HFI_ADMIN],
        },
        // {
        //   name: 'resolved-issues',
        //   label: 'Resolved issues',
        //   filters: ['country-filter', 'location-filter', 'date-time-filter'],
        // },
      ],
      panelOpen: false,
      openSideBar,
      sliderTypes,
    };
  },
  computed: {
    getUserRoles() {
      return this.$store.getters.getUserRole;
    },
    getUserName() {
      return this.$store.getters.getUserName;
    },
    getSelectedDashboard() {
      return this.$store.getters.getCurrentContext;
    },
    getSelectedDashboardName() {
      return contextNameResolver(this.$store.getters.getCurrentContext);
    },
    getUseremail() {
      return this.$store.getters.getUserEmail;
    },
    getUserNameAbbreviation() {
      return this.$store.getters.getUserName
        ?.split(' ')
        .map((e) => e[0])
        .join('');
    },
    getAvailableContexts() {
      return Object.keys(contexts)
        .filter((e) => this.getUserRoles?.includes(e))
        .map((e) => contexts[e]);
    },
    isPublicPage() {
      return this.$route.meta.public;
    },
    isFullPage() {
      return this.$route.meta.layout === false;
    },
    currentRouteName() {
      return this.$route.name;
    },
    filtersByRoute() {
      return this.routes.reduce((p, c) => {
        p[c.name] = c.filters;
        return p;
      }, {});
    },
    currentFilters() {
      return this.filtersByRoute[this.currentRouteName];
    },
    utilizationLegend() {
      const legend = [...this.utilizationPage];
      if (this.$store.getters.getUtilizationView === 'site') {
        legend[0].label = 'Shipped by DCs';
      } else {
        legend[0].label = 'Shipped by WFH';
      }

      return legend;
    },
    getMapLegend() {
      switch (this.currentRouteName) {
        case 'product-flow': {
          return productFlowMapLegend;
        }
        case 'inventory': {
          return inventoryMapLegend;
        }
        case 'utilization': {
          return this.utilizationLegend;
        }
        case 'destroyed-products': {
          return destroyedProductsPage;
        }
        case 'hfiUtilization': {
          return this.$store.getters.getHFIUtilizationView === 'general'
            ? HFIMapLegends.utilization.general
            : HFIMapLegends.utilization.expiry;
        }
        case 'hfiInventory': {
          return HFIMapLegends.inventory;
        }
        case 'hfiTreatments': {
          return HFIMapLegends.treatments;
        }
        default: {
          return null;
        }
      }
    },
    utilizationView() {
      return this.$store.getters.getUtilizationView;
    },
    AvailableRoutes() {
      const currentContext = this.getSelectedDashboard;
      return this.routes.filter((e) => {
        return e.context.includes(currentContext);
      });
    },
  },
  watch: {
    utilizationView(val) {
      const indexOfUtilizationRoute = this.routes.findIndex((e) => e.name === 'utilization');
      if (val === 'country') {
        if (indexOfUtilizationRoute) {
          this.routes[indexOfUtilizationRoute].filters = [
            'country-filter',
            'manufacturer-filter',
            'date-time-filter',
          ];
        }
      } else {
        if (indexOfUtilizationRoute) {
          this.routes[indexOfUtilizationRoute].filters = [
            'country-filter',
            'treatment-center-filter',
            'manufacturer-filter',
            'date-time-filter',
          ];
        }
      }
    },
  },
  methods: {
    ...mapMutations(['resetAllFilters']),
    contextNameResolver,
    logoutFromApp() {
      this.$router.push({ name: 'login' });
      this.$store.commit('LOGOUT');
    },
    async logoutCommands(command) {
      switch (command) {
        case 'logout': {
          this.logoutFromApp();
          break;
        }
        case 'change-password': {
          this.$router.push({
            name: 'changePassword',
          });
          break;
        }
        default: {
          break;
        }
      }
    },
    async dashboardSwitcherCommands(command) {
      switch (command) {
        case 'WFH': {
          this.$store.commit('SWITCH_CONTEXT', contexts.WFH_ADMIN);
          this.$router.push({
            name: 'events',
          });
          break;
        }
        case 'HFI': {
          this.$store.commit('SWITCH_CONTEXT', contexts.HFI_ADMIN);
          this.$router.push({
            name: 'hfiUtilization',
          });
          break;
        }
        default: {
          break;
        }
      }
    },

    resetLocalFilter() {
      const localFilters = [
        'treatment-center-filter',
        'country-filter',
        'date-time-filter',
        'no-event-location-filter',
      ];
      localFilters.forEach((e) => {
        if (this.$refs[e]?.[0]) {
          this.$refs[e][0]?.resetLocalFilter();
        }
      });
    },

    navigateTo(route) {
      this.resetAllFilters();
      this.resetLocalFilter();
      this.$router.push({ name: route.name });
    },
  },
};
</script>

<style lang="scss">
@import '../../scss/variables';

.el-dropdown-menu.el-popper {
  padding: 0;
  &.dashboard-management-dropdown-slider {
    min-width: 180px;
  }
  .el-dropdown-menu__item {
    min-height: 40px;
    text-align: left;
    padding: 0;
    color: var(--black);

    &:hover {
      color: var(--black);
      background: var(--background-grey);
    }
    &.regular-item {
      padding: 0px 12px;
      line-height: 40px;
    }
    &.user-info-item {
      &:hover {
        background: inherit;
        cursor: inherit;
      }
    }
    .user-info {
      min-height: 68px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--border-black);
      .wrapper {
        display: flex;
        flex-direction: row;
        padding: 0px 12px;
        .circular-avatar {
          margin-top: auto;
          margin-bottom: auto;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          border: 1px solid var(--blue-brand);
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
            #0e3fe5;
          text-align: center;
          display: flex;
          line-height: 120%;
          text-transform: uppercase;
          .text {
            margin: auto;
            line-height: 14px;
          }
        }
        .user-info-section {
          margin-top: auto;
          margin-bottom: auto;
          margin-left: 1em;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.main-container-wrapper {
  min-height: 100vh;
  .main-top-navigation {
    margin-top: 16px;
    padding-bottom: 16px;
    text-align: start;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--grey-200);
    .navigation-buttons {
      .black-button {
        padding: 12px 16px;
        height: 40px;
      }
    }
    .dropdowns-wrapper {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      .user-management-dropdown,
      .dashboard-management-dropdown {
        margin-left: auto;
        width: auto;
        margin-top: auto;
        margin-bottom: auto;
        &.el-dropdown {
          .el-dropdown-link {
            padding: 0px 6px;
            display: flex;
            border-radius: 12px;
            font-weight: 500;
            color: var(--black);
            &:hover {
              cursor: pointer;
            }
            &.context-switcher {
              border-radius: 0px;
            }
            .current-context {
              height: 100%;
              margin-top: auto;
              margin-bottom: auto;
            }
            .circular-avatar {
              height: 40px;
              width: 40px;
              border-radius: 50%;
              border: 1px solid var(--blue-brand);
              background: #fffffff2;
              text-align: center;
              display: flex;
              text-transform: uppercase;
              .text {
                margin: auto;
              }
            }
            img {
              margin-right: 1em;
            }
            .el-icon-arrow-down,
            .icon-img {
              margin-left: 1em;
              display: flex;
              &::before {
                margin: auto;
              }
            }
          }
        }
      }

      .dashboard-management-dropdown {
        margin-right: 1em;
        height: 40px;
        padding: 6px 0px 6px 1em;
        &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)),
            #0e3fe5;
          border-radius: 4px;
        }
      }
      .user-management-dropdown {
        &:hover {
          .el-dropdown-link {
            .circular-avatar {
              background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
                #0e3fe5;
            }
          }
        }
      }
    }
  }
}
.main-page-content {
  display: flex;
  flex-direction: row;
  .main-page-content-wrapper {
    position: relative;
    flex-grow: 1;
    height: calc(100vh - 42px);
    padding: 28px 16px;
    background-color: var(--background-grey);
    overflow: auto;
  }
}
.sidebar {
  .filters-wrapper {
    .ps__rail-y {
      right: 4px;
      height: 99%;
      margin-top: 2px;
      margin-bottom: 2px;
    }
    &.ps--active-y > .ps__rail-y {
      border-radius: 6px;
      width: 4px;
      &.ps--clicking {
        width: 8px;
        & > .ps__thumb-y {
          width: 8px;
        }
      }
      .ps__thumb-y {
        width: 4px;
        right: 0px;
        background-color: var(--background-grey-300);
      }
      background-color: var(--grey-200);
    }

    &::-webkit-scrollbar {
      display: none;
    }
    .context-switcher {
      margin-bottom: 1em;
    }
    .map-legend {
      margin-top: 2em;
    }
    img.mainLogo {
      align-self: start;
      width: 100%;
      margin: 24px 0em;
      height: 92px;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    max-height: calc(100vh - 73px);
    overflow-y: auto;
    margin: 0em 0em 0em 24px;
    padding-right: 24px;
    .basic-filter-wrapper + .basic-filter-wrapper {
      margin-top: 1em;
    }
    .remove-filters-wrapper {
      margin-top: 1em;
    }
    .map-legend-wrapper {
      margin-top: 64px;
      align-self: flex-end;
      .map-legend-item {
        width: 100%;
        padding-right: 0;
      }
    }
  }
}

.main-row {
  display: flex;
  flex-wrap: nowrap;
  .sidebar {
    width: 25%;
    flex-basis: 25%;
    flex-grow: 0.25;
    max-width: 288px;
    min-width: 288px;
    @media screen and (max-width: 375px) {
      width: 100%;
      max-width: unset;
    }
  }
  .inner-content-wrapper {
    height: auto;
    flex-grow: 1;
    display: flex;
    .main-page-wrapper {
      flex-grow: 1;
    }
    .validation-panel {
      width: 56px;
      .validation-check {
        align-self: center;
        margin-top: 76px;
        &.logout {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}
</style>
