export const productFlowMapLegend = [
  {
    label: 'Expected (shipped)',
    color: '#4744DF',
  },
  {
    label: 'Actual (received)',
    color: '#00A3AD',
  },
];

export const inventoryMapLegend = [
  {
    label: 'MONTHS TO EXPIRY',
    header: true,
  },
  {
    label: '>3 months',
    color: '#DFDFDF',
  },
  {
    label: '1-3 months',
    color: '#E6F6F7',
  },
  {
    label: '0-1 months',
    color: '#B3E3E6',
  },
  {
    label: 'Expired',
    color: '#FFB9AF',
  },
];

export const utilizationPage = [
  {
    label: 'Shipped by WFH',
    color: '#4744DF',
    noSeparator: true,
  },
  {
    label: 'UTILIZATION',
    header: true,
  },
  {
    label: 'Surgery',
    color: '#DFDFDF',
  },
  {
    label: 'Acute bleed',
    color: '#E6F6F7',
  },
  {
    label: 'Prophylaxis',
    color: '#B3E3E6',
  },
  {
    label: 'Other',
    color: '#FFB9AF',
  },
  {
    label: 'Destroyed',
    color: '#FF8273',
  },
];

export const destroyedProductsPage = [
  {
    label: 'REASON',
    header: true,
  },
  {
    label: 'Damaged',
    color: '#00A3AD',
  },
  {
    label: 'Expired',
    color: '#4744DF',
  },
  {
    label: 'Recalled',
    color: '#F57923',
  },
];

export const HFIMapLegends = {
  treatments: [
    {
      label: 'TREATMENTS',
      header: true,
    },
    {
      label: 'Surgery',
      color: '#ACACAC',
    },
    {
      label: 'Acute bleed',
      color: '#00A3AD',
    },
    {
      label: 'Prophylaxis',
      color: '#4744DF',
    },
    {
      label: 'Other',
      color: '#F57923',
    },
  ],
  utilization: {
    general: [
      {
        label: 'Shipped by WFH',
        color: '#4744DF',
        noSeparator: true,
      },
      {
        label: 'UTILIZATION',
        header: true,
      },
      {
        label: 'Surgery',
        color: '#DFDFDF',
      },
      {
        label: 'Acute bleed',
        color: '#E6F6F7',
      },
      {
        label: 'Prophylaxis',
        color: '#B3E3E6',
      },
      {
        label: 'Other',
        color: '#FFB9AF',
      },
      {
        label: 'Destroyed',
        color: '#FF8273',
      },
    ],
    expiry: [
      {
        label: 'Shipped by WFH',
        color: '#4744DF',
        noSeparator: true,
      },
      {
        label: 'Used in last 30 days before expiry',
        color: '#B3E3E6',
      },
      {
        label: 'Scanned after expiration date',
        color: '#FF8273',
      },
    ],
  },
  inventory: [
    {
      label: 'MONTHS TO EXPIRY',
      header: true,
    },
    {
      label: '>3 months',
      color: '#DFDFDF',
    },
    {
      label: '1-3 months',
      color: '#E6F6F7',
    },
    {
      label: '0-1 months',
      color: '#B3E3E6',
    },
    {
      label: 'Expired',
      color: '#FFB9AF',
    },
  ],
};
