<template>
  <div class="base-header-wrapper" @click="onButtonClosing()">
    <div class="back-arrow-wrapper clickable">
      <img class="back-arrow" alt="" :src="`${$endPoint}/assets/icons/arrow-left.png`" />
      <h4 class="label-small">{{ title }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseHeader',
  props: {
    onclose: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    onButtonClosing() {
      this.$props.onclose({ buttonClicked: true });
    },
  },
};
</script>

<style scoped lang="scss">
.base-header-wrapper {
  height: 64px;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  padding-left: 1.5em;
  * {
    margin: auto 0.5em;
  }

  .back-arrow {
  }
  .back-arrow-wrapper {
    height: 43px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    .label-small {
      color: var(--black);
    }
    &:hover {
      background: var(--grey-100);
      color: var(--black-primary);
      border: none;
    }
  }
}
</style>
