<template>
  <div class="remove-filters-wrapper">
    <div class="label-medium" @click="removeFilters">Remove filters</div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'removeFiltersComponent',
  methods: {
    ...mapMutations(['resetAllFilters']),
    removeFilters() {
      this.resetAllFilters();
      if (this.$listeners.resetLocalFilters) {
        this.$listeners.resetLocalFilters();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.remove-filters-wrapper {
  text-align: left;
  display: flex;
  .label-medium {
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
