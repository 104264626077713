<template>
  <base-slider v-bind="$props" v-on="$listeners" title="GO BACK" :size="['75%', '100%']">
    <div class="event-list-slider" v-if="locationDetails">
      <h2 class="label-extra-large">Location event log</h2>
      <div class="event-list-slider-content">
        <div class="content-header">
          <h3 class="label-large">
            Location: {{ locationDetails.name
            }}{{ locationDetails.city ? ', ' + locationDetails.city : '' }}
          </h3>
          <div class="filter-row">
            <h5 class="label-medium">
              Location type:
              <span class="label-bold">{{ resolveLocationType(locationDetails.type) }}</span>
            </h5>
            <!--            <div class="red-flag-filter">-->
            <!--              <h5 class="label-medium">Red flag</h5>-->
            <!--              <red-flag-status-filter-->
            <!--                :values="flagStatusOptions"-->
            <!--                :initial-value="filter"-->
            <!--                @change="(v) => filterRows(v)"-->
            <!--              />-->
            <!--            </div>-->
          </div>
        </div>

        <div class="event-list-table">
          <location-event-list-table
            :table-data="events"
            :location="locationDetails"
            @sortChange="handleSortChange"
          />
          <div class="pagination">
            <el-pagination
              @size-change="handleLimitChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-sizes="[5, 10, 25, 50, 100]"
              :page-size="limit"
              :total="total"
              layout="prev, pager, next, sizes, total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </base-slider>
</template>

<script>
import BaseSlider from './BaseSlider';
import { resolveLocationType } from '../../utils/nameResolver';
import LocationEventListTable from '../LocationEventListTable';
import { eventPageFilterStatus } from '../../utils/enums';
export default {
  name: 'EventListSlider',
  components: { LocationEventListTable, BaseSlider },
  props: {
    locationDetails: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    initialFilter: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      filter: this.$props.initialFilter,
      events: [],
      currentPage: 1,
      limit: 10,
      total: 0,
      sortBy: 'eventTime:descending',
      flagStatusOptions: [
        {
          value: eventPageFilterStatus.ALL,
          label: 'All',
        },
        {
          value: eventPageFilterStatus.RED_FLAG_ONLY,
          label: 'Yes',
        },
        {
          value: eventPageFilterStatus.NO_RED_FLAG,
          label: 'No',
        },
        {
          value: eventPageFilterStatus.RESOLVED_ONLY,
          label: 'Resolved',
        },
      ],
    };
  },
  watch: {
    initialFilter: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.filter = newValue;
      }
    },
    locationDetails: function () {
      this.currentPage = 1;
      this.$nextTick(this.fetchLocationEvents);
    },
  },
  methods: {
    resolveLocationType,
    handleSortChange(sortBy) {
      this.sortBy = sortBy;
      this.fetchLocationEvents();
    },
    handleLimitChange(limit) {
      this.limit = limit;
      this.fetchLocationEvents();
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.fetchLocationEvents();
    },
    filterViews(filterStatus, data) {
      switch (filterStatus) {
        case eventPageFilterStatus.RED_FLAG_ONLY: {
          return data.filter((event) => event.isRedFlag);
        }

        case eventPageFilterStatus.RESOLVED_ONLY: {
          return data.filter((event) => event.isResolved);
        }

        case eventPageFilterStatus.NO_RED_FLAG: {
          return data.filter((event) => !event.isRedFlag);
        }

        default: {
          return data;
        }
      }
    },
    async fetchLocationEvents() {
      const loading = this.$loading({ target: '.event-list-table' });
      try {
        const params = {
          dateRange: this.$store.state.filters.selectedFilters.dateRange,
          manufacturer: this.$store.state.filters.selectedFilters.manufacturer,
          sortBy: !this.sortBy.endsWith('null') ? this.sortBy : 'eventTime:descending',
          limit: this.limit,
          offset: this.currentPage * this.limit - this.limit,
        };

        const { data } = await this.$http.get(`/locations/${this.locationDetails.id}/events`, {
          params,
        });

        this.events = data.events;
        this.total = data.total;
      } catch (e) {
        this.$notify({ type: 'error', message: "Couldn't fetch location events" });
      } finally {
        loading.close();
      }

      setTimeout(() => {
        loading.close();
      }, 1200);
    },
  },
};
</script>

<style scoped lang="scss">
.event-list-slider {
  height: 100%;
  padding-bottom: 1em;
  .event-list-slider-content {
    height: 100%;
    align-self: start;
    text-align: start;
    .content-header {
      padding-left: 4rem;
      padding-right: 1rem;
      .label-large,
      .label-medium {
        color: var(--black-primary);
      }
    }

    .filter-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      * {
        margin-top: auto;
        margin-bottom: auto;
      }
      .red-flag-filter {
        text-align: start;
        h5 {
          padding-left: 0;
          margin-bottom: 8px;
        }
      }
    }
  }
  h2.label-extra-large {
    padding-left: 4rem;
    align-self: start;
    text-align: start;
    margin-bottom: 24px;
  }

  .event-list-table {
    margin-top: 40px;
    min-height: calc(100% - 199px);
    display: flex;
    flex-direction: column;

    .pagination {
      margin-top: auto;
      text-align: center;
      margin-bottom: 35px;
    }
  }
}
</style>
