<template>
  <div class="events-list-table">
    <el-table
      align="left"
      v-if="tableInitialized"
      :data="tableData"
      :header-cell-class-name="(v) => getCellClass(v, 'label-medium')"
      header-row-class-name="clickable"
      :default-sort="{ prop: 'eventTime', order: 'descending' }"
      @row-click="(v) => onRowClicking(v)"
      :row-class-name="(v) => getRowClass(v, 'clickable')"
      :cell-class-name="(v) => getCellClass(v, 'normal-break')"
      @sort-change="handleSortChange"
    >
      <el-table-column align="left" prop="id" label="Event ID" :width="columnWidth">
        <template slot-scope="scope" slot="header">
          {{ scope.column.label }}
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        align="left"
        prop="type"
        label="Event type"
        :width="columnWidth"
        :formatter="(ev) => resolveEventType(ev.type)"
      >
        <template slot-scope="scope" slot="header">
          {{ scope.column.label }}
        </template>
      </el-table-column>
      <el-table-column
        align="left"
        prop="eventTime"
        sortable="custom"
        label="Event date"
        :formatter="(ev) => `${dateToUTCFormatter(ev.eventTime) + ' UTC'}`"
        :width="columnWidth"
      >
        <template slot-scope="scope" slot="header">
          {{ scope.column.label }}
        </template>
      </el-table-column>
      <el-table-column
        align="left"
        prop="destination"
        label="Destination"
        sortable="custom"
        :formatter="(ev) => ev.destination || '-'"
        :width="columnWidth"
      >
        <template slot-scope="scope" slot="header">
          {{ scope.column.label }}
        </template>
      </el-table-column>
      <el-table-column
        align="left"
        prop="submittedBy"
        sortable="custom"
        label="Submitted by"
        :width="columnWidth"
        :formatter="(ev) => valueParser(ev.submittedBy) || '-'"
      >
        <template slot-scope="scope" slot="header">
          {{ scope.column.label }}
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        align="left"-->
      <!--        prop="isRedFlag"-->
      <!--        label="Red flag"-->
      <!--        :formatter="(ev) => (ev.isRedFlag ? 'Yes' : 'No')"-->
      <!--        :width="columnWidth"-->
      <!--      >-->
      <!--        <template slot-scope="scope" slot="header">-->
      <!--          <header-with-sorting>-->
      <!--            {{ scope.column.label }}-->
      <!--          </header-with-sorting>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        align="left"
        prop="totalVials"
        sortable="custom"
        label="# of vials"
        :width="columnWidth"
      >
        <template slot-scope="scope" slot="header">
          {{ scope.column.label }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import lists from '../utils/list';
import { resolveEventType } from '../utils/nameResolver';
import { openSideBar } from '../utils/eventBus';
import { sliderTypes } from '../utils/enums';
import { valueParser } from '../utils/utils';
import { dateToUTCFormatter } from '../utils/numberFormatter';

export default {
  name: 'LocationEventListTable',
  components: {},
  props: {
    tableData: {
      type: Array,
      default: () => lists.mockEvents,
    },
    location: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dateToUTCFormatter,
      columnWidth: 0,
      tableInitialized: false,
      columnCount: 6,
    };
  },
  mounted() {
    this.tableInitialized = true;
  },
  methods: {
    resolveEventType,
    valueParser,
    onRowClicking(...row) {
      let eventDetail = row[0];
      const location = Object.assign({}, this.$props.location);
      delete location.events;
      eventDetail['location'] = location;
      openSideBar(sliderTypes.EVENT_DETAILS, eventDetail, {
        ogList: {
          tableData: this.$props.tableData,
          location: this.$props.location,
        },
      }); // TODO add single event details page
    },
    handleSortChange(val) {
      this.$emit('sortChange', `${val.prop}:${val.order}`);
    },
    calculateNumberOfVialsFromIssues(event) {
      let finalNumber = 0;
      if ((event?.issues?.length ?? 0) > 0) {
        finalNumber += event.issues.reduce((p, c) => p + c.numberOfVials, 0);
      }
      if ((event?.resolvedIssues?.length ?? 0) > 0) {
        finalNumber += event.resolvedIssues.reduce((p, c) => p + c.numberOfVials, 0);
      }
      return finalNumber;
    },

    getRowClass({ row }, initial = '') {
      if (row.isRedFlag) {
        return initial + ' red-flag-table-row-background';
      }
      if (row.resolvedIssues?.length > 0 && (!row.issues || row.issues?.length === 0)) {
        return initial + ' resolved-table-row-background';
      }

      return initial;
    },

    getCellClass({ columnIndex }, extra) {
      if (columnIndex === 0) {
        return 'left-column-padding' + ' ' + extra;
      }
      if (columnIndex === 5) {
        return 'center-data' + ' ' + extra;
      }
      return extra;
    },
  },
};
</script>

<style lang="scss">
.events-list-table {
  width: 100%;
  .el-table__header-wrapper {
    .label-medium {
      &.el-table__cell {
        .cell {
          color: var(--black-tertiary);
        }
      }
    }
  }
  .el-table__body-wrapper {
    .el-table__cell {
      .cell {
        color: var(--black-primary);
      }
    }
  }

  .red-flag-event {
    display: flex;
    .warning-icon {
      margin-left: 20px;
      margin-top: auto;
      margin-bottom: auto;
    }
    .label {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .cell {
    word-break: break-word !important;
  }
  .left-column-padding {
    &.el-table__cell {
      .cell {
        padding-left: 4rem;
      }
    }
    &.normal-break {
      .cell {
        word-break: break-word;
      }
    }
  }
  .center-data {
    &.el-table__cell {
    }
  }
}
</style>
