<template>
  <div class="utilization-page-context-switcher-wrapper">
    <black-button :text="active !== 'country'" @click="active = 'country'">
      Country level
    </black-button>
    <black-button :text="active !== 'site'" @click="active = 'site'">Site level</black-button>
  </div>
</template>

<script>
import BlackButton from './BlackButton';
import { mapMutations } from 'vuex';
export default {
  name: 'UtilizationPageContextSwitcher',
  components: { BlackButton },
  data() {
    return {
      active: 'country',
    };
  },
  watch: {
    active() {
      this.setUtilizationView(this.active);
    },
  },
  created() {
    this.setUtilizationView(this.active);
  },
  methods: {
    ...mapMutations(['setUtilizationView']),
  },
};
</script>

<style scoped></style>
