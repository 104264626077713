export default {
  computed: {
    formModel() {
      return Object.keys(this.formData).reduce((p, c) => {
        p[c] = this.formData[c].value;
        return p;
      }, {});
    },
  },
  methods: {
    validateFormInline(formName) {
      return new Promise((res, rej) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            res(true);
          } else {
            rej(false);
          }
        });
      });
    },
    validateForm() {
      for (const [, field] of Object.entries(this.formData)) {
        if (!field.validate()) {
          return false;
        }
      }
      return true;
    },
    isText(propertyName) {
      const re = /^[A-Za-z\s]+$/;
      const property = this.formData[propertyName];

      const isValid = re.test(property.value);

      property.error = !isValid;

      return isValid;
    },
    isEmail(propertyName) {
      const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})+$/;
      const property = this.formData[propertyName];

      const isValid = re.test(property.value);

      property.error = !isValid;

      return isValid;
    },
    isNotEmpty(propertyName, minLength = 0) {
      const property = this.formData[propertyName];
      const isValid = `${property.value}`.length > minLength;

      property.error = !isValid;

      return isValid;
    },
    hasLength(propertyName, length) {
      const property = this.formData[propertyName];
      const isValid = property.value.length === length;

      property.error = !isValid;

      return isValid;
    },
    dependentOn(propertyName, propertyValue, validationResult) {
      const property = this.formData[propertyName];
      if (property.value !== propertyValue) {
        return false;
      }

      return validationResult;
    },
  },
};
