<template>
  <div class="resolved-issues-list-table">
    <div class="resolved-issues-list-header">
      <h4 class="label-big">Resolved issues</h4>
    </div>
    <div class="resolved-issues-table">
      <el-table
        :data="tableData"
        header-cell-class-name="label-medium"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column align="left" prop="issueId" label="Issue ID">
          <template slot-scope="scope" slot="header">
            <header-with-sorting>
              {{ scope.column.label }}
            </header-with-sorting>
          </template></el-table-column
        >
        <el-table-column
          align="left"
          prop="issueType"
          label="Issue type"
          :formatter="(ev) => resolveIssueType(ev.issueType)"
        >
          <template slot-scope="scope" slot="header">
            <header-with-sorting>
              {{ scope.column.label }}
            </header-with-sorting>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="numberOfVials" label="# of vials impacted">
          <template slot-scope="scope" slot="header">
            <header-with-sorting>
              {{ scope.column.label }}
            </header-with-sorting>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          prop="resolvedDate"
          label="Date resolved"
          :formatter="(row) => row.resolvedDate.toLocaleString()"
        >
          <template slot-scope="scope" slot="header">
            <header-with-sorting>
              {{ scope.column.label }}
            </header-with-sorting>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="resolutionInfo" label="resolution data">
          <template slot-scope="scope" slot="header">
            <header-with-sorting>
              {{ scope.column.label }}
            </header-with-sorting>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { resolveIssueType } from '../utils/nameResolver';
import HeaderWithSorting from './basic/HeaderwithSorting';

export default {
  name: 'ResolvedIssuesTable',
  components: { HeaderWithSorting },
  props: {
    tableData: {
      type: Array,
      default: null,
    },
  },
  methods: {
    resolveIssueType,
  },
};
</script>

<style scoped lang="scss">
.resolved-issues-list-table {
  .resolved-issues-list-header {
    text-align: start;
    align-self: start;
    padding: 1em 1em;
  }

  .resolved-issues-table {
    ::v-deep .label-medium {
      color: var(--black-disabled);
    }

    .number-of-vials-column {
      display: flex;
      flex-direction: row;

      * {
        margin: auto 1em;
      }

      .resolve-button {
        margin-left: 3em;
      }
    }
  }
}
</style>
