<template>
  <div class="header-wrapper">
    <div class="header-content label-medium header-row">
      <slot></slot>
    </div>
    <div class="sorting-content clickable" @click="toggleStatus()">
      <img
        :src="`${$endPoint}/assets/icons/iconline.svg`"
        class="upper-caret"
        v-if="status === sortingStatus.ASC"
      />
      <img :src="`${$endPoint}/assets/icons/iconline.svg`" v-if="status === sortingStatus.DESC" />
      <img
        :src="`${$endPoint}/assets/icons/iconline-disabled.svg`"
        class="grey"
        v-if="status === sortingStatus.NONE"
      />
    </div>
  </div>
</template>

<script>
import { sortingStatus } from '../../utils/enums';

export default {
  name: 'HeaderWithSorting',
  props: {
    initialValue: {
      type: String,
      default: sortingStatus.NONE,
    },
  },
  data() {
    return {
      sortingStatus,
      status: this.initialValue,
    };
  },
  methods: {
    toggleStatus() {
      switch (this.status) {
        case sortingStatus.NONE: {
          this.status = sortingStatus.ASC;
          break;
        }
        case sortingStatus.ASC: {
          this.status = sortingStatus.DESC;
          break;
        }
        case sortingStatus.DESC: {
          this.status = sortingStatus.NONE;
          break;
        }
        default: {
          this.status = sortingStatus.NONE;
          break;
        }
      }
      this.$emit('sort', this.status);
    },
  },
};
</script>

<style scoped lang="scss">
.header-wrapper {
  display: flex;
  justify-content: flex-start;
  .sorting-content {
    margin-left: 0.4em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35px;
    height: 20px;
    img {
      margin: auto;
      width: 12px;
      height: 8px;
      &.grey {
        svg {
          color: var(--grey-100);
        }
      }
    }
    .upper-caret {
      transform: rotate(180deg);
    }
  }
  .header-row {
    font-size: 14px;
    color: var(--black-tertiary);
  }
  word-break: break-word;
}
</style>
