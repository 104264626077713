<template>
  <div class="events-list-table">
    <el-table
      v-if="tableInitialized"
      :data="tableData"
      header-row-class-name="label-medium"
      row-class-name="clickable"
      :default-sort="{ prop: 'eventCount', order: 'descending' }"
      @row-click="onRowClicking"
      @sort-change="handleSortChange"
    >
      <el-table-column
        sortable="custom"
        prop="name"
        :formatter="(ev) => `${ev.name}${ev.city ? ', ' + ev.city : ''}`"
        label="Location name"
        :width="columnWidth"
      >
      </el-table-column>
      <el-table-column
        prop="type"
        label="Location type"
        :width="columnWidth"
        sortable="custom"
        :formatter="(ev) => resolveLocationType(ev.type)"
      >
      </el-table-column>
      <el-table-column sortable="custom" prop="eventCount" label="# of events" :width="columnWidth">
        <template slot-scope="scope">
          <double-tacked-chart
            :bars="scope.row.vials"
            :inverted="false"
            :max-width="scope.column.realWidth"
            :step="getStepSize(scope, 0.9)"
            :noTooltip="true"
            :auto-switch-labels="true"
          >
          </double-tacked-chart>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="redFlagEvents" label="Red flag events" :width="columnWidth">-->
      <!--        <template slot-scope="scope" slot="header">-->
      <!--          <header-with-sorting>-->
      <!--            {{ scope.column.label }}-->
      <!--          </header-with-sorting>-->
      <!--        </template>-->
      <!--        <template slot-scope="scope">-->
      <!--          <div class="red-flag-event">-->
      <!--            <span class="label">{{ scope.row.redFlagEvent || 0 }}</span>-->
      <!--            <img-->
      <!--              v-if="scope.row.redFlagEvent > 0"-->
      <!--              :src="`${$endPoint}/assets/icons/warning.svg`"-->
      <!--              alt=""-->
      <!--              class="warning-icon clickable"-->
      <!--              @click.stop="onRedFlagIconClick(scope.row)"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
  </div>
</template>

<script>
import { resolveLocationType } from '../utils/nameResolver';
import { openSideBar } from '../utils/eventBus';
import { eventPageFilterStatus, sliderTypes } from '../utils/enums';
import DoubleTackedChart from './charts/DoubleTackedChart';

export default {
  name: 'EventsListTable',
  components: { DoubleTackedChart },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columnWidth: 0,
      tableInitialized: false,
      columnCount: 3,
      maxEventCount: 0,
    };
  },
  mounted() {
    this.tableInitialized = false;
    let parentWidth = this.$el.parentElement.parentElement.scrollWidth;
    this.columnWidth = (parentWidth - 48) / this.columnCount;
    this.tableInitialized = true;
  },
  methods: {
    resolveLocationType,
    onRowClicking(row) {
      openSideBar(sliderTypes.EVENT_LIST, row);
    },
    onRedFlagIconClick(data) {
      openSideBar(sliderTypes.EVENT_LIST, data, {
        initialFilter: eventPageFilterStatus.RED_FLAG_ONLY,
      });
    },
    handleSortChange(val) {
      this.$emit('sortChange', `${val.prop}:${val.order}`);
    },
    handleSortColumn() {},
    getStepSize(scope, reduction = 0.75) {
      const maxValue = Math.max(
        ...this.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []),
      );
      return maxValue > 0
        ? (scope.column.realWidth * reduction) /
            Math.max(...this.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []))
        : 0;
    },
    getMaxValue() {
      return Math.max(
        ...this.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []),
      );
    },
    getMinValue() {
      return Math.min(
        ...this.tableData.reduce((p, c) => [...p, ...c.vials.map((t) => t.value)], []),
      );
    },
  },
};
</script>

<style scoped lang="scss">
.events-list-table {
  width: 100%;
  ::v-deep .label-medium {
    color: var(--black-tertiary);
    th {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .red-flag-event {
    display: flex;
    .warning-icon {
      margin-left: 20px;
      margin-top: auto;
      margin-bottom: auto;
    }
    .label {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
</style>
