<template>
  <div class="sidebar-wrapper">
    <div class="main-aidtrust-logo">
      <img :src="`${$endPoint}/assets/images/aid-trust-logo.svg`" />
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'sidebar',
};
</script>

<style scoped lang="scss">
.sidebar-wrapper {
  .main-aidtrust-logo {
    height: 73px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--grey-200);
    img {
      margin: auto auto auto 1em;
    }
  }
}
</style>
