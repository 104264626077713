<template>
  <div class="login-page-wrapper">
    <div class="login-card">
      <div class="title-row">
        <img :src="`${$endPoint}/assets/icons/aidtrust-logo.svg`" />
      </div>

      <h2 class="label-large page-name">Login</h2>
      <div class="login-form">
        <el-form ref="loginForm" :rules="rules" :model="formModel" :hide-required-asterisk="true">
          <el-form-item label="Email" prop="email">
            <el-input
              v-model="formData.email.value"
              clearable
              :class="{ 'input--error': formData.email.error }"
              @blur="formData.email.validate()"
              placeholder="Please input your email"
            ></el-input>
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input
              v-model="formData.password.value"
              show-password
              :class="{ 'input--error': formData.password.error }"
              @blur="formData.password.validate()"
              @keydown.native.enter="login"
              placeholder="Please input password"
            ></el-input>
          </el-form-item>
        </el-form>
        <ErrorMessageBox
          :message="'Email and password combination didn\'t match'"
          v-if="submittingError"
        />
        <el-button type="primary" @click="login">LOGIN</el-button>
      </div>
      <div class="forgot-password-section">
        <a href="/forgot-password" class="forgot-password label-small">Forgot password</a>
      </div>
    </div>
  </div>
</template>

<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';
import { roles } from '@/utils/enums';
import ErrorMessageBox from '@/components/basic/ErrorMessageBox';

export default {
  name: 'login',
  components: { ErrorMessageBox },
  mixins: [FormValidationMixin],
  data() {
    return {
      email: '',
      password: '',
      submittingError: false,
      formData: {
        email: { value: '', error: false, validate: () => this.isEmail('email') },
        password: { value: '', error: false, validate: () => this.isNotEmpty('password') },
      },
      rules: {
        email: [
          {
            required: true,
            message: '* Please fill in your email',
            trigger: ['blur', 'change'],
          },
          {
            type: 'email',
            message: '* Please fill in a correct email',
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          {
            required: true,
            message: '* Please fill in your password',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  methods: {
    async login() {
      if (
        !this.validateForm() &&
        !(await this.validateFormInline('loginForm').catch(() => false))
      ) {
        return;
      }

      this.isLoading = true;
      this.submittingError = false;
      const loader = this.$loading({ target: '.login-card' });
      this.$store
        .dispatch('signIn', {
          email: this.formData.email.value,
          password: this.formData.password.value,
        })
        .then((data) => {
          this.redirect(data.roles);
        })
        .catch(() => {
          this.submittingError = true;
        })
        .finally(() => {
          this.isLoading = false;
          loader.close();
        });
    },

    redirect(role) {
      switch (true) {
        case role.includes(roles.HFI_ADMIN): {
          this.$router.push({
            name: 'hfiUtilization',
          });
          break;
        }
        case role.includes(roles.WFH_ADMIN): {
          this.$router.push({
            name: 'events',
          });
          break;
        }
        default: {
          this.$router.push({
            name: 'events',
          });
          break;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login-page-wrapper {
  display: flex;
  height: 100vh;
  overflow-y: scroll;
  background: var(--background-grey);

  .login-card {
    background: var(--white);
    padding: 32px;
    margin: auto;
    width: 420px;
    min-height: 550px;
    box-shadow: 0px 2px 4px rgba(82, 97, 115, 0.2);
    border-radius: 16px;
    display: flex;
    flex-direction: column;

    .title-row {
      margin-top: 21px;
      margin-bottom: 32px;
      display: flex;
      justify-content: space-between;
      img {
        margin-right: auto;
        margin-left: auto;
      }
    }

    .page-name {
      color: var(--black-primary);
    }
    .login-form {
      margin-top: 22px;
      margin-bottom: 22px;
      .el-form-item {
        margin-bottom: 15px;
      }
      .el-button {
        margin-top: 12px;
        font-family: Roboto;
      }

      .el-button {
        width: 100%;
        background-color: var(--button-blue);
        border-color: var(--button-blue);
      }
    }

    .forgot-password-section {
      margin-top: auto;
      margin-bottom: 22px;
      display: flex;

      .forgot-password {
        margin: auto;
        text-decoration: underline;
        color: var(--button-blue);

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
