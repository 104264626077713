<template>
  <div class="basic-filter-wrapper">
    <h5 class="label-small">Manufacturer</h5>
    <el-select
      v-model="$store.state.filters.selectedFilters.manufacturer"
      placeholder="Select"
      v-bind="$props"
      v-on="$listeners"
      @change="setFilter"
      class="manufacturer-select"
    >
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import eventBus from '../../utils/eventBus';
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'ManufacturerFilter',
  data() {
    return {
      eventBus,
      value: null,
    };
  },
  computed: {
    ...mapGetters(['getManufacturerFilters']),
    options() {
      return this.getManufacturerFilters;
    },
  },
  created() {
    this.$store.dispatch('fetchAvailableManufacturers');
  },
  methods: {
    ...mapMutations(['activateFilter']),
    setFilter(value) {
      this.activateFilter({ filter: 'manufacturer', value });
      this.$store.dispatch('fetchAvailableLocations');
    },
  },
};
</script>

<style scoped lang="scss">
.label-small {
  margin-bottom: 8px;
  text-align: start;
}
.manufacturer-select {
  text-align: start;
  display: block;
}
</style>
