export const valueParser = (input) => {
  if (input === 'null') {
    return null;
  }
  return input;
};

export const prettyPrintDecimal = (val, fractionDigits = 1) => {
  if (!val) {
    return val;
  }

  let num = Number.parseFloat(val).toFixed(fractionDigits);
  return Number.parseFloat(num).toLocaleString('en', {
    style: 'decimal',
  });
};

export const shortenUuid = (uuid, prefix = '') => {
  return prefix + uuid.substring(33);
};
