import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const formatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 });
const formatterWithoutDecimals = Intl.NumberFormat('en', {
  notation: 'compact',
  maximumFractionDigits: 0,
});
const formatterWithoutDecimalsAndFullNumbers = Intl.NumberFormat('en', {
  notation: 'standard',
  maximumFractionDigits: 1,
});
const percentFormatter = Intl.NumberFormat('en', {
  notation: 'compact',
  style: 'percent',
  maximumFractionDigits: 1,
});

export const formatAmountNumber = (value) => {
  return formatter.format(value);
};

export const formatAmountNumberWithoutDecimals = (value) => {
  return formatterWithoutDecimals.format(value);
};

export const formatRegularAmounts = (value) => {
  return formatterWithoutDecimalsAndFullNumbers.format(value);
};

export const formatPercent = (value) => {
  if (value === 'INF' || value === 'Infinity' || !Number.isFinite(parseFloat(value))) {
    return percentFormatter.format(0);
  }

  return percentFormatter.format(value);
};

export const dateToUTCFormatter = (date) => {
  return dayjs(date).utc().format('DD/MM/YYYY, HH:mm:ss');
};
