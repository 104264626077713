export const eventPageTabs = {
  MAP: 'map',
  TABLE: 'table',
};

export const barChartColors = {
  BLUE: 'blue',
  GREEN: 'green',
  GREY: 'grey',
  PALE_TEAL: 'pale-teal',
  LIGHT_TEAL: 'light-teal',
  PALE_RED: 'pale-red',
  DARK_RED: 'dark-red',
  DARK_ORANGE: 'dark-orange',
  GRAY_30: 'grey-30',
  GRAY_300: 'grey-300',
  DARK_BLUE: 'dark-blue',
};

export const productFlowPageDescription = {
  PRODUCT_AMOUNT_EXPECTED: 'product_amount_expected',
  PRODUCT_AMOUNT_RECEIVED: 'product_amount_received',
};

export const sliderTypes = {
  VERIFICATION: 'verification',
  EVENT_DETAILS: 'event_details',
  EVENT_LIST: 'event_list',
  INBOUND_FLOW: 'inbound_flow',
  UTILIZATION_PRODUCT_BREAKDOWN: 'utilization_product_breakdown',
  PRODUCT_DESTRUCTION_EVENT_LOG: 'product_destruction_event_log',
};

export const mapMarkers = {
  RED_FLAG: 'RED_FLAG',
  TREATMENT_CENTER: 'treatment_center',
  DANGER_TREATMENT_CENTER: 'danger_treatment_center',
  DISTRIBUTION_CENTER: 'distribution_center',
  DANGER_DISTRIBUTION_CENTER: 'danger_distribution_center',
  EVENT_MARKER: 'event_marker',
};

export const eventPageFilterStatus = {
  ALL: null,
  RED_FLAG_ONLY: 'only',
  NO_RED_FLAG: 'no',
  RESOLVED_ONLY: 'resolved_only',
};

export const centerType = {
  DISTRIBUTION_CENTER: 'Country distribution center',
  TREATMENT_CENTER: 'Treatment center',
};

export const eventType = {
  SHIPPING: 'urn:epcglobal:cbv:bizstep:shipping',
  RECEIVING: 'urn:epcglobal:cbv:bizstep:receiving',
  DISPENSING: 'urn:epcglobal:cbv:bizstep:dispensing',
  DESTRUCTION: 'urn:epcglobal:cbv:bizstep:destroying',
};

export const issueTypes = {
  IDLE_STOCK: 'IDLE_STOCK',
  DAMAGED: 'DAMAGED',
};
export const inventoryExpiryStages = {
  THREE_MONTHS_EXPIRY: 'THREE_MONTHS_EXPIRY',
  ONE_TO_THREE_MONTHS_EXPIRY: 'ONE_TO_THREE_MONTHS_EXPIRY',
  ZERO_TO_ONE_MONTHS_EXPIRY: 'ZERO_TO_ONE_MONTHS_EXPIRY',
  EXPIRED: 'EXPIRED',
};

export const eventNames = {
  UTILIZATION_PAGE_CONTEXT: 'UTILIZATION_PAGE_CONTEXT',
};

export const vialStatus = {
  SURGERY: 'SURGERY',
  ACUTE_BLOOD: 'ACUTE_BLOOD',
  PROPHYLAXIS: 'PROPHYLAXIS',
  OTHER: 'OTHER',
  DESTROYED: 'DESTROYED',
};

export const vialsShipmentStatus = {
  VIALS_USED: 'VIALS_USED',
  VIALS_SHIPPED: 'VIALS_SHIPPED',
};

export const HFIUtilizationVialStatus = {
  HFI_SHIPPED_BY_DCS: 'HFI_SHIPPED_BY_DCS',
  HFI_USED_ON_LAST_MONTH: 'HFI_USED_ON_LAST_MONTH',
  EXPIRED_ON_SCANNING: 'EXPIRED_ON_SCANNING',
};

export const HFITreatmentTypes = {
  SURGERY: 'SURGERY',
  ACUTE_BLOOD: 'ACUTE BLEED',
  PROPHYLAXIS: 'PROPHYLAXIS',
  OTHER: 'OTHER',
};

export const vialDestructionReason = {
  DAMAGED: 'DAMAGED',
  EXPIRED: 'EXPIRED',
  RECALLED: 'RECALLED',
};

export const sortingStatus = {
  ASC: 'ASC',
  DESC: 'DESC',
  NONE: null,
};

export const roles = {
  HFI_ADMIN: 'HFI_ADMIN',
  WFH_ADMIN: 'WFH_ADMIN',
};

export const contexts = {
  WFH_ADMIN: 'WFH',
  HFI_ADMIN: 'HFI',
};
