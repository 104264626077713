<template>
  <div class="treatment-details-list-table">
    <div class="treatment-details-header">
      <h4 class="label-big">Treatment Details</h4>
    </div>
    <div class="treatment-details-list">
      <el-row>
        <el-col :xs="24" :sm="12" :md="6" :lg="7" :xl="5">
          <ul>
            <li>
              Patient ID:
              <span class="label-bold">{{ treatmentDetails.patientId || 'N/A' }}</span>
            </li>
            <li>
              Diagnosis:
              <span class="label-bold">{{ treatmentDetails.diagnosis || 'N/A' }}</span>
            </li>
            <li>
              Physician: <span class="label-bold">{{ treatmentDetails.physician || 'N/A' }}</span>
            </li>
            <li>
              Treatment date:
              <span class="label-bold">{{
                new Date(treatmentDetails.treatmentDate).toLocaleDateString()
              }}</span>
            </li>
            <li>
              Treatment type:
              <span class="label-bold">{{ treatmentDetails.treatmentType || 'N/A' }}</span>
            </li>
          </ul>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="4" :offset="1">
          <ul>
            <li>
              Acute bleed type:
              <span class="label-bold">{{ treatmentDetails.acuteBleedType || 'N/A' }}</span>
            </li>
            <li>
              Description:
              <span class="label-bold">{{ treatmentDetails.description || 'N/A' }}</span>
            </li>
            <li>
              Inhibitors:
              <span class="label-bold">{{
                treatmentDetails.inhibitors === 'false' ? 'No' : 'Yes'
              }}</span>
            </li>
            <li>
              Home treatment:
              <span class="label-bold">{{
                treatmentDetails.homeTreatment === 'false' ? 'No' : 'Yes'
              }}</span>
            </li>
            <li>
              Total IUs used:
              <span class="label-bold">{{ treatmentDetails.totalIUsUsed || 'N/A' }}</span>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventTreatmentDetail',
  props: {
    eventTreatmentDetails: {
      type: Object,
      default: null,
    },
  },
  computed: {
    treatmentDetails() {
      if (!this.eventTreatmentDetails) return {};

      return this.eventTreatmentDetails;
    },
  },
};
</script>

<style scoped lang="scss">
.treatment-details-list-table {
  .treatment-details-header {
    text-align: start;
    align-self: start;
    padding: 1em 1em;
  }
  .treatment-details-list {
    background-color: var(--white);
    margin-bottom: 12px;
    padding: 0 1em;
    ul {
      list-style: none;
      text-align: start;
      align-content: start;
      li {
        margin: 1em 0;
        color: var(--black-secondary);
        .label-bold {
          color: var(--black);
        }
      }
    }
  }
}
</style>
